import React, { FC } from "react";
import { Accordion } from "./accordion/Accordion";
import { backButtonSx } from "../../../theme/skiptonLightThemeOverrides";
import { Button } from "@saturn-ui/components";
import { AddIcon } from "../../../assets";
import "./AccordionGroup.scss";
import { JourneyStepsAccordionItemProps } from "../../../feature/journeySteps/JourneyStepsTypes";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../../redux/store";
import { PropertyFormState } from "../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";

type Props = {
  captions: {
    headerTitle: string;
    addButtonTitle: string;
    saveButtonTitle: string;
  };
  items: JourneyStepsAccordionItemProps[];
  setItems:
    | ActionCreatorWithPayload<
        JourneyStepsAccordionItemProps[],
        "aboutYouStep/setProperties"
      >
    | ActionCreatorWithPayload<
        JourneyStepsAccordionItemProps[],
        "yourMoneyStep/setProvisions"
      >;
  defaultItem: JourneyStepsAccordionItemProps;
};

export const AccordionGroup: FC<Props> = ({
  captions,
  items,
  defaultItem,
  setItems,
}) => {
  const dispatch = useAppDispatch();
  const showAddButton = !items.find(
    (item) => !item.isSaved || !item.canContinue
  );

  const addItem = () => {
    const collapsedItems = items.map((item) => ({
      ...item,
      expanded: false,
    }));

    dispatch(setItems([...collapsedItems, defaultItem]));
  };

  const deleteNewItem = (selectedIndex: number) => {
    dispatch(setItems(items.filter((item, index) => index !== selectedIndex)));
  };

  const setValuesAndAllowContinue =
    (selectedIndex: number) => (values: PropertyFormState) => {
      dispatch(
        setItems(
          items.map((item, index) => {
            if (index === selectedIndex) {
              return {
                ...item,
                values,
                canContinue: true,
              };
            }

            return item;
          })
        )
      );
    };

  const toggleItem = (selectedIndex: number) => {
    dispatch(
      setItems(
        items.map((item, index) => {
          if (index === selectedIndex) {
            return {
              ...item,
              expanded: !item.expanded,
            };
          }

          return item;
        })
      )
    );
  };

  const saveItem =
    (selectedIndex: number) =>
    (values: PropertyFormState, collapseSavedItem = true) => {
      dispatch(
        setItems(
          items.map((item, index) => {
            if (index === selectedIndex) {
              return {
                ...item,
                values,
                isSaved: true,
                canContinue: collapseSavedItem,
                expanded: !collapseSavedItem,
              };
            }

            return item;
          })
        )
      );
    };

  return (
    <div className="custom-accordion-group">
      {items.map((item, index) => {
        return (
          <Accordion
            key={`${captions.headerTitle}-${index}`}
            index={index}
            captions={captions}
            handleDeleteItem={deleteNewItem}
            handleToggleItem={toggleItem}
            handleSaveItem={saveItem}
            handleSetValuesAndAllowContinue={setValuesAndAllowContinue}
            {...item}
          />
        );
      })}
      {showAddButton && (
        <div className="custom-accordion-group-add-button">
          <Button
            id="add-button"
            className="add-button"
            sx={backButtonSx}
            startIcon={<AddIcon />}
            onClick={addItem}
            disableRipple={true}
            variant="text"
          >
            {captions.addButtonTitle}
          </Button>
        </div>
      )}
    </div>
  );
};
