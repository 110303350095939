import { FieldMessages } from "@saturn-ui/components";

/**
 * Based on todoClick() from saturn-ui todoList / TodoListMessage.tsx
 */
export const focusOnInputById = (
  id: string,
  setFieldTouched?: (field: string) => void
): void => {
  const field = document.getElementById(id);
  let fieldName: string | null | undefined;
  switch (field?.tagName) {
    case "INPUT":
    case "SELECT":
      fieldName = field?.getAttribute("name");
      if (field?.getAttribute("aria-hidden")) {
        // Set focus for MUI select fields - have to set focus to the
        // div preceding the input field
        const focus = field?.previousSibling as HTMLDivElement;
        focus?.focus();
      } else {
        // Set focus for standard text input fields
        field?.focus();
        // Also highlight the text as if you've tabbed here
        (field as HTMLInputElement)?.select();
      }
      break;
    case "FIELDSET":
      // Set focus on the first input for a fieldset
      const input = field?.querySelector("input");
      input?.focus();
      fieldName = input?.getAttribute("name");
      break;
    default:
  }

  if (!!fieldName && setFieldTouched) {
    setFieldTouched(fieldName);
  }
};

type Errors = {
  [key: string]: FieldMessages | boolean;
};

// TODO: do something with boolean filed in Error object
export const getErrorsInOrder = (
  errors: Errors,
  keysInOrder: string[]
): FieldMessages[] => {
  const errorsInOrder: FieldMessages[] = [];
  keysInOrder.forEach((key) => {
    if (errors[key]) {
      if (typeof errors[key] === "boolean") {
        errorsInOrder.push({ error: "TODO: what to do with boolean error?" });
      } else {
        errorsInOrder.push(errors[key] as FieldMessages);
      }
    }
  });
  return errorsInOrder;
};

export const getErrorMessage = (errors: FieldMessages[]): string => {
  if (!errors.length) {
    return "";
  }
  const isPlural = errors.length > 1;
  let errorMessage = `There ${isPlural ? "are" : "is"} ${errors.length} ${
    isPlural ? "errors" : "error"
  }.\n`;
  let errorNumber = 1;
  errors.forEach((fieldMessage) => {
    errorMessage += `${errorNumber}.: ${fieldMessage.error || ""}\n`;
    errorNumber++;
  });
  return errorMessage;
};

export const getErrorMessageInOrder = (
  errors: Errors,
  keysInOrder: string[]
): string => {
  return getErrorMessage(getErrorsInOrder(errors, keysInOrder));
};

export const getFirstErrorKey = (
  errors: Errors,
  keysInOrder: string[]
): string => {
  for (let i = 0; i < keysInOrder.length; i++) {
    if (errors[keysInOrder[i]]) {
      return keysInOrder[i];
    }
  }
  return "";
};
