import {
  MOCK_PRIVILEGE,
  USER_STATUS_ACTIVE,
} from "../../feature/authorisation";
import { AppState } from "../../types/AppState";
import { AuthenticationState } from "../../feature/authentication";

export const selectUserProfile = (/* state: AppState */): {
  privileges: string[];
  status: string;
} => ({
  privileges: [MOCK_PRIVILEGE],
  status: USER_STATUS_ACTIVE,
}); // state.authorisation.profile

export const selectLoginPending = (state: AppState): boolean =>
  state.authentication.loginPending;

export const selectIsLoggedIn = (state: AppState): boolean =>
  Boolean(state.authentication.sessionId) || state.authentication.logoutPending;

export const selectIsLoginFailed = (state: AppState): boolean =>
  state.authentication.loginFailed;

export const selectIsRefreshFailed = (state: AppState): boolean =>
  state.authentication.refreshFailed;

export const selectAuthentication = (state: AppState): AuthenticationState =>
  state.authentication;
