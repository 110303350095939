import { DefinitionResource } from "@saturn-ui/components";
import { JourneyStepsDefinition } from "../../../../feature/journeySteps/JourneyStepsTypes";
import { ourAdviceStepDefinition } from "./steps/OurAdviceStep";
import { aboutYouStepDefinition } from "./steps/AboutYouStep";
import { yourFeelingsStepDefinition } from "./steps/YourFeelingsStep";
import { yourMoneyStepDefinition } from "./steps/YourMoneyStep";

const journeyStepsDefinition: DefinitionResource<JourneyStepsDefinition> = {
  content: {
    steps: {
      content: {
        ourAdvice: ourAdviceStepDefinition,
        aboutYou: aboutYouStepDefinition,
        yourFeelings: yourFeelingsStepDefinition,
        yourMoney: yourMoneyStepDefinition,
      },
    },
  },
};

export default journeyStepsDefinition;
