import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCaseApi } from "skipton-common";
import { AppState } from "../../../types/AppState";
import { mapDataToCustomDataCFFApi } from "../mappers";
import { withSessionId } from "../../../utils/AuthorisationUtils";

export const postCFFCustomData = createAsyncThunk<
  void,
  {
    baseUrl: string;
  },
  { state: AppState }
>(
  "post-cff-custom-data",
  async (
    data: {
      baseUrl: string;
    },
    { getState }
  ): Promise<void> => {
    const { baseUrl } = data;
    const {
      ourAdvice: { initialValues: ourAdviceValues },
      yourFeelings: { initialValues: yourFeelingsValues },
      customData: { remoteCustomData },
    } = getState();

    const caseApi = withSessionId(getCaseApi(baseUrl));

    const customDataBody = mapDataToCustomDataCFFApi(
      ourAdviceValues,
      yourFeelingsValues,
      remoteCustomData
    );

    await caseApi.postCFFCustomData(customDataBody);
  }
);

export const postCFFCustomDataSuccess = postCFFCustomData.fulfilled;
export const postCFFCustomDataPending = postCFFCustomData.pending;
export const postCFFCustomDataFailed = postCFFCustomData.rejected;
