import { useCallback } from "react";
import { postCFFSectionCustomData } from "../feature/customData/thunks";
import { RootState, useRootSelector } from "skipton-features";
import { useSelector } from "react-redux";
import { selectCustomDataSections } from "../redux/selectors/customData";
import { useAppDispatch } from "../redux/store";
import {
  getAboutYouRoute,
  getOurAdviceRoute,
  getYourFeelingsRoute,
  getYourMoneyRoute,
} from "../helpers";
import { setCFFSectionCustomData } from "../feature/customData/CustomDataSlice";
import {
  prepareSectionsWithCompleted,
  prepareSectionsWithStarted,
} from "../utils/sections";
import { HttpRequestError } from "skipton-common";

export enum SectionStatus {
  Completed = "Completed",
  Started = "Started",
}

export enum PersistableSections {
  OurAdvice = "OurAdvice",
  AboutYou = "AboutYou",
  YourMoney = "YourMoney",
  ATR = "ATR",
}

export const persistableSectionsRoutes: Record<string, string> = {
  [PersistableSections.OurAdvice]: getOurAdviceRoute(),
  [PersistableSections.AboutYou]: getAboutYouRoute(),
  [PersistableSections.YourMoney]: getYourMoneyRoute(),
  [PersistableSections.ATR]: getYourFeelingsRoute(),
};

export type UseSaveAndResumeReturnType = {
  commitStepStarted: () => Promise<void>;
  commitStepCompleted: () => Promise<void>;
};

export const useSaveAndResumeLogic = (
  sectionName: PersistableSections
): UseSaveAndResumeReturnType => {
  const sections = useSelector(selectCustomDataSections);
  const dispatch = useAppDispatch();
  const { APIUri } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );

  const commitStepStarted = useCallback(async () => {
    const updatedSections = prepareSectionsWithStarted(sections, sectionName);
    dispatch(setCFFSectionCustomData(updatedSections));
    try {
      await dispatch(
        postCFFSectionCustomData({
          baseUrl: APIUri ?? "",
          sections: updatedSections,
        })
      ).unwrap();
    } catch (error) {
      throw new HttpRequestError(
        (error as HttpRequestError).code,
        (error as HttpRequestError).message
      );
    }
  }, [APIUri, dispatch, sectionName, sections]);

  const commitStepCompleted = useCallback(async () => {
    const updatedSections = prepareSectionsWithCompleted(sections, sectionName);
    dispatch(setCFFSectionCustomData(updatedSections));
    try {
      await dispatch(
        postCFFSectionCustomData({
          baseUrl: APIUri ?? "",
          sections: updatedSections,
        })
      ).unwrap();
    } catch (error) {
      throw new HttpRequestError(
        (error as HttpRequestError).code,
        (error as HttpRequestError).message
      );
    }
  }, [APIUri, dispatch, sectionName, sections]);

  return {
    commitStepStarted,
    commitStepCompleted,
  };
};
