import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  IconButton,
  SubmitButton,
} from "@saturn-ui/components";
import "./ReadyToCompleteDialog.scss";
import { CloseIcon } from "../../../../../assets/icons/CloseIcon";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { RootState, useRootSelector } from "skipton-features";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../../../../helpers/adobeAnalyticsHelpers";

type Props = {
  loading: boolean;
  errorMessage: string | null;
  onCancelClick?: () => void;
  onCompleteClick?: () => void;
};
export const ReadyToCompleteDialog: React.FC<Props> = ({
  loading,
  errorMessage,
  onCancelClick,
  onCompleteClick,
}) => {
  const { isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const {
    financialAdvicePage: {
      clientInfo: { category },
    },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = getVirtualPageCustomerType(category);

      trackVirtualPageView({
        pageName: "Ready to submit?",
        customerType,
      });
    }
  }, [isAdobeAnalyticsLoaded]);

  return (
    <Dialog
      isOpen={true}
      onClose={onCancelClick}
      className="auth-dialog ready-to-complete-dialog"
    >
      <IconButton
        className="dialog-close-button"
        ariaLabel="Close"
        onClick={onCancelClick}
      >
        <CloseIcon />
      </IconButton>
      <h2 className="dialog-title">Ready to complete?</h2>
      <p>
        If you are comfortable with your answers, select ‘Complete journey’.
        This means your answers will be submitted to your adviser to review as
        part of their meeting preparations. You will not be able to go back and
        change your answers after selecting this.
      </p>
      <p>
        If you do not quite feel ready, that is no problem. Select ‘I need more
        time’. This allows you to review and adjust your answers
      </p>
      <div className="dialog-bottom-bar">
        {errorMessage && (
          <div className="dialog-error-message error-message">
            {errorMessage}
          </div>
        )}
        <div className="dialog-actions-wrapper">
          <Button
            className="decorated-button"
            onClick={onCancelClick}
            variant="outlined"
            id="cancel-button"
            disableRipple={true}
          >
            <span className="decoration cancel-button-text">
              I need more time
            </span>
          </Button>
          <SubmitButton
            submitting={loading}
            className="decorated-button"
            onClick={onCompleteClick}
            variant="contained"
            id="complete-button"
            disableRipple={true}
          >
            <span className="decoration">Complete journey</span>
          </SubmitButton>
        </div>
      </div>
    </Dialog>
  );
};
