import { PRIVILEGES } from "../feature/authorisation";
import { IApiBaseService } from "skipton-common";
import store from "../redux/store";

export const checkPrivilege = (userPrivileges: string[]): boolean =>
  PRIVILEGES.some((privilege) => userPrivileges.includes(privilege));

export const withSessionId = <T extends IApiBaseService>(api: T): T => {
  const { sessionId } = store.getState().authentication;
  api.setCustomOptions({ sessionId });
  return api;
};
