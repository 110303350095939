import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import "./AboutYouStep.scss";
import { Layout } from "../../../layout/Layout";
import {
  AboutYouStepContentStates,
  AboutYouStepDefinition,
  AboutYouStepErrors,
  AboutYouStepState,
  AboutYouStepTouched,
  GetTaxStatusListStates,
  InitialiseAboutYouStepStates,
  PropertyFormErrors,
  PropertyFormState,
  PropertyFormTouched,
  SendPartyPersonalStates,
} from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import { useAppDispatch } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { useHistory } from "react-router-dom";
import { getErrorRoute, getOurAdviceRoute } from "../../../../helpers";
import {
  setAboutYouStepValues,
  setIsJointCustomerDialogShown,
  setIsSharingYourInformationDialogShown,
  setProperties,
} from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepSlice";
import {
  initialiseAboutYouStep,
  postPartyPersonal,
} from "../../../../feature/journeySteps/steps/aboutYouStep/thunks";
import { RootState, useRootSelector } from "skipton-features";
import "../../JourneySteps.scss";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
  CLIENT_CATEGORY_SINGLE,
} from "../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import { OverlayContext } from "../../../../contexts/OverlayContext";
import { SharingYourInformationDialog } from "../dialogs/sharingYourInformationDialog/SharingYourInformationDialog";
import {
  PersistableSections,
  useSaveAndResumeLogic,
} from "../../../../hooks/useSaveAndResumeLogic";
import {
  HTTP_UNAUTHORIZED_STATUS_CODE,
  HttpRequestError,
} from "skipton-common";
import { YourPropertyContent } from "./yourPropertyContent/YourPropertyContent";
import {
  createFormScope,
  FormContextProvider,
  FormsConfiguration,
  PuffLoader,
  useFormContainer,
  useFormsConfiguration,
  useLocalization,
} from "@saturn-ui/components";
import { validate } from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepValidation";
import { validate as validateProperty } from "../../../../feature/journeySteps/steps/aboutYouStep/property/PropertyValidation";
import { JointCustomerDialog } from "../dialogs";
import { AboutYouContent } from "./aboutYouContent/AboutYouContent";
import {
  ABOUT_YOU_CLIENT_1_SCOPE_NAME,
  ABOUT_YOU_CLIENT_2_SCOPE_NAME,
  ABOUT_YOU_SCOPE_NAME,
  YOUR_PROPERTY_SCOPE_NAME,
} from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepConstants";
import { defaultProperty } from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepInitialState";
import { ErrorRetry } from "../../../errorRetry/ErrorRetry";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../../../helpers/adobeAnalyticsHelpers";

type Props = {
  definition: AboutYouStepDefinition;
};

export const AboutYouStep: FC<Props> = ({ definition }) => {
  const history = useHistory() as unknown[];
  const dispatch = useAppDispatch();
  const { hideOverlay, showOverlay } = useContext(OverlayContext);
  const { commitStepStarted } = useSaveAndResumeLogic(
    PersistableSections.AboutYou
  );
  const { validation } = useFormsConfiguration<FormsConfiguration>();
  const localization = useLocalization();
  const { APIUri } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const {
    aboutYou: {
      initialValues: {
        aboutYou: {
          [CLIENT_1_SCOPE_NAME]: client1AboutYouValues,
          [CLIENT_2_SCOPE_NAME]: client2AboutYouValues,
        },
        yourProperty,
      },
      properties,
      activePropertyIndex,
      isSharingYourInformationDialogShown,
    },
    financialAdvicePage: {
      clientInfo: {
        salutation: {
          [CLIENT_1_SCOPE_NAME]: client1Salutation,
          [CLIENT_2_SCOPE_NAME]: client2Salutation,
        },
        category,
      },
    },
  } = useSelector((state: AppState) => state);
  const { isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const [initialiseAboutYouStepState, setInitialiseAboutYouStepState] =
    useState(InitialiseAboutYouStepStates.Uninitialised);
  const [aboutYouStepContentState, setAboutYouStepContentState] = useState(
    AboutYouStepContentStates.Client1Content
  );
  const [sendPartyPersonalState, setSendPartyPersonalState] = useState(
    SendPartyPersonalStates.Uninitialised
  );
  const [getTaxStatusListState, setGetTaxStatusListState] = useState(
    GetTaxStatusListStates.Uninitialised
  );

  useEffect(() => {
    if (
      category === CLIENT_CATEGORY_JOINT &&
      isSharingYourInformationDialogShown
    ) {
      showOverlay({
        content: SharingYourInformationDialog,
        props: {
          onCancelClick: hideOverlay,
          onQuitClick: hideOverlay,
        },
      });

      dispatch(setIsSharingYourInformationDialogShown(false));
    }
  }, [
    category,
    dispatch,
    hideOverlay,
    isSharingYourInformationDialogShown,
    showOverlay,
  ]);

  const initialisePage = async () => {
    try {
      setInitialiseAboutYouStepState(InitialiseAboutYouStepStates.Initialising);

      await dispatch(
        initialiseAboutYouStep({
          baseUrl: APIUri ?? "",
        })
      ).unwrap();

      await commitStepStarted();

      setInitialiseAboutYouStepState(InitialiseAboutYouStepStates.Success);
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setInitialiseAboutYouStepState(InitialiseAboutYouStepStates.Error);
    }
  };

  useEffect(() => {
    void initialisePage();
  }, []);

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = getVirtualPageCustomerType(category);

      if (
        aboutYouStepContentState === AboutYouStepContentStates.Client1Content
      ) {
        trackVirtualPageView({
          pageName: "About you",
          customerType,
          ...(category === CLIENT_CATEGORY_JOINT
            ? {
                customerNumber: 1,
              }
            : {}),
        });
      }
      if (
        aboutYouStepContentState === AboutYouStepContentStates.Client2Content
      ) {
        trackVirtualPageView({
          pageName: "About you",
          customerType,
          customerNumber: 2,
        });
      }
      if (aboutYouStepContentState === AboutYouStepContentStates.YourProperty) {
        trackVirtualPageView({
          pageName: "About you – property",
          customerType,
        });
      }
    }
  }, [isAdobeAnalyticsLoaded, aboutYouStepContentState]);

  const formContainer = useFormContainer<
    AboutYouStepState,
    AboutYouStepErrors,
    AboutYouStepTouched
  >({
    initialValues: {
      aboutYou: {
        [CLIENT_1_SCOPE_NAME]: client1AboutYouValues,
        [CLIENT_2_SCOPE_NAME]: client2AboutYouValues,
      },
      yourProperty,
    },
    validateFunc: (aboutYouStepValues) =>
      validate(
        aboutYouStepValues,
        definition,
        validation,
        localization,
        category
      ),
    enableReinitialize: true,
  });

  const { touched, errors, values } = formContainer;

  const formPropertyContainer = useFormContainer<
    PropertyFormState,
    PropertyFormErrors,
    PropertyFormTouched
  >({
    initialValues:
      (properties[activePropertyIndex]?.values as PropertyFormState) ??
      defaultProperty,
    validateFunc: (propertyValues) =>
      validateProperty(
        propertyValues,
        definition.yourPropertyForm.content.fields.content,
        validation,
        localization,
        category
      ),
    enableReinitialize: true,
  });

  const { values: activePropertyValues } = formPropertyContainer;

  const aboutYouClient1Scope = createFormScope({
    name: ABOUT_YOU_CLIENT_1_SCOPE_NAME,
    values: values[ABOUT_YOU_SCOPE_NAME][CLIENT_1_SCOPE_NAME],
    errors: errors?.[ABOUT_YOU_SCOPE_NAME]?.[CLIENT_1_SCOPE_NAME],
    touched: touched?.[ABOUT_YOU_SCOPE_NAME]?.[CLIENT_1_SCOPE_NAME],
    readOnly: false,
    disabled: false,
  });

  const aboutYouClient2Scope = createFormScope({
    name: ABOUT_YOU_CLIENT_2_SCOPE_NAME,
    values: values[ABOUT_YOU_SCOPE_NAME][CLIENT_2_SCOPE_NAME],
    errors: errors?.[ABOUT_YOU_SCOPE_NAME]?.[CLIENT_2_SCOPE_NAME],
    touched: touched?.[ABOUT_YOU_SCOPE_NAME]?.[CLIENT_2_SCOPE_NAME],
    readOnly: false,
    disabled: false,
  });

  const yourPropertyScope = createFormScope({
    name: YOUR_PROPERTY_SCOPE_NAME,
    values: values[YOUR_PROPERTY_SCOPE_NAME],
    errors: errors?.[YOUR_PROPERTY_SCOPE_NAME],
    touched: touched?.[YOUR_PROPERTY_SCOPE_NAME],
    readOnly: false,
    disabled: false,
  });

  const checkSendPartyPersonalState = (...states: SendPartyPersonalStates[]) =>
    !!states.find((state) => state === sendPartyPersonalState);

  const handleBackClick = useCallback(
    (
      aboutYouStepValues: AboutYouStepState,
      propertyValues: PropertyFormState
    ) => {
      history.push(getOurAdviceRoute());
      dispatch(setAboutYouStepValues(aboutYouStepValues));
      dispatch(
        setProperties(
          properties.map((item, index) => {
            if (index === activePropertyIndex) {
              return {
                ...item,
                values: propertyValues,
              };
            }

            return item;
          })
        )
      );
    },
    [dispatch, history, properties, activePropertyIndex]
  );

  const sendPartyPersonal = async (): Promise<void> => {
    try {
      setSendPartyPersonalState(SendPartyPersonalStates.Initialising);

      await dispatch(
        postPartyPersonal({
          baseUrl: APIUri ?? "",
        })
      ).unwrap();
      setSendPartyPersonalState(SendPartyPersonalStates.Success);
      setAboutYouStepContentState(AboutYouStepContentStates.YourProperty);
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setSendPartyPersonalState(SendPartyPersonalStates.Error);
    }
  };

  const handleClient1ContentPressContinue = useCallback(
    async (isDialogShown: boolean) => {
      if (category === CLIENT_CATEGORY_SINGLE) {
        await sendPartyPersonal();

        return;
      }

      if (isDialogShown) {
        showOverlay({
          content: JointCustomerDialog,
          props: {
            salutation: client2Salutation?.forename ?? "",
            onCancelClick: hideOverlay,
            onQuitClick: () => {
              dispatch(setIsJointCustomerDialogShown(false));
              setAboutYouStepContentState(
                AboutYouStepContentStates.Client2Content
              );
              hideOverlay();
            },
          },
        });

        return;
      }

      setAboutYouStepContentState(AboutYouStepContentStates.Client2Content);
    },
    [
      category,
      checkSendPartyPersonalState,
      sendPartyPersonal,
      showOverlay,
      client2Salutation?.forename,
      hideOverlay,
      dispatch,
    ]
  );

  const handleClient2ContentPressContinue = useCallback(async () => {
    await sendPartyPersonal();
  }, [setAboutYouStepContentState, sendPartyPersonal]);

  const handleClient2ContentPressBack = useCallback(() => {
    setAboutYouStepContentState(AboutYouStepContentStates.Client1Content);
  }, [setAboutYouStepContentState]);

  const handleYourPropertyContentPressBack = useCallback(() => {
    if (category === CLIENT_CATEGORY_SINGLE) {
      setAboutYouStepContentState(AboutYouStepContentStates.Client1Content);
    }
    if (category === CLIENT_CATEGORY_JOINT) {
      setAboutYouStepContentState(AboutYouStepContentStates.Client2Content);
    }
  }, [setAboutYouStepContentState]);

  const checkInitialiseAboutYouStepState = (
    ...states: InitialiseAboutYouStepStates[]
  ) => !!states.find((state) => state === initialiseAboutYouStepState);

  const checkGetTaxStatusListState = (...states: GetTaxStatusListStates[]) =>
    !!states.find((state) => state === getTaxStatusListState);

  const renderAboutYouStepFormContent = () => {
    return (
      <FormContextProvider value={formContainer}>
        <form
          onSubmitCapture={(e) => e.preventDefault()}
          onSubmit={(e) => e.preventDefault()}
          autoComplete="off"
          noValidate
        >
          {aboutYouStepContentState ===
            AboutYouStepContentStates.Client1Content && (
            <AboutYouContent
              scope={aboutYouClient1Scope}
              clientScopeName={CLIENT_1_SCOPE_NAME}
              definition={definition}
              title={
                category === CLIENT_CATEGORY_SINGLE
                  ? "About you"
                  : `${client1Salutation?.forename ?? ""}, about you`
              }
              salutation={client1Salutation?.forename ?? ""}
              setGetTaxStatusListState={setGetTaxStatusListState}
              checkGetTaxStatusListState={checkGetTaxStatusListState}
              handleContinueClick={handleClient1ContentPressContinue}
              checkSendPartyPersonalState={checkSendPartyPersonalState}
            />
          )}
          {aboutYouStepContentState ===
            AboutYouStepContentStates.Client2Content && (
            <AboutYouContent
              scope={aboutYouClient2Scope}
              clientScopeName={CLIENT_2_SCOPE_NAME}
              definition={definition}
              title={`${client2Salutation?.forename ?? ""}, about you`}
              salutation={client2Salutation?.forename ?? ""}
              setGetTaxStatusListState={setGetTaxStatusListState}
              checkGetTaxStatusListState={checkGetTaxStatusListState}
              handleContinueClick={handleClient2ContentPressContinue}
              handleBackClick={handleClient2ContentPressBack}
              checkSendPartyPersonalState={checkSendPartyPersonalState}
            />
          )}

          {aboutYouStepContentState ===
            AboutYouStepContentStates.YourProperty && (
            <YourPropertyContent
              scope={yourPropertyScope}
              definition={definition}
              handleBackClick={handleYourPropertyContentPressBack}
              formPropertyContainer={formPropertyContainer}
            />
          )}
        </form>
      </FormContextProvider>
    );
  };

  const renderAboutYouStepContent = () => {
    if (
      checkInitialiseAboutYouStepState(
        InitialiseAboutYouStepStates.Initialising
      )
    ) {
      return (
        <div className="journey-step about-you-step full-width">
          <div className="journey-step-preloader-wrapper">
            <PuffLoader />
          </div>
        </div>
      );
    }

    if (checkInitialiseAboutYouStepState(InitialiseAboutYouStepStates.Error)) {
      return (
        <div className="journey-step about-you-step">
          <div className="left-side journey-step-error-retry-wrapper">
            <ErrorRetry handleRetryButtonClick={initialisePage} />
          </div>
          <div className="right-side" />
        </div>
      );
    }

    if (checkGetTaxStatusListState(GetTaxStatusListStates.Error)) {
      return (
        <div className="journey-step about-you-step">
          {renderAboutYouStepFormContent()}
        </div>
      );
    }

    return (
      <div className="journey-step about-you-step full-width">
        <div className="journey-step-bg">
          <div className="content-wrapper">
            <div className="step-pagination" aria-hidden="true">
              Step 2 of 4
            </div>
            {renderAboutYouStepFormContent()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout
      backButtonText="Back"
      onBackPress={() => handleBackClick(values, activePropertyValues)}
      alwaysHavePadding={true}
      progressStepperProps={{
        numberOfSteps: 4,
        active: 2,
        maxStepDone: 1,
      }}
    >
      {renderAboutYouStepContent()}
    </Layout>
  );
};
