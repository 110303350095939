import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCaseApi } from "skipton-common";
import { AppState } from "../../../../../types/AppState";
import { mapDataToPropertyApi } from "../mappers";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";
import { PropertyFormState } from "../AboutYouStepTypes";

export const postProperty = createAsyncThunk<
  void,
  {
    baseUrl: string;
    property: PropertyFormState;
  },
  { state: AppState }
>(
  "post-property",
  async (
    data: {
      baseUrl: string;
      property: PropertyFormState;
    },
    { getState }
  ): Promise<void> => {
    const { baseUrl, property } = data;
    const {
      financialAdvicePage: {
        clientInfo: { category },
      },
    } = getState();

    const caseApi = withSessionId(getCaseApi(baseUrl));
    const propertyBody = mapDataToPropertyApi(property, category);

    await caseApi.postProperty(propertyBody);
  }
);

export const postPropertySuccess = postProperty.fulfilled;
export const postPropertyPending = postProperty.pending;
export const postPropertyFailed = postProperty.rejected;
