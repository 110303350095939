export const CONFIRMATION_FORM_CONFIRM_READ_FIELD = "confirmRead";
export const CONFIRMATION_FORM_CONFIRM_NO_QUESTIONS_FIELD =
  "confirmNoQuestions";

export const LOGIN_FORM_ID_FIELD = "id";

export const confirmationFormFieldKeys = [
  CONFIRMATION_FORM_CONFIRM_READ_FIELD,
  CONFIRMATION_FORM_CONFIRM_NO_QUESTIONS_FIELD,
];
