import React, { FC } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { DeleteIcon, ExpandMoreIcon } from "../../../../assets";
import "./Accordion.scss";
import { backButtonSx } from "../../../../theme/skiptonLightThemeOverrides";
import { Button } from "@saturn-ui/components";
import { JourneyStepsAccordionItemProps } from "../../../../feature/journeySteps/JourneyStepsTypes";
import { PropertyFormState } from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";

const CustomAccordion = (props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
);

const AccordionSummary = (props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
);

export type CustomAccordionProps = JourneyStepsAccordionItemProps & {
  captions: {
    headerTitle: string;
    addButtonTitle: string;
    saveButtonTitle: string;
  };
  index: number;
  handleDeleteItem: (index: number) => void;
  handleToggleItem: (index: number) => void;
  handleSaveItem: (
    index: number
  ) => (values: PropertyFormState, collapseSavedItem?: boolean) => void;
  handleSetValuesAndAllowContinue: (
    index: number
  ) => (values: PropertyFormState) => void;
};

export const Accordion: FC<CustomAccordionProps> = ({
  captions,
  index,
  isSaved,
  expanded,
  values,
  SummaryComponent,
  DetailsComponent,
  SavedDetailsComponent,
  handleDeleteItem,
  handleToggleItem,
  handleSaveItem,
  handleSetValuesAndAllowContinue,
}) => {
  return (
    <div className="custom-accordion" aria-label="Expanding block">
      {!isSaved && index !== 0 && (
        <Button
          id="delete-button"
          data-testid="delete-button"
          className="custom-accordion-button delete-button"
          sx={backButtonSx}
          startIcon={<DeleteIcon />}
          onClick={() => handleDeleteItem(index)}
          disableRipple={true}
          aria-label="delete"
          variant="text"
        />
      )}
      <CustomAccordion expanded={expanded}>
        <AccordionSummary
          onClick={() => handleToggleItem(index)}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${captions.headerTitle}-${index}-content`}
          id={`${captions.headerTitle}-${index}-header`}
        >
          <div className="custom-accordion-header">
            <h2 className="custom-accordion-title">{`${captions.headerTitle} ${
              index + 1
            }`}</h2>
          </div>
          {isSaved && (
            <div className="custom-accordion-summary">
              <SummaryComponent values={values} />
            </div>
          )}
        </AccordionSummary>
        <MuiAccordionDetails>
          {isSaved ? (
            <SavedDetailsComponent
              values={values}
              setValuesAndAllowContinue={handleSetValuesAndAllowContinue(index)}
            />
          ) : (
            <div className="custom-accordion-details">
              <DetailsComponent
                handleSaveItem={handleSaveItem(index)}
                values={values}
                index={index}
                saveButtonTitle={captions.saveButtonTitle}
              />
            </div>
          )}
        </MuiAccordionDetails>
      </CustomAccordion>
    </div>
  );
};
