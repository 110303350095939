import { OurAdviceStepDefinition } from "./OurAdviceStepTypes";

export const template: OurAdviceStepDefinition = {
  form: {
    type: "group",
    content: {
      fields: {
        type: "group",
        content: {
          confirmWatchVideo: {
            type: "boolean",
          },
          isQuestion: {
            type: "boolean",
          },
          question: {
            type: "string",
          },
        },
      },
    },
  },
};
