import { CaseApi } from "skipton-common";
import { Answers } from "../YourFeelingsStepTypes";

export const mapDataToPartyATRApi = (
  id: string,
  answers: Answers
): CaseApi.PartyATR => {
  return {
    id,
    answers: Object.keys(answers).map((id) => ({
      questionId: id,
      answerId: answers[id]?.id || "",
    })),
  };
};
