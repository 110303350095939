import { CaseApi } from "skipton-common";

export const mapResponseToCustomSectionsState = (
  response: CaseApi.CFFSectionCustomDataResponseModel
): CaseApi.CFFSection[] => {
  if (!response.CFFSection) {
    return [];
  }

  if (Array.isArray(response.CFFSection.section)) {
    return response.CFFSection.section;
  }

  if (
    typeof response.CFFSection.section === "object" &&
    Object.keys(response.CFFSection.section).length
  ) {
    return [response.CFFSection.section];
  }

  return [];
};
