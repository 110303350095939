import isNil from "lodash/isNil";

type Normal = 1 | -1;
type IntervalUpdateCallback = (state: TimerState) => void;

export type TimerState = {
  readonly left: number;
  readonly current: number;
  readonly isEnded: boolean;
};
export type TimerPayload = {
  from?: number;
  to?: number;
  step?: number;
  interval?: number;
  direction?: Normal;
  onIntervalUpdate?: IntervalUpdateCallback;
};
export interface TimerInterface extends TimerPayload, TimerState {
  interval?: number;

  stop(): void;
  reset(): void;
  start(payload?: TimerPayload): void;
  restart(payload?: TimerPayload): void;

  setIntervalUpdateCallback(callback: IntervalUpdateCallback): void;
}

const DEFAULT_STEP = 1;
const DEFAULT_TO = Infinity;
const DEFAULT_DIRECTION: Normal = -1;
const DEFAULT_TIMER_INTERVAL_MS = 1000;

export class Timer implements TimerInterface {
  public get current(): number {
    return this._current || 0;
  }

  public get left(): number {
    if (isNil(this.to) || isNil(this.direction)) {
      return 0;
    }
    return this.current + this.to * this.direction;
  }

  public get isEnded(): boolean {
    return this.current === this.to;
  }

  public get onIntervalUpdate(): IntervalUpdateCallback | undefined {
    return this._onIntervalUpdate;
  }

  public to: number | undefined;
  public from: number | undefined;
  public step: number | undefined;
  public interval: number | undefined;
  public direction: Normal | undefined;

  private _current: number | undefined;
  private timeoutId?: number;
  private _onIntervalUpdate?: IntervalUpdateCallback;

  constructor(payload: TimerPayload) {
    this.init = this.init.bind(this);
    this.stop = this.stop.bind(this);
    this.start = this.start.bind(this);
    this.reset = this.reset.bind(this);
    this.restart = this.restart.bind(this);
    this.validateRange = this.validateRange.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.doIntervalStep = this.doIntervalStep.bind(this);
    this.handleIntervalEnd = this.handleIntervalEnd.bind(this);
    this.setIntervalUpdateCallback = this.setIntervalUpdateCallback.bind(this);

    this.init(payload);
  }

  public start(payload?: TimerPayload): void {
    if (!isNil(payload)) {
      this.init(payload);
    }
    this.nextTick(); // necessary for the actual state from the first second
    this.handleTimeout();
  }

  public restart(payload?: TimerPayload): void {
    this.reset();
    this.start(payload);
  }

  public stop(): void {
    clearTimeout(this.timeoutId);
  }

  public reset(): void {
    this.stop();

    this._current = this.from;
    this.timeoutId = undefined;
  }

  public setIntervalUpdateCallback(callback: IntervalUpdateCallback): void {
    this._onIntervalUpdate = callback;
  }

  private init({
    from,
    onIntervalUpdate,
    to = DEFAULT_TO,
    step = DEFAULT_STEP,
    direction = DEFAULT_DIRECTION,
    interval = DEFAULT_TIMER_INTERVAL_MS,
  }: TimerPayload): void {
    this.validateRange({
      to,
      from,
      step,
      interval,
      direction,
    });

    this.to = to;
    this.from = from;
    this.step = step;
    this.interval = interval;
    this.direction = direction;

    this._current = from;
    this._onIntervalUpdate = onIntervalUpdate;
  }

  private handleTimeout(): void {
    this.timeoutId = setTimeout(
      this.doIntervalStep.bind(this),
      this.interval
    ) as unknown as number;
  }

  private doIntervalStep(): void {
    if (isNil(this.step) || isNil(this.direction)) {
      return;
    }

    this._current = this.current + this.step * this.direction;

    this.nextTick();

    if (this._current === this.to) {
      this.handleIntervalEnd();

      return;
    } else {
      this.handleTimeout();
    }
  }

  private nextTick() {
    if (!isNil(this.onIntervalUpdate)) {
      const nextState: TimerState = {
        left: this.left,
        current: this.current,
        isEnded: this.isEnded,
      };

      this.onIntervalUpdate(nextState);
    }
  }

  private validateRange(payload: TimerPayload) {
    if (isNil(payload.to) || isNil(payload.from)) {
      console.log("Timer range incorrect. It will be executed indefinitely");

      return;
    }

    const isValid =
      payload.direction === -1
        ? payload.from > payload.to
        : payload.from < payload.to;

    if (!isValid) {
      throw new Error("Timer range is not valid");
    }
  }

  private handleIntervalEnd() {
    this.stop();
  }
}
