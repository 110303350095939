import { DefinitionResource } from "@saturn-ui/components";
import { ErrorPageDefinition } from "skipton-common";

const errorPageDefinition: DefinitionResource<ErrorPageDefinition> = {
  content: {
    errorPageHeading: { caption: "Oops, something’s not working" },
    errorPageTopLabel: {
      caption:
        "We're really sorry about this, but the system seems to be unavailable at the moment.  Please check recent communications about planned downtime in the first instance.",
    },
    errorPageBottomLabel: {
      caption: "For further support and information, please contact ",
    },
    errorPageBottomContactLabel: {
      caption: "Management Support mailbox.",
    },
    topLogo: {
      caption: "",
    },
  },
};

export default errorPageDefinition;
