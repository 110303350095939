import { WritableDraft } from "immer/dist/types/types-external";
import {
  YourMoneyStepErrors,
  YourMoneyStepState,
  YourMoneyStepTouched,
} from "../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";
import {
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_FORM_KNOW_POLICY_REF_NUM,
  PROVISION_FORM_POLICY_REFERENCE_NUMBER,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_WHO_BELONG_TO,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
  PROVISION_SCOPE_NAME,
} from "../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepConstants";
import { ListItem, UpdateStateFunction } from "@saturn-ui/components";
import { List } from "../../../../feature/journeySteps/JourneyStepsTypes";

export const resetReferenceNumberField = (
  values: WritableDraft<YourMoneyStepState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): WritableDraft<YourMoneyStepState> => {
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_POLICY_REFERENCE_NUMBER}`,
    false
  );
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_POLICY_REFERENCE_NUMBER] = null;
  return values;
};

export const resetWhoIsItHeldWithOtherField = (
  values: WritableDraft<YourMoneyStepState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): WritableDraft<YourMoneyStepState> => {
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER}`,
    false
  );
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER] = null;
  return values;
};

export const resetEachFieldExceptHoldingType = (
  values: WritableDraft<YourMoneyStepState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): WritableDraft<YourMoneyStepState> => {
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_HOW_MUCH_IS_IT_WORTH}`,
    false
  );
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_KNOW_POLICY_REF_NUM}`,
    false
  );
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_PRODUCT_TYPE}`,
    false
  );
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_WHO_IS_IT_HELD_WITH}`,
    false
  );
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER}`,
    false
  );
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_WHO_BELONG_TO}`,
    false
  );
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_POLICY_REFERENCE_NUMBER}`,
    false
  );

  values[PROVISION_SCOPE_NAME][PROVISION_FORM_HOW_MUCH_IS_IT_WORTH] = null;
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_KNOW_POLICY_REF_NUM] = null;
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_PRODUCT_TYPE] = null;
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH] = null;
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER] = null;
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_BELONG_TO] = null;
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_POLICY_REFERENCE_NUMBER] = null;
  return values;
};

export const handleHoldingTypeFieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      YourMoneyStepState,
      YourMoneyStepErrors,
      YourMoneyStepTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) =>
    resetEachFieldExceptHoldingType(values, setFieldTouched)
  );
};

export const handleWhoIsItHeldWithFieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      YourMoneyStepState,
      YourMoneyStepErrors,
      YourMoneyStepTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) =>
    resetWhoIsItHeldWithOtherField(values, setFieldTouched)
  );
};

export const handleIsPolicyReferenceNumberKnownFieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      YourMoneyStepState,
      YourMoneyStepErrors,
      YourMoneyStepTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) => resetReferenceNumberField(values, setFieldTouched));
};

export const resetProductTypeField = (
  values: WritableDraft<YourMoneyStepState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): WritableDraft<YourMoneyStepState> => {
  setFieldTouched(
    `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_PRODUCT_TYPE}`,
    false
  );
  values[PROVISION_SCOPE_NAME][PROVISION_FORM_PRODUCT_TYPE] = null;
  return values;
};

export const handleWhoBelongToFieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      YourMoneyStepState,
      YourMoneyStepErrors,
      YourMoneyStepTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) => resetProductTypeField(values, setFieldTouched));
};

export const getWhoBelongToList = (
  name1: string | null | undefined,
  name2: string | null | undefined,
  showBoth: boolean
): List => {
  const party1 = name1 || "Party 1";
  const party2 = name2 || "Party 2";

  const items: ListItem[] = [
    {
      id: "Party1",
      displayText: party1,
    },
    {
      id: "Party2",
      displayText: party2,
    },
  ];

  if (showBoth) {
    items.push({
      id: "J",
      displayText: `${party1} & ${party2}`,
    });
  }

  return {
    listType: "whoBelongToList",
    lang: "en-GB",
    items,
  };
};

export const excludeEachISAFromList = (list: ListItem[]): ListItem[] => {
  const IsaIdList = ["ISA", "JISA", "LISA"];
  return list.filter((listItem) => !IsaIdList.includes(listItem.id));
};
