import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../types/AppState";
import LogoSvg from "../../assets/logo/logo.svg";

interface Props {
  className?: string;
}

const Logo: React.FC<Props> = ({ className }) => {
  const logoAltText = useSelector(
    (state: AppState) =>
      state.configuration.data.definitions.logo.altText.caption
  );

  return <img src={LogoSvg} className={className} alt={logoAltText || ""} />;
};

export default Logo;
