import {
  FieldValidator,
  Localization,
  ValidationConfiguration,
} from "@saturn-ui/components";
import {
  PropertyFormErrors,
  PropertyFormState,
  YourPropertyFormFieldsDefinition,
} from "../AboutYouStepTypes";
import {
  ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD,
} from "../AboutYouStepConstants";
import { ClientCategory } from "../../../../financialAdvicePage/FinancialAdvicePageTypes";
import {
  CLIENT_CATEGORY_JOINT,
  CLIENT_CATEGORY_SINGLE,
} from "../../../../financialAdvicePage/FinancialAdvicePageConstants";

export const validate = (
  propertyValues: PropertyFormState,
  definition: YourPropertyFormFieldsDefinition,
  config: ValidationConfiguration,
  localization: Localization,
  category: ClientCategory
): {
  errors: PropertyFormErrors;
  isValid: boolean;
} => {
  const errors: PropertyFormErrors = {};
  let percentageSumValid = true;

  const fv = new FieldValidator<PropertyFormErrors>(
    errors,
    config,
    localization
  );

  fv.checkSelect(
    definition[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD],
    propertyValues[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD] || null,
    ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD
  );

  fv.checkSelect(
    definition[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD],
    propertyValues[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD] || null,
    ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD
  );

  if (propertyValues[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?.id === "Other") {
    fv.checkPercentage(
      definition[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD],
      propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD],
      ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD
    );

    if (
      category === CLIENT_CATEGORY_SINGLE &&
      propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] !==
        null &&
      (propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] ?? 0) <
        1
    ) {
      fv.checkText(
        definition[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD],
        propertyValues[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD],
        ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD
      );
    }

    if (category === CLIENT_CATEGORY_JOINT) {
      fv.checkPercentage(
        definition[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD],
        propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD],
        ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD
      );

      const percentageSum =
        (propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] ??
          0) +
        (propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD] ??
          0);

      if (percentageSum > 1) {
        errors[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM] = true;
        percentageSumValid = false;
      }

      if (
        propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] !==
          null &&
        propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD] !==
          null &&
        percentageSum < 1
      ) {
        fv.checkText(
          definition[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD],
          propertyValues[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD],
          ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD
        );
      }
    }
  }

  fv.checkNumber(
    definition[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD],
    propertyValues[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD],
    ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD
  );

  fv.checkSelect(
    definition[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD],
    propertyValues[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD] || null,
    ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD
  );

  if (
    propertyValues[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]?.id ===
    "Yes"
  ) {
    fv.checkNumber(
      definition[ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD],
      propertyValues[ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD],
      ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD
    );
  }

  return {
    errors,
    isValid: fv.isValid() && percentageSumValid,
  };
};
