import {
  FieldValidator,
  Localization,
  ValidationConfiguration,
} from "@saturn-ui/components";
import {
  OurAdviceStepFormDefinition,
  OurAdviceStepFormErrors,
  OurAdviceStepFormState,
} from "./OurAdviceStepTypes";
import {
  OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  OUR_ADVICE_FORM_IS_QUESTION_FIELD,
  OUR_ADVICE_FORM_QUESTION_FIELD,
} from "./OurAdviceStepConstants";

export const validate = (
  ourAdviceValues: OurAdviceStepFormState,
  definition: OurAdviceStepFormDefinition,
  config: ValidationConfiguration,
  localization: Localization
): { errors: OurAdviceStepFormErrors; isValid: boolean } => {
  const errors: OurAdviceStepFormErrors = {};

  const fv = new FieldValidator<OurAdviceStepFormErrors>(
    errors,
    config,
    localization
  );

  fv.checkBoolean(
    definition.fields.content[OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD],
    ourAdviceValues[OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD] || null,
    OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD
  );

  fv.checkBoolean(
    definition.fields.content[OUR_ADVICE_FORM_IS_QUESTION_FIELD],
    ourAdviceValues[OUR_ADVICE_FORM_IS_QUESTION_FIELD],
    OUR_ADVICE_FORM_IS_QUESTION_FIELD
  );

  if (ourAdviceValues[OUR_ADVICE_FORM_IS_QUESTION_FIELD]) {
    fv.checkText(
      definition.fields.content[OUR_ADVICE_FORM_QUESTION_FIELD],
      ourAdviceValues[OUR_ADVICE_FORM_QUESTION_FIELD],
      OUR_ADVICE_FORM_QUESTION_FIELD
    );
  }

  return {
    errors,
    isValid: fv.isValid(),
  };
};
