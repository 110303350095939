import { UpdateStateFunction } from "@saturn-ui/components";
import {
  OurAdviceStepFormErrors,
  OurAdviceStepFormState,
  OurAdviceStepFormTouched,
} from "../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepTypes";
import { WritableDraft } from "immer/dist/types/types-external";
import { OUR_ADVICE_FORM_QUESTION_FIELD } from "../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepConstants";

export const resetQuestionField = (
  values: WritableDraft<OurAdviceStepFormState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): WritableDraft<OurAdviceStepFormState> => {
  setFieldTouched(OUR_ADVICE_FORM_QUESTION_FIELD, false);

  values[OUR_ADVICE_FORM_QUESTION_FIELD] = null;
  return values;
};

export const handleIsQuestionFieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      OurAdviceStepFormState,
      OurAdviceStepFormErrors,
      OurAdviceStepFormTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) => resetQuestionField(values, setFieldTouched));
};
