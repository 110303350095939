import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
} from "../../../financialAdvicePage/FinancialAdvicePageConstants";

export const ABOUT_YOU_FORM_MARITAL_STATUS_FIELD = "maritalStatus";
export const ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD = "employmentStatus";
export const ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD = "grossAnnualIncome";
export const ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD = "taxJurisdiction";
export const ABOUT_YOU_FORM_TAX_STATUS_FIELD = "taxStatus";
export const ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD = "fullISAAllowance";
export const ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD =
  "nationalInsuranceNumber";

export const ABOUT_YOU_STEP_MARITAL_STATUS_LIST = "maritalStatus";
export const ABOUT_YOU_STEP_TAX_STATUS_LIST = "taxStatus";
export const ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST = "TJ1";
export const ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST = "TJ2";
export const ABOUT_YOU_STEP_TAX_JURISDICTION_LIST = "taxJurisdiction";

export const ABOUT_YOU_STEP_YOUR_PROPERTY_SCOPE_NAME = "yourProperty";

export const ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD = "isOwnProperty";
export const ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD = "propertyType";
export const ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD = "ownership";
export const ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD =
  "ownershipPercentageClient1";
export const ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD =
  "ownershipPercentageClient2";
export const ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD =
  "otherOwnerDetails";
export const ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD = "propertyValue";
export const ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD =
  "isPropertyMortgage";
export const ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD =
  "mortgageRemaining";
export const ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM = "percentageSum";

export const ABOUT_YOU_SCOPE_NAME = "aboutYou";
export const YOUR_PROPERTY_SCOPE_NAME = "yourProperty";

export const ABOUT_YOU_CLIENT_1_SCOPE_NAME = `${ABOUT_YOU_SCOPE_NAME}.${CLIENT_1_SCOPE_NAME}`;
export const ABOUT_YOU_CLIENT_2_SCOPE_NAME = `${ABOUT_YOU_SCOPE_NAME}.${CLIENT_2_SCOPE_NAME}`;

export const aboutYouStepFieldKeys = [
  ABOUT_YOU_FORM_MARITAL_STATUS_FIELD,
  ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD,
  ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD,
  ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD,
  ABOUT_YOU_FORM_TAX_STATUS_FIELD,
  ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD,
  ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD,
];

export const propertyFormFieldKeys = [
  ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM,
];
