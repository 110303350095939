import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const propertyTypeList: List = {
  listType: "propertyType",
  lang: "en-GB",
  items: [
    {
      id: "MAIN",
      displayText: "Main Residence",
    },
    {
      id: "2ND",
      displayText: "2nd Residence",
    },
    {
      id: "BTL",
      displayText: "Buy to Let",
    },
    {
      id: "COMPROP",
      displayText: "Commercial Property",
    },
    {
      id: "HOL",
      displayText: "Holiday Home",
    },
  ],
};
