import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const CalendarIcon: React.FC<Props> = ({
  className,
  height = 20,
  width = 20,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <path
      d="M18.6381 7.33636H1.36206V2.56836H18.6381V7.33636ZM1.96206 6.73636H18.0381V3.16836H1.96206V6.73636Z"
      fill="#77787A"
    />
    <path
      d="M18.6381 18.9943H1.36206V6.73633H18.6381V18.9923V18.9943ZM11.0241 9.33833L8.45406 11.1123L8.90806 11.7703L10.2221 10.8623V15.6583H11.0221V9.33833H11.0241Z"
      fill="#77787A"
    />
    <path
      d="M5.02407 1.00586H4.42407V4.72786H5.02407V1.00586Z"
      fill="#77787A"
    />
    <path
      d="M15.5761 1.00586H14.9761V4.72786H15.5761V1.00586Z"
      fill="#77787A"
    />
  </SvgIcon>
);
