import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const providerNameList: List = {
  listType: "providerName",
  lang: "en-GB",
  items: [
    {
      id: "AEGON",
      displayText: "AEGON",
    },
    {
      id: "AegonCofundsPlatform",
      displayText: "Aegon Cofunds Platform",
    },
    {
      id: "Aviva",
      displayText: "Aviva",
    },
    {
      id: "Fidelity",
      displayText: "Fidelity",
    },
    {
      id: "Halifax",
      displayText: "Halifax",
    },
    {
      id: "HargreavesLansdown",
      displayText: "Hargreaves Lansdown",
    },
    {
      id: "Legal&General",
      displayText: "Legal & General",
    },
    {
      id: "LloydsBank",
      displayText: "Lloyds Bank",
    },
    {
      id: "NEST",
      displayText: "NEST",
    },
    {
      id: "PhoenixLife&Pensions",
      displayText: "Phoenix Life & Pensions",
    },
    {
      id: "Prudential",
      displayText: "Prudential",
    },
    {
      id: "Quilter",
      displayText: "Quilter",
    },
    {
      id: "ReassureUK",
      displayText: "Reassure UK",
    },
    {
      id: "RoyalLondon",
      displayText: "Royal London",
    },
    {
      id: "SantanderAssetManagement",
      displayText: "Santander Asset Management",
    },
    {
      id: "SantanderUKplc",
      displayText: "Santander UK plc",
    },
    {
      id: "ScottishWidows",
      displayText: "Scottish Widows",
    },
    {
      id: "StandardLifeAssuranceLtd",
      displayText: "Standard Life Assurance Ltd",
    },
    {
      id: "VirginMoney",
      displayText: "Virgin Money",
    },
    {
      id: "Other",
      displayText: "Other",
    },
  ],
};
