import {
  FormScope,
  GroupContent,
  GroupDef,
  TextFieldDef,
  ListItem,
  FieldMessages,
  ListFieldDef,
  NumberFieldDef,
} from "@saturn-ui/components";
import {
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_FORM_KNOW_POLICY_REF_NUM,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
  PROVISION_FORM_WHO_BELONG_TO,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_KNOW_POLICY_REF_NUM_LIST,
  PROVISION_PRODUCT_TYPE_LIST_INVESTMENT,
  PROVISION_PRODUCT_TYPE_LIST_PENSION,
  PROVISION_PRODUCT_TYPE_LIST_SAVINGS,
  PROVISION_TYPE_OF_HOLDING_LIST,
  PROVISION_WHO_BELONG_TO_LIST,
  PROVISION_WHO_IS_IT_HELD_WITH_LIST,
  PROVISION_SCOPE_NAME,
  PROVISION_FORM_POLICY_REFERENCE_NUMBER,
  PROVISION_ID,
  PROVISION_FORM_UPLOADED_FILES,
} from "./YourMoneyStepConstants";
import { JourneyStepsAccordionItemProps } from "../../JourneyStepsTypes";

export type DocumentData = {
  name: string;
  uploaded: boolean;
};

export enum SendDocumentStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export type YourMoneyStepDefinition = {
  provisionForm: GroupDef & GroupContent<ProvisionFormDefinition>;
};

export type ProvisionFormDefinition = {
  fields: GroupDef & GroupContent<ProvisionFormFieldsDefinition>;
};

export type ProvisionFormFieldsDefinition = {
  [PROVISION_FORM_TYPE_OF_HOLDING]: ListFieldDef;
  [PROVISION_FORM_WHO_BELONG_TO]: ListFieldDef;
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH]: ListFieldDef;
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER]: TextFieldDef;
  [PROVISION_FORM_PRODUCT_TYPE]: ListFieldDef;
  [PROVISION_FORM_KNOW_POLICY_REF_NUM]: ListFieldDef;
  [PROVISION_FORM_POLICY_REFERENCE_NUMBER]: TextFieldDef;
  [PROVISION_FORM_HOW_MUCH_IS_IT_WORTH]: NumberFieldDef;
};

export type YourMoneyStepLists = {
  [PROVISION_TYPE_OF_HOLDING_LIST]: ListItem[] | null;
  [PROVISION_WHO_BELONG_TO_LIST]: ListItem[] | null;
  [PROVISION_WHO_IS_IT_HELD_WITH_LIST]: ListItem[] | null;
  [PROVISION_PRODUCT_TYPE_LIST_SAVINGS]: ListItem[] | null;
  [PROVISION_PRODUCT_TYPE_LIST_INVESTMENT]: ListItem[] | null;
  [PROVISION_PRODUCT_TYPE_LIST_PENSION]: ListItem[] | null;
  [PROVISION_KNOW_POLICY_REF_NUM_LIST]: ListItem[] | null;
};

export type YourMoneyStepMeta = {
  provisions: JourneyStepsAccordionItemProps[];
  activeProvisionIndex: number;
  navigationDisabled: boolean;
  lists: YourMoneyStepLists;
};

export type YourMoneyStepState = {
  [PROVISION_SCOPE_NAME]: ProvisionFormState;
};

export type YourMoneyStepErrors = {
  [PROVISION_SCOPE_NAME]: ProvisionFormErrors;
};

export type YourMoneyStepTouched = {
  [PROVISION_SCOPE_NAME]: ProvisionFormTouched;
};

export type ProvisionFormState = {
  [PROVISION_FORM_TYPE_OF_HOLDING]: ListItem | null;
  [PROVISION_FORM_WHO_BELONG_TO]: ListItem | null;
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH]: ListItem | null;
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER]: string | null;
  [PROVISION_FORM_PRODUCT_TYPE]: ListItem | null;
  [PROVISION_FORM_KNOW_POLICY_REF_NUM]: ListItem | null;
  [PROVISION_FORM_POLICY_REFERENCE_NUMBER]: string | null;
  [PROVISION_FORM_HOW_MUCH_IS_IT_WORTH]: number | null;
  [PROVISION_ID]: string | null;
  [PROVISION_FORM_UPLOADED_FILES]: string[];
};

export type ProvisionFormErrors = {
  [PROVISION_FORM_TYPE_OF_HOLDING]?: FieldMessages;
  [PROVISION_FORM_WHO_BELONG_TO]?: FieldMessages;
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH]?: FieldMessages;
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER]?: FieldMessages;
  [PROVISION_FORM_PRODUCT_TYPE]?: FieldMessages;
  [PROVISION_FORM_KNOW_POLICY_REF_NUM]?: FieldMessages;
  [PROVISION_FORM_POLICY_REFERENCE_NUMBER]?: FieldMessages;
  [PROVISION_FORM_HOW_MUCH_IS_IT_WORTH]?: FieldMessages;
};

export type ProvisionFormTouched = {
  [PROVISION_FORM_TYPE_OF_HOLDING]?: boolean;
  [PROVISION_FORM_WHO_BELONG_TO]?: boolean;
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH]?: boolean;
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER]?: boolean;
  [PROVISION_FORM_PRODUCT_TYPE]?: boolean;
  [PROVISION_FORM_KNOW_POLICY_REF_NUM]?: boolean;
  [PROVISION_FORM_POLICY_REFERENCE_NUMBER]?: boolean;
  [PROVISION_FORM_HOW_MUCH_IS_IT_WORTH]?: boolean;
};

export type ProvisionFormScope = FormScope<
  ProvisionFormState,
  ProvisionFormErrors,
  ProvisionFormTouched
>;

export enum SendProvisionStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}
