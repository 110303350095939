import { SaturnUITheme, skiptonLightTheme } from "@saturn-ui/foundations";

export const skiptonCFFLightTheme: SaturnUITheme = {
  ...skiptonLightTheme,
  shape: {
    borderRadius: 30,
  },
};

export const backButtonSx = {
  padding: 0,
  fontSize: 16,
  color: "black",
  outline: "initial",
  ":hover": {
    backgroundColor: "transparent",
    color: "black",
  },
  ":active": {
    backgroundColor: "transparent",
    color: "black",
  },
  ":focus": {
    backgroundColor: "transparent",
    color: "black",
  },
};
