import {
  ATRFormErrors,
  ATRFormScope,
  ATRFormState,
  ATRFormTouched,
  AtrQuestion,
} from "../../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepTypes";
import {
  FieldUpdateEventArgs,
  ListItem,
  RadioGroupInput,
  useFormContext,
} from "@saturn-ui/components";
import React, { forwardRef, useCallback, useMemo } from "react";
import { ClientScopeName } from "../../../../../feature/financialAdvicePage/FinancialAdvicePageTypes";
import { get } from "lodash";
import "./ATR.scss";

type Props = {
  fieldName: string;
  scope: ATRFormScope;
} & AtrQuestion;

export const ATRQuestion = forwardRef<HTMLInputElement, Props>(
  ({ description, answers, fieldName, scope }, ref) => {
    const formContext = useFormContext<
      ATRFormState,
      ATRFormErrors,
      ATRFormTouched
    >();
    const { values, setFieldValue, touched, validateForm, errors } =
      formContext;
    const scopeName = scope.name as ClientScopeName;
    const options = useMemo(
      () =>
        answers.map(({ id, text }) => ({
          id: String(id),
          displayText: text,
        })),
      [answers]
    );

    const onUpdate = useCallback(
      (args: FieldUpdateEventArgs<ListItem>) => {
        setFieldValue(
          `${scope.name as ClientScopeName}.${fieldName}`,
          args.value
        );
        validateForm();
      },
      [fieldName, scope.name, setFieldValue, validateForm]
    );

    const { selectedValue, hesError } = useMemo(
      () => ({
        hesError:
          get(touched, [scopeName, fieldName], false) &&
          get(errors, [scopeName, fieldName], false),
        selectedValue: values[scopeName].hasOwnProperty(fieldName)
          ? values[scopeName][fieldName]
          : null,
      }),
      [errors, fieldName, scopeName, touched, values]
    );

    return (
      <fieldset className="atr-fieldset">
        <legend className="atr-description" aria-hidden={true}>
          {description}
        </legend>
        {Boolean(hesError) && (
          <div className="error-message">Please answer this question</div>
        )}
        <div ref={ref}>
          <RadioGroupInput
            id="vertical"
            isVertical
            className="atr-questions"
            name={fieldName}
            onUpdate={onUpdate}
            options={options}
            value={selectedValue}
            required
          />
        </div>
      </fieldset>
    );
  }
);
