import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  OurAdviceStepFormState,
  OurAdviceStepMeta,
} from "./OurAdviceStepTypes";
import { defaultOurAdviceStepFormState } from "./OurAdviceStepInitialState";

const defaultState: OurAdviceStepMeta = {
  initialValues: defaultOurAdviceStepFormState,
};

const ourAdviceStepSlice = createSlice({
  name: "ourAdviceStep",
  initialState: defaultState,
  reducers: {
    setValues: (state, action: PayloadAction<OurAdviceStepFormState>) => {
      state.initialValues = action.payload;
    },
    resetToInitialValues: (state) => {
      state.initialValues = defaultOurAdviceStepFormState;
    },
  },
});

export const { setValues, resetToInitialValues } = ourAdviceStepSlice.actions;
export default ourAdviceStepSlice.reducer;
