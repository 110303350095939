import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListApi } from "skipton-common";
import { AppState } from "../../../../../types/AppState";
import { mapListToState } from "../../../../list/mappers";
import {
  ABOUT_YOU_STEP_TAX_STATUS_LIST,
  ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST,
  ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST,
} from "../AboutYouStepConstants";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";
import { AboutYouStepTaxStatusLists } from "../AboutYouStepTypes";

export const getTaxStatusList = createAsyncThunk<
  AboutYouStepTaxStatusLists,
  {
    baseUrl: string;
    taxJurisdiction: string;
  },
  { state: AppState }
>(
  "get-tax-status-list",
  async (
    data: {
      baseUrl: string;
      taxJurisdiction: string;
    },
    { getState }
  ): Promise<AboutYouStepTaxStatusLists> => {
    const { baseUrl, taxJurisdiction } = data;
    const {
      aboutYou: { lists },
    } = getState();

    const listApi = withSessionId(getListApi(baseUrl));

    let taxStatusRestUkList = lists[ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST];
    let taxStatusScotlandList = lists[ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST];

    if (
      taxStatusRestUkList === null &&
      taxJurisdiction === ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST
    ) {
      const taxStatusResponse = await listApi.getList(
        `${ABOUT_YOU_STEP_TAX_STATUS_LIST}/${ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST}`
      );

      taxStatusRestUkList = mapListToState(taxStatusResponse.body);
    }

    if (
      taxStatusScotlandList === null &&
      taxJurisdiction === ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST
    ) {
      const taxStatusResponse = await listApi.getList(
        `${ABOUT_YOU_STEP_TAX_STATUS_LIST}/${ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST}`
      );

      taxStatusScotlandList = mapListToState(taxStatusResponse.body);
    }

    return {
      [ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST]: taxStatusRestUkList,
      [ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST]: taxStatusScotlandList,
    };
  }
);

export const getTaxStatusListSuccess = getTaxStatusList.fulfilled;
export const getTaxStatusListPending = getTaxStatusList.pending;
export const getTaxStatusListFailed = getTaxStatusList.rejected;
