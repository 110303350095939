import React, { FC, PropsWithChildren } from "react";
import "./Card.scss";

type CardProps = {
  className?: string;
};

type CardHeaderProps = {
  className?: string;
};

type CardContentProps = {
  className?: string;
};

type CardSubComponents = {
  Header: typeof Header;
  Content: typeof Content;
};

export const Card: FC<PropsWithChildren<CardProps>> & CardSubComponents = ({
  className,
  children,
}) => {
  return <div className={`default-card ${className || ""}`}>{children}</div>;
};

const Header: FC<CardHeaderProps> = ({ className, children }) => {
  return (
    <div className={`default-card-header ${className || ""}`}>{children}</div>
  );
};

const Content: FC<CardContentProps> = ({ className, children }) => {
  return (
    <div className={`default-card-content ${className || ""}`}>{children}</div>
  );
};

Card.Header = Header;
Card.Content = Content;

export default Card;
