import React, { useEffect } from "react";
import { FC } from "react";
import { Layout } from "../layout/Layout";
import "./ThankYouPage.scss";
import Card from "../card/Card";
import { DoneIcon } from "../../assets";
import { RootState, useRootSelector } from "skipton-features";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../helpers/adobeAnalyticsHelpers";
import { ClientCategory } from "../../feature/financialAdvicePage/FinancialAdvicePageTypes";
import { useLocation } from "react-router-dom";

export type LocationState = {
  category?: ClientCategory;
};

export const ThankYouPage: FC = () => {
  const { isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const location = useLocation<LocationState>() as { state: LocationState };

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = location.state?.category
        ? getVirtualPageCustomerType(location.state?.category)
        : undefined;

      trackVirtualPageView({
        pageName: "Thank you",
        ...(customerType
          ? {
              customerType,
            }
          : {}),
      });
    }
  }, [isAdobeAnalyticsLoaded]);

  return (
    <Layout>
      <div className="thank-you-page">
        <div className="thank-you-page-container">
          <div className="thank-you-page-content">
            <h1 className="page-title thank-you-page-title">
              Thank you for your time!
            </h1>
            <p>We have everything we need for now.</p>
            <p>
              We really appreciate you taking the time to complete this fact
              find. Thanks to you, your adviser can now do the following.
            </p>
            <div className="thank-you-page-cards">
              <Card className="thank-you-page-card">
                <Card.Content className="card-content">
                  <div className="card-icon">
                    <DoneIcon />
                  </div>
                  <div className="card-content-wrapper">
                    <p className="card-description">
                      Begin to review your thoughts on risk and reward. They
                      will share your results when you meet, and how it will
                      shape the advice they offer.
                    </p>
                  </div>
                </Card.Content>
              </Card>
              <Card className="thank-you-page-card">
                <Card.Content className="card-content">
                  <div className="card-icon">
                    <DoneIcon />
                  </div>
                  <div className="card-content-wrapper">
                    <p className="card-description">
                      Take a look at your current financial arrangements. This
                      includes their past performance and potential future
                      prospects. They will factor these holdings into the
                      overall recommendations they make to you during your
                      meeting.
                    </p>
                  </div>
                </Card.Content>
              </Card>
              <Card className="thank-you-page-card">
                <Card.Content className="card-content">
                  <div className="card-icon">
                    <DoneIcon />
                  </div>
                  <div className="card-content-wrapper">
                    <p className="card-description">
                      Understand your wider circumstances, such as your current
                      income, as part of their research.
                    </p>
                  </div>
                </Card.Content>
              </Card>
            </div>
            <p>
              Most importantly of all, you do not need to do anything else to
              prepare for your financial advice meeting. It allows your adviser
              to have an even better conversation with you about your financial
              future.
            </p>
            <p>
              During your meeting, your adviser can answer your questions, talk
              about our approach to investing, and provide personalised
              recommendations for you to consider.
            </p>
            <p>
              <strong>You can now close your browser</strong>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
