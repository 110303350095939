import { useSaturnUITheme } from "@saturn-ui/foundations";
import { Box, HeaderLogo } from "@saturn-ui/components";
import { Stack } from "@mui/material";
import React from "react";
import "./Header.scss";

export type HeaderProps = {
  logo?: React.ReactNode;
  actions?: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = ({ logo, actions }) => {
  const { palette, shadows } = useSaturnUITheme();

  return (
    <Box
      className="main-header"
      data-testid="header"
      component="header"
      sx={{
        background: palette.components.header.background,
        color: palette.components.header.text,
        borderTop: `4px solid ${palette.components.header.accent}`,
        boxShadow: shadows[2],
      }}
    >
      <Stack
        className="page-container"
        sx={{ pt: 2, pb: 2 }}
        direction="row"
        justifyContent="space-between"
      >
        {logo || <HeaderLogo />}
        {actions || null}
      </Stack>
    </Box>
  );
};
