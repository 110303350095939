import React, { FC, useEffect } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../redux/selectors/authentication";
import { getDefaultRoute } from "../../helpers";

type Props = RouteProps;

export const ProtectedRoute: FC<Props> = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }: { location: unknown }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{ pathname: getDefaultRoute(), state: { from: location } }}
          />
        )
      }
    />
  );
};
