import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCaseApi } from "skipton-common";
import { AppState } from "../../../../../types/AppState";
// import { mapDataToPartyPersonalApi } from "../mappers";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
} from "../../../../financialAdvicePage/FinancialAdvicePageConstants";
import { mapDataToPartyATRApi } from "../mappers/PostPartyATRMapper";

export const postPartyATR = createAsyncThunk<
  void,
  {
    baseUrl: string;
  },
  { state: AppState }
>(
  "post-party-personal",
  async (
    data: {
      baseUrl: string;
    },
    { getState }
  ): Promise<void> => {
    const { baseUrl } = data;
    const {
      yourFeelings: { answers, questionnaireId },
      financialAdvicePage: {
        clientInfo: { category },
      },
    } = getState();

    const caseApi = withSessionId(getCaseApi(baseUrl));
    const client1PartyATRBody = mapDataToPartyATRApi(
      questionnaireId || "",
      answers[CLIENT_1_SCOPE_NAME]
    );
    await caseApi.postPartyATR(1, client1PartyATRBody);

    if (category === CLIENT_CATEGORY_JOINT) {
      const client2PartyATRBody = mapDataToPartyATRApi(
        questionnaireId || "",
        answers[CLIENT_2_SCOPE_NAME]
      );
      await caseApi.postPartyATR(2, client2PartyATRBody);
    }
  }
);

export const postPartyATRSuccess = postPartyATR.fulfilled;
export const postPartyATRPending = postPartyATR.pending;
export const postPartyATRFailed = postPartyATR.rejected;
