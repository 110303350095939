import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListApi } from "skipton-common";
import { AppState } from "../../../../../types/AppState";
import { YOUR_FEELINGS_STEP_ART } from "../YourFeelingsStepConstants";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";
import {
  InitialiseYourFeelingsResponse,
  YourFeelingsStepMeta,
} from "../YourFeelingsStepTypes";

export const initialiseYourFeelingsStep = createAsyncThunk<
  Promise<Pick<YourFeelingsStepMeta, "atrQuestions" | "questionnaireId">>,
  {
    baseUrl: string;
  },
  { state: AppState }
>(
  "initialise-your-feelings-step",
  async (
    data: {
      baseUrl: string;
    },
    { getState }
  ): Promise<
    Pick<YourFeelingsStepMeta, "atrQuestions" | "questionnaireId">
  > => {
    const { baseUrl } = data;
    const {
      yourFeelings: { atrQuestions, questionnaireId },
    } = getState();

    if (atrQuestions === null || questionnaireId === null) {
      const listApi = withSessionId(getListApi(baseUrl));

      const {
        body: { questionnaireId, questions },
      } = await listApi.getList<InitialiseYourFeelingsResponse>(
        YOUR_FEELINGS_STEP_ART
      );

      return {
        atrQuestions: questions,
        questionnaireId,
      };
    }

    return {
      atrQuestions,
      questionnaireId,
    };
  }
);

export const initialiseYourFeelingsStepSuccess =
  initialiseYourFeelingsStep.fulfilled;
export const initialiseYourFeelingsStepPending =
  initialiseYourFeelingsStep.pending;
export const initialiseYourFeelingsStepFailed =
  initialiseYourFeelingsStep.rejected;
