import {
  FieldValidator,
  Localization,
  ValidationConfiguration,
} from "@saturn-ui/components";
import {
  YourFeelingsStepFormDefinition,
  YourFeelingsStepFormErrors,
  YourFeelingsStepFormState,
} from "./YourFeelingsStepTypes";
import {
  YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  YOUR_FEELINGS_FORM_IS_QUESTION_FIELD,
  YOUR_FEELINGS_FORM_QUESTION_FIELD,
} from "./YourFeelingsStepConstants";

export const validate = (
  yourFeelingsValues: YourFeelingsStepFormState,
  definition: YourFeelingsStepFormDefinition,
  config: ValidationConfiguration,
  localization: Localization
): { errors: YourFeelingsStepFormErrors; isValid: boolean } => {
  const errors: YourFeelingsStepFormErrors = {};

  const fv = new FieldValidator<YourFeelingsStepFormErrors>(
    errors,
    config,
    localization
  );

  fv.checkBoolean(
    definition.fields.content[YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD],
    yourFeelingsValues[YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD] || null,
    YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD
  );

  fv.checkBoolean(
    definition.fields.content[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD],
    yourFeelingsValues[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD],
    YOUR_FEELINGS_FORM_IS_QUESTION_FIELD
  );

  if (yourFeelingsValues[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]) {
    fv.checkText(
      definition.fields.content[YOUR_FEELINGS_FORM_QUESTION_FIELD],
      yourFeelingsValues[YOUR_FEELINGS_FORM_QUESTION_FIELD],
      YOUR_FEELINGS_FORM_QUESTION_FIELD
    );
  }

  return {
    errors,
    isValid: fv.isValid(),
  };
};
