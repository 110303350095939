import { CaseApi } from "skipton-common";
import { AboutYouFormState } from "../AboutYouStepTypes";
import {
  ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD,
  ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD,
  ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD,
  ABOUT_YOU_FORM_MARITAL_STATUS_FIELD,
  ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD,
  ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD,
  ABOUT_YOU_FORM_TAX_STATUS_FIELD,
} from "../AboutYouStepConstants";

export const mapDataToPartyPersonalApi = (
  aboutYouValues: AboutYouFormState
): CaseApi.PartyPersonal => {
  return {
    maritalStatus:
      aboutYouValues[ABOUT_YOU_FORM_MARITAL_STATUS_FIELD]?.id ?? null,
    employmentStatus:
      aboutYouValues[ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD]?.id ?? null,
    annualIncome: aboutYouValues[ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD],
    taxJurisdiction:
      aboutYouValues[ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD]?.id ?? null,
    taxStatus: aboutYouValues[ABOUT_YOU_FORM_TAX_STATUS_FIELD]?.id ?? null,
    usedISAallowance:
      aboutYouValues[ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD]?.id === "Yes",
    niNumber: aboutYouValues[ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD],
  };
};
