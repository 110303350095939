import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const CloseIcon: React.FC<Props> = ({
  className,
  height = 24,
  width = 24,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <g id="Action / close" clipPath="url(#clip0_3566_2900)">
      <path
        id="Vector"
        d="M17.6569 7.75736L13.4143 12L17.6569 16.2426L16.2427 17.6569L12.0001 13.4142L7.75744 17.6569L6.34323 16.2426L10.5859 12L6.34323 7.75736L7.75744 6.34315L12.0001 10.5858L16.2427 6.34315L17.6569 7.75736Z"
        fill="#0F1112"
      />
    </g>
    <defs>
      <clipPath id="clip0_3566_2900">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
