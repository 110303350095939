import { CaseApi } from "skipton-common";
import { OurAdviceStepFormState } from "../../journeySteps/steps/ourAdviceStep/OurAdviceStepTypes";
import {
  OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  OUR_ADVICE_FORM_IS_QUESTION_FIELD,
  OUR_ADVICE_FORM_QUESTION_FIELD,
} from "../../journeySteps/steps/ourAdviceStep/OurAdviceStepConstants";
import { YourFeelingsStepFormState } from "../../journeySteps/steps/yourFeelingsStep/YourFeelingsStepTypes";
import {
  YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  YOUR_FEELINGS_FORM_IS_QUESTION_FIELD,
  YOUR_FEELINGS_FORM_QUESTION_FIELD,
} from "../../journeySteps/steps/yourFeelingsStep/YourFeelingsStepConstants";
import { NO, YES } from "../CustomDataConstants";
import { booleanToText } from "../../../utils/boolean";
import { isNull } from "lodash";

export const mapDataToCustomDataCFFApi = (
  ourAdviceStepValues: OurAdviceStepFormState,
  yourFeelingsValues: YourFeelingsStepFormState,
  remoteCustomData: Partial<CaseApi.CFFCustomData["CFF"]> | null
): CaseApi.CFFCustomData => {
  const {
    OurAdvice_watched_video = NO,
    OurAdvice_Question = NO,
    OurAdvice_QuestionText = "",
    ATR_watched_video = NO,
    ATR_Question = NO,
    ATR_QuestionText = "",
  } = remoteCustomData || {};
  return {
    CFF: {
      Version: "1.0",
      OurAdvice_watched_video: booleanToText(
        ourAdviceStepValues[OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD],
        OurAdvice_watched_video
      ),
      OurAdvice_Question: booleanToText(
        ourAdviceStepValues[OUR_ADVICE_FORM_IS_QUESTION_FIELD],
        OurAdvice_Question
      ),
      ...(ourAdviceStepValues[OUR_ADVICE_FORM_IS_QUESTION_FIELD]
        ? {
            OurAdvice_QuestionText:
              ourAdviceStepValues[OUR_ADVICE_FORM_QUESTION_FIELD] || "",
          }
        : {}),
      ...(isNull(ourAdviceStepValues[OUR_ADVICE_FORM_IS_QUESTION_FIELD]) &&
      OurAdvice_Question === YES
        ? { OurAdvice_QuestionText }
        : {}),
      ATR_watched_video: booleanToText(
        yourFeelingsValues[YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD],
        ATR_watched_video
      ),
      ATR_Question: booleanToText(
        yourFeelingsValues[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD],
        ATR_Question
      ),
      ...(yourFeelingsValues[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]
        ? {
            ATR_QuestionText:
              yourFeelingsValues[YOUR_FEELINGS_FORM_QUESTION_FIELD] || "",
          }
        : {}),
      ...(isNull(yourFeelingsValues[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]) &&
      ATR_Question === YES
        ? { ATR_QuestionText }
        : {}),
    },
  };
};
