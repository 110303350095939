import { CaseApi, SalutationApi } from "skipton-common";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
  CLIENT_CATEGORY_SINGLE,
} from "./FinancialAdvicePageConstants";

export type ClientScopeName =
  | typeof CLIENT_1_SCOPE_NAME
  | typeof CLIENT_2_SCOPE_NAME;

export type JointClientCategory = typeof CLIENT_CATEGORY_JOINT;
export type SingleClientCategory = typeof CLIENT_CATEGORY_SINGLE;

export type ClientCategory = JointClientCategory | SingleClientCategory;

export type ClientInfo = {
  salutation: {
    [CLIENT_1_SCOPE_NAME]: SalutationApi.Salutation | null;
    [CLIENT_2_SCOPE_NAME]: SalutationApi.Salutation | null;
  };
  category: ClientCategory;
};

export type FinancialAdvicePageMeta = {
  adviser: CaseApi.AdviserResponse | null;
  video: string | undefined;
  clientInfo: ClientInfo;
};

export enum FinancialAdvicePageStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}
