import React, { FC, isValidElement } from "react";

type OverlayConsumerProps = {
  overlayContent?: React.ReactNode;
};

export const OverlayConsumer: FC<OverlayConsumerProps> = ({
  overlayContent,
}) => {
  return isValidElement(overlayContent) ? overlayContent : null;
};
