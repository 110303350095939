import { ClientInfo } from "./FinancialAdvicePageTypes";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_SINGLE,
} from "./FinancialAdvicePageConstants";

export const defaultClientInfo: ClientInfo = {
  salutation: {
    [CLIENT_1_SCOPE_NAME]: null,
    [CLIENT_2_SCOPE_NAME]: null,
  },
  category: CLIENT_CATEGORY_SINGLE,
};
