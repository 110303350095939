import React from "react";
import clsx from "clsx";
import "./ProgressStepper.scss";
import { DoneIcon } from "../../assets";

export interface ProgressStepperProps {
  numberOfSteps: number;
  active: number;
  maxStepDone: number;
}

export function ProgressStepper(props: ProgressStepperProps): JSX.Element {
  const { numberOfSteps, active, maxStepDone } = props;

  const showSteps = () => {
    const steps: JSX.Element[] = [];
    const screenReaderText = `Step ${active} of ${numberOfSteps}`;

    for (let index = 1; index <= numberOfSteps; index++) {
      const showCheckSign = index <= maxStepDone && index !== active;
      steps.push(
        <li
          key={index}
          className={clsx("progress-stepper-item", {
            done: index <= maxStepDone,
            active: index === active,
            checkSign: showCheckSign,
          })}
          aria-label={screenReaderText}
          aria-hidden={index !== active}
        >
          <div className="progress-stepper-digit">
            {showCheckSign ? <DoneIcon /> : index}
          </div>
        </li>
      );
    }
    return steps;
  };

  const lastActiveStep = [active, maxStepDone].sort().pop() || 1;
  const processPercentage = ((lastActiveStep - 1) / (numberOfSteps - 1)) * 100;

  return (
    <div className="progress-stepper back-button-wrapper">
      <div className="only-visual progress-stepper-background">
        <div
          className="progress-stepper-percentage"
          style={{ width: `${processPercentage}%` }}
        />
      </div>
      <ul>{showSteps()}</ul>
    </div>
  );
}
