import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const TextSnippetIcon: React.FC<Props> = ({
  className,
  height = 24,
  width = 24,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <path d="M20.41 8.41006L15.58 3.58006C15.21 3.21006 14.7 3.00006 14.17 3.00006H5C3.9 3.00006 3 3.90006 3 5.00006V19.0001C3 20.1001 3.9 21.0001 5 21.0001H19C20.1 21.0001 21 20.1001 21 19.0001V9.83006C21 9.30006 20.79 8.79006 20.41 8.41006ZM7 7.00006H14V9.00006H7V7.00006ZM17 17.0001H7V15.0001H17V17.0001ZM17 13.0001H7V11.0001H17V13.0001Z" />
  </SvgIcon>
);
