import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const FileDownloadDoneIcon: React.FC<Props> = ({
  className,
  height = 24,
  width = 24,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <path
      d="M5 18.0001H19V20.0001H5V18.0001ZM9.6 15.3001L5 10.7001L7 8.80006L9.6 11.4001L17 4.00006L19 6.00006L9.6 15.3001Z"
      fill="#549B5C"
    />
  </SvgIcon>
);
