import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const ArrowForwardIcon: React.FC<Props> = ({
  className,
  height = 16,
  width = 10,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <path
      d="M1.55786 1L8.46094 7.90308L1.90302 14.461"
      stroke="transparent"
      strokeWidth="2"
    />
  </SvgIcon>
);
