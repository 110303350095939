import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import {
  Button,
  createFormScope,
  FormContextProvider,
  FormsConfiguration,
  ProgressBar,
  SubmitButton,
  useFormContainer,
  useFormsConfiguration,
  useLocalization,
} from "@saturn-ui/components";
import React from "react";
import "./ATR.scss";
import {
  Answers,
  ATRFormErrors,
  ATRFormState,
  ATRFormTouched,
  ATRQuestionsList,
  YourFeelingsStepDefinition,
} from "../../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepTypes";
import { validate } from "../../../../../feature/journeySteps/steps/yourFeelingsStep/ATRValidation";
import { get, isNull } from "lodash";
import { ATRQuestion } from "./ATRQuestion";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
} from "../../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import { ClientScopeName } from "../../../../../feature/financialAdvicePage/FinancialAdvicePageTypes";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import {
  setAnswer,
  setCurrentQuestion,
} from "../../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepSlice";
import { useAppDispatch } from "../../../../../redux/store";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../../../../helpers/adobeAnalyticsHelpers";
import { RootState, useRootSelector } from "skipton-features";

type Props = {
  scope: ClientScopeName;
  loading: boolean;
  errorMessage: string | null;
  questions: ATRQuestionsList;
  definition: YourFeelingsStepDefinition;
  onClickFinish: () => void;
};

const ATRFieldName = (id: number) => `atr_question_${id}`;

const getInitialState = (questions: ATRQuestionsList, answers: Answers) =>
  questions.reduce(
    (previousValue, currentValue, currentIndex) => ({
      ...previousValue,
      [ATRFieldName(currentIndex + 1)]: answers[currentValue.id] || null,
    }),
    {}
  );

export const ATR: FC<Props> = ({
  scope,
  loading,
  errorMessage,
  questions,
  definition,
  onClickFinish,
}) => {
  const {
    yourFeelings: {
      answers,
      currentQuestion: { [scope]: currentQuestionIndex },
    },
    financialAdvicePage: {
      clientInfo: { category, salutation },
    },
  } = useSelector((state: AppState) => state);
  const { isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = getVirtualPageCustomerType(category);

      trackVirtualPageView({
        pageName: `Risk - Q.${currentQuestionIndex + 1}/${questions.length}`,
        customerType,
        ...(category === CLIENT_CATEGORY_JOINT
          ? {
              customerNumber: scope === CLIENT_1_SCOPE_NAME ? 1 : 2,
            }
          : {}),
      });
    }
  }, [isAdobeAnalyticsLoaded, currentQuestionIndex, questions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isFirstQuestion, isLastQuestion, progressLabel, currentQuestKey } =
    useMemo(
      () => ({
        currentQuestKey: ATRFieldName(currentQuestionIndex + 1),
        progressLabel: `Question ${currentQuestionIndex + 1} of ${
          questions.length
        }`,
        isFirstQuestion: currentQuestionIndex === 0,
        isLastQuestion: currentQuestionIndex + 1 === questions.length,
      }),
      [currentQuestionIndex, questions.length]
    );

  const { validation } = useFormsConfiguration<FormsConfiguration>();

  const localization = useLocalization();

  const formContainer = useFormContainer<
    ATRFormState,
    ATRFormErrors,
    ATRFormTouched
  >({
    initialValues: {
      [CLIENT_1_SCOPE_NAME]: getInitialState(
        questions,
        answers[CLIENT_1_SCOPE_NAME]
      ),
      [CLIENT_2_SCOPE_NAME]: getInitialState(
        questions,
        answers[CLIENT_2_SCOPE_NAME]
      ),
    },
    validateFunc: (values) =>
      validate(
        scope,
        values,
        definition.ATRForm.content,
        validation,
        localization,
        category
      ),
    enableReinitialize: true,
    validateOnInitialize: true,
  });

  const { validateForm, setFieldTouched, errors, values, touched } =
    formContainer;

  const currentQuestion = questions[currentQuestionIndex];

  const activeScope = useMemo(
    () =>
      createFormScope({
        name: scope,
        values: values[scope],
        errors: errors[scope],
        touched: touched[scope],
        readOnly: false,
        disabled: false,
      }),
    [errors, scope, touched, values]
  );

  const handleClickNext = useCallback(() => {
    validateForm();
    setFieldTouched(`${activeScope.name}.${currentQuestKey}`);
    if (isNull(get(errors, [activeScope.name, currentQuestKey], null))) {
      inputRef.current?.getElementsByTagName("input")[0].focus();
      dispatch(setCurrentQuestion(currentQuestionIndex + 1));
      dispatch(
        setAnswer({
          [currentQuestion.id]: get(values, [scope, currentQuestKey], null),
        })
      );
    } else {
      inputRef.current?.getElementsByTagName("input")[0].focus();
    }
  }, [
    activeScope.name,
    currentQuestKey,
    currentQuestionIndex,
    dispatch,
    errors,
    questions,
    scope,
    setFieldTouched,
    validateForm,
    values,
  ]);

  const handleClickPrev = useCallback(() => {
    inputRef.current?.getElementsByTagName("input")[0].focus();
    dispatch(setCurrentQuestion(currentQuestionIndex - 1));
  }, [currentQuestionIndex, dispatch]);

  const handleClickFinish = useCallback(() => {
    validateForm();
    setFieldTouched(`${activeScope.name}.${currentQuestKey}`);
    if (isNull(get(errors, [activeScope.name, currentQuestKey], null))) {
      dispatch(
        setAnswer({
          [currentQuestion.id]: get(values, [scope, currentQuestKey], null),
        })
      );
      onClickFinish();
    }
  }, [
    activeScope.name,
    currentQuestKey,
    currentQuestionIndex,
    dispatch,
    errors,
    onClickFinish,
    questions,
    scope,
    setFieldTouched,
    validateForm,
    values,
  ]);

  return (
    <FormContextProvider value={formContainer}>
      <form onSubmit={(e) => e.preventDefault()} autoComplete="off" noValidate>
        {category === CLIENT_CATEGORY_JOINT ? (
          <h2 className="atr-title">
            {salutation[scope]?.forename}, your attitude to risk
          </h2>
        ) : (
          <h2 className="atr-title">Your attitude to risk</h2>
        )}
        <fieldset>
          <legend className="sr-only">
            {progressLabel}: {currentQuestion.description}
          </legend>
          <div aria-hidden={true}>
            <ProgressBar
              label={progressLabel}
              numberOfSteps={12}
              activeStep={currentQuestionIndex + 1}
            />
          </div>
          <ATRQuestion
            {...currentQuestion}
            fieldName={currentQuestKey}
            scope={activeScope}
            ref={inputRef}
          />
        </fieldset>
        <div className="atr-bottom-bar">
          {errorMessage && (
            <span className="atr-error-message error-message">
              {errorMessage}
            </span>
          )}
          <div className="atr-actions-wrapper">
            {!isFirstQuestion && (
              <Button
                className="decorated-button prev-button"
                onClick={handleClickPrev}
                variant="outlined"
                id="cancel-button"
                disableRipple={true}
              >
                <span className="decoration">Previous Question</span>
              </Button>
            )}
            {!isLastQuestion ? (
              <Button
                className="decorated-button next-button"
                onClick={handleClickNext}
                variant="contained"
                id="logout-button"
                disableRipple={true}
              >
                <span className="decoration">Next Question</span>
              </Button>
            ) : (
              <SubmitButton
                className="decorated-button next-button"
                onClick={handleClickFinish}
                variant="contained"
                id="logout-button"
                disableRipple={true}
                submitting={loading}
              >
                <span className="decoration">Finish</span>
              </SubmitButton>
            )}
          </div>
        </div>
      </form>
    </FormContextProvider>
  );
};
