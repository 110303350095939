import React from "react";
import { Button, Dialog, IconButton } from "@saturn-ui/components";
import "./ErrorDialog.scss";
import { CloseIcon } from "../../assets/icons/CloseIcon";

type Props = {
  title?: string;
  description?: string;
  onClose: () => void;
};

export const ErrorDialog: React.FC<Props> = ({
  title,
  description,
  onClose,
}) => {
  return (
    <Dialog isOpen={true} onClose={onClose} className="auth-dialog">
      <IconButton
        className="dialog-close-button"
        ariaLabel={"Close"}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      {title && <h2 className="dialog-title">{title}</h2>}
      {description && <p>{description}</p>}
      <div className="dialog-single-action">
        <Button
          className="primary-button"
          onClick={onClose}
          variant="contained"
          id="refresh-button"
          disableRipple={true}
        >
          Ok
        </Button>
      </div>
    </Dialog>
  );
};
