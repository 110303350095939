import {
  Localization,
  FieldValidator,
  ValidationConfiguration,
} from "@saturn-ui/components";

import {
  ConfirmationFormDefinition,
  ConfirmationFormError,
  ConfirmationFormState,
} from "../../feature/welcomePage/WelcomePageTypes";

export const validate = (
  formState: ConfirmationFormState,
  definition: ConfirmationFormDefinition,
  config: ValidationConfiguration,
  localization: Localization
): { errors: ConfirmationFormError; isValid: boolean } => {
  const errors: ConfirmationFormError = {};

  const fv = new FieldValidator<ConfirmationFormError>(
    errors,
    config,
    localization
  );

  fv.setError(
    "confirmRead",
    formState.confirmRead
      ? ""
      : "Oops! We need you to confirm you have read the welcome information before we continue. Please tick the box when you have read the information."
  );

  fv.setError(
    "confirmNoQuestions",
    formState.confirmNoQuestions
      ? ""
      : "Oops! We need you to confirm you do not have any questions at this point before we continue. Please tick the box if this is the case."
  );

  return {
    errors,
    isValid: fv.isValid(),
  };
};
