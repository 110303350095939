import { UpdateStateFunction } from "@saturn-ui/components";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  PropertyFormErrors,
  PropertyFormState,
  PropertyFormTouched,
} from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import {
  ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD,
} from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepConstants";

export const resetOwnershipSubFields = (
  values: WritableDraft<PropertyFormState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): WritableDraft<PropertyFormState> => {
  setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD, false);
  setFieldTouched(ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD, false);
  setFieldTouched(ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD, false);

  values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] = null;
  values[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD] = null;
  values[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD] = null;
  return values;
};

export const handleOwnershipFieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      PropertyFormState,
      PropertyFormErrors,
      PropertyFormTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) => resetOwnershipSubFields(values, setFieldTouched));
};

export const resetMortgageRemainingField = (
  values: WritableDraft<PropertyFormState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): WritableDraft<PropertyFormState> => {
  setFieldTouched(ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD, false);

  values[ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD] = null;
  return values;
};

export const handleIsPropertyMortgageFieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      PropertyFormState,
      PropertyFormErrors,
      PropertyFormTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) => resetMortgageRemainingField(values, setFieldTouched));
};

export const resetOtherOwnerDetailsField = (
  values: WritableDraft<PropertyFormState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): WritableDraft<PropertyFormState> => {
  setFieldTouched(ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD, false);

  values[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD] = null;
  return values;
};

export const handlePercentageClient1FieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      PropertyFormState,
      PropertyFormErrors,
      PropertyFormTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) => resetOtherOwnerDetailsField(values, setFieldTouched));
};

export const handlePercentageClient2FieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      PropertyFormState,
      PropertyFormErrors,
      PropertyFormTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void
): void => {
  updateState((values) => resetOtherOwnerDetailsField(values, setFieldTouched));
};
