import { AboutYouStepDefinition } from "src/feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import { DefinitionResource } from "@saturn-ui/components";

export const aboutYouStepDefinition: DefinitionResource<AboutYouStepDefinition> =
  {
    content: {
      form: {
        content: {
          fields: {
            content: {
              maritalStatus: {
                caption: "1. What is your marital status?",
                appearance: "select",
                placeholder: "Please select",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              employmentStatus: {
                caption: "2. What is your employment status?",
                appearance: "select",
                placeholder: "Please select",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              grossAnnualIncome: {
                caption: "3. What is your gross annual income?",
                help: "Gross means how much you are paid before tax",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              taxJurisdiction: {
                caption: "4. Where do you pay tax?",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              taxStatus: {
                caption: "5. What is your tax status?",
                appearance: "select",
                placeholder: "Please select",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              fullISAAllowance: {
                caption:
                  "6. Have you used your full ISA allowance for the latest tax year (which runs up to the end of 5 April)?",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              nationalInsuranceNumber: {
                caption: "7. What is your National Insurance number?",
                placeholder: "Type here",
                formatter: "upperCase",
                errors: {
                  required: true,
                  regex:
                    "^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)[ABCEGHJKLMNOPRSTWXYZ][ABCEGHJKLMNPRSTWXYZ][0-9]{6}[A-D]$",
                  messages: {
                    regex: "NI number is not in a valid format",
                    required: "Please answer this question",
                  },
                },
              },
            },
          },
        },
      },
      yourPropertyForm: {
        content: {
          fields: {
            content: {
              isOwnProperty: {
                caption: "Do you own a property?",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              propertyType: {
                caption: "What type of property do you own?",
                appearance: "select",
                placeholder: "Please select",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              ownership: {
                caption: "Who owns the property?",
                appearance: "select",
                placeholder: "Please select",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              ownershipPercentageClient1: {
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              ownershipPercentageClient2: {
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              otherOwnerDetails: {
                caption: "Other owner details",
                placeholder: "Type here",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              propertyValue: {
                caption: "Property estimated value",
                help: "Please share a rough estimate of the value of your property. If you are not sure, you can get some support from websites like Zoopla to understand local property sold prices.",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              isPropertyMortgage: {
                caption: "Does the property have a mortgage?",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              mortgageRemaining: {
                caption:
                  "How much approximately do you have left to repay on the mortgage?",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
            },
          },
        },
      },
    },
  };
