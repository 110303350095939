import { MainScheduler, Scheduler } from "./Scheduler";
import { bindAllClass } from "../utils/classes";
import isFunction from "lodash/isFunction";
import { MainEmitterEvents } from "./EventEmitter";
import isDate from "lodash/isDate";

const INTERACTION_CHECKING_SEC = 60;
const INACTIVITY_WAITING_SEC = 60;

export const browserEvents: EventsType[] = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange",
  "focus",
];

export enum SessionEvents {
  INTERACTION_CHECKING = "INTERACTION_CHECKING",
  INACTIVITY_WAITING = "INACTIVITY_WAITING",
  INACTIVITY_INTERRUPTED = "INACTIVITY_INTERRUPTED",
  SESSION_EXPIRED = "SESSION_EXPIRED",
}

export class SessionServiceImpl {
  private _browserEventsBound = false;
  private _sessionExpiryDate: Date | null = null;
  private _isActive = false;
  private _defaultHandlers: Map<SessionEvents, Handler[]> = new Map([
    [
      SessionEvents.INTERACTION_CHECKING,
      [this.onInteractionCheckingStart.bind(this)],
    ],
    [
      SessionEvents.INACTIVITY_WAITING,
      [this.onInactivityWaitingStart.bind(this)],
    ],
    [
      SessionEvents.INACTIVITY_INTERRUPTED,
      [this.onInactivityInterrupted.bind(this)],
    ],
    [SessionEvents.SESSION_EXPIRED, []],
  ]);
  private _eventsHandlers = new Map(this._defaultHandlers);
  private _eventTimeouts: Map<SessionEvents, Date | null> = new Map(
    Object.values(SessionEvents).map((event) => [event, null])
  );

  constructor(private _scheduler: Scheduler<MainEmitterEvents>) {
    bindAllClass(this);
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  private get secondsToInteractionChecking(): number {
    return (
      this.secondsToSessionExpiration -
      (INTERACTION_CHECKING_SEC + INACTIVITY_WAITING_SEC)
    );
  }

  private get secondsToInactivityWaiting(): number {
    return this.secondsToSessionExpiration - INACTIVITY_WAITING_SEC;
  }

  public get secondsToSessionExpiration(): number {
    if (!isDate(this._sessionExpiryDate)) {
      throw new Error("Expiry date is not valid");
    }
    if (this._sessionExpiryDate.getTime() < new Date().getTime()) {
      throw new Error("Session is expired");
    }
    return (this._sessionExpiryDate.getTime() - new Date().getTime()) / 1000;
  }

  public restart(expiry: Date): void {
    this.removeScheduledEvents();
    this._isActive = false;
    this.start(expiry);
  }

  public start(expiry: Date): void {
    if (!this.isActive) {
      this._isActive = true;
      this._sessionExpiryDate = expiry;
      this.eachEventHandlers((handlers, key) =>
        this._scheduler.emitter.on(key, handlers)
      );
      this.scheduleInteractionChecking();
      this.scheduleSessionExpiration();
    }
  }

  public stop(): void {
    if (this._isActive) {
      this.resetHandlers();
      this._isActive = false;
    }
  }

  public attachHandler(event: SessionEvents, handler: Handler): void {
    const boundedHandler = handler.bind(this);
    const handlers = this._eventsHandlers.get(event);

    if (
      handlers &&
      !handlers.includes(boundedHandler) &&
      isFunction(boundedHandler)
    ) {
      this._eventsHandlers.set(event, [...handlers, boundedHandler]);
    }
  }

  private onInteractionCheckingStart() {
    this.boundInactivityListeners();
    this.scheduleInactivityWaiting();
  }

  private onInactivityWaitingStart(): void {
    this.unboundInactivityListeners();
  }

  private onInactivityInterrupted(): void {
    this.unboundInactivityListeners();
  }

  private createTimeout(durationSec: number, event: SessionEvents) {
    const timeout = new Date();

    timeout.setSeconds(timeout.getSeconds() + durationSec);

    this._eventTimeouts.set(event, timeout);

    return timeout;
  }

  private eachEventHandlers(
    callback: (handlers: Handler[], key: SessionEvents) => void
  ): void {
    this._eventsHandlers.forEach(callback);
  }

  public updateExpiryDate(expiry: Date): void {
    this._sessionExpiryDate = expiry;
  }

  public resetHandlers(): void {
    this.removeScheduledEvents();
    this._eventsHandlers = new Map(this._defaultHandlers);
  }

  private removeScheduledEvents() {
    if (this.isActive) {
      this.eachEventHandlers((handlers, key) => {
        const timeout = this._eventTimeouts.get(key);

        timeout && this._scheduler.removeScheduledDate(key, timeout);

        this._scheduler.emitter.has(key) && this._scheduler.emitter.remove(key);
      });
    }
  }

  private interruptInactivity(): void {
    this._scheduler.emitter.emit(SessionEvents.INACTIVITY_INTERRUPTED);
  }

  private boundInactivityListeners(): void {
    if (!this._browserEventsBound) {
      browserEvents.forEach((e) => {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.addEventListener(e, this.interruptInactivity);
      });
      this._browserEventsBound = true;
    }
  }

  private unboundInactivityListeners(): void {
    if (this._browserEventsBound) {
      browserEvents.forEach((e) => {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.removeEventListener(e, this.interruptInactivity);
      });
      this._browserEventsBound = false;
    }
  }

  private scheduleInteractionChecking(): void {
    const interactionCheckingTimeout = this.createTimeout(
      this.secondsToInteractionChecking,
      SessionEvents.INTERACTION_CHECKING
    );
    this._scheduler.scheduleEvent(
      SessionEvents.INTERACTION_CHECKING,
      interactionCheckingTimeout
    );
  }

  private scheduleSessionExpiration(): void {
    const sessionExpiredTimeout = this.createTimeout(
      this.secondsToSessionExpiration,
      SessionEvents.SESSION_EXPIRED
    );
    this._scheduler.scheduleEvent(
      SessionEvents.SESSION_EXPIRED,
      sessionExpiredTimeout
    );
  }

  private scheduleInactivityWaiting(): void {
    const inactivityWaitingTimeout = this.createTimeout(
      this.secondsToInactivityWaiting,
      SessionEvents.INACTIVITY_WAITING
    );
    this._scheduler.scheduleEvent(
      SessionEvents.INACTIVITY_WAITING,
      inactivityWaitingTimeout
    );
  }
}

export type Handler = () => void | Promise<void>;

export type EventsType =
  | "mousemove"
  | "keydown"
  | "wheel"
  | "DOMMouseScroll"
  | "mousewheel"
  | "mousedown"
  | "touchstart"
  | "touchmove"
  | "MSPointerDown"
  | "MSPointerMove"
  | "visibilitychange"
  | "focus";

export const SessionService = new SessionServiceImpl(MainScheduler);
