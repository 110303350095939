import React from "react";
import { Button, Dialog, IconButton } from "@saturn-ui/components";
import { CloseIcon } from "../../../../../assets/icons/CloseIcon";
import "./JointCustomerDialog.scss";

type Props = {
  salutation: string;
  onCancelClick?: () => void;
  onQuitClick?: () => void;
};
export const JointCustomerDialog: React.FC<Props> = ({
  salutation,
  onCancelClick,
  onQuitClick,
}) => {
  return (
    <Dialog isOpen={true} onClose={onCancelClick} className="auth-dialog">
      <IconButton
        className="dialog-close-button"
        ariaLabel={"Close"}
        onClick={onCancelClick}
      >
        <CloseIcon />
      </IconButton>
      <h2 className="dialog-title">{`${salutation}, your turn`}</h2>
      <p>{`${salutation}, next we need you to complete your details.`}</p>
      <div className="dialog-single-action">
        <Button
          className="decorated-button"
          onClick={onQuitClick}
          variant="contained"
          id="proceed-button"
          disableRipple={true}
        >
          <span className="decoration">Continue</span>
        </Button>
      </div>
    </Dialog>
  );
};
