import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthenticationApi, getAuthenticationApi } from "skipton-common";
import { withSessionId } from "../../../utils/AuthorisationUtils";
import store from "../../../redux/store";

export const refresh = createAsyncThunk(
  "authentication/refresh",
  async (data: {
    baseUrl: string;
  }): Promise<AuthenticationApi.RefreshResponseModel> => {
    const { baseUrl } = data;

    const authenticationApi = withSessionId(getAuthenticationApi(baseUrl));

    const securityId = store.getState().authentication.securityId;

    const response = await authenticationApi.refresh({
      securityId: securityId || "",
    });

    return response.body;
  }
);

export const refreshSuccess = refresh.fulfilled;
export const refreshPending = refresh.pending;
export const refreshFailed = refresh.rejected;
