import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  AboutYouStepDefinition,
  AboutYouStepErrors,
  AboutYouStepState,
  AboutYouStepTouched,
  PropertyFormErrors,
  PropertyFormState,
  PropertyFormTouched,
  YourPropertyContentStates,
  YourPropertyFormScope,
} from "../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import { useAppDispatch } from "../../../../../redux/store";
import {
  Button,
  FieldRenderer,
  FormContextProvider,
  FormContextType,
  RadioGroupInput,
  SubmitButton,
  useFormContext,
} from "@saturn-ui/components";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import {
  setProperties,
  setYourPropertyValues,
} from "../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepSlice";
import {
  ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD,
  YOUR_PROPERTY_SCOPE_NAME,
} from "../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepConstants";
import { ownPropertyList } from "../aboutYouStepLists";
import {
  PersistableSections,
  useSaveAndResumeLogic,
} from "../../../../../hooks/useSaveAndResumeLogic";
import { getErrorRoute, getYourMoneyRoute } from "../../../../../helpers";
import {
  HTTP_UNAUTHORIZED_STATUS_CODE,
  HttpRequestError,
} from "skipton-common";
import { useHistory } from "react-router-dom";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_CATEGORY_SINGLE,
} from "../../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import "./YourPropertyContent.scss";
import { isEmpty, some } from "lodash";
import { AccordionGroup } from "../../../accordionGroup/AccordionGroup";
import { defaultProperty } from "../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepInitialState";
import clsx from "clsx";
import { focusOnInputById } from "../../../../../utils/formUtils";

type Props = {
  scope: YourPropertyFormScope;
  definition: AboutYouStepDefinition;
  handleBackClick: () => void;
  formPropertyContainer: FormContextType<
    PropertyFormState,
    PropertyFormErrors,
    PropertyFormTouched
  >;
};

export const YourPropertyContent: FC<Props> = ({
  scope,
  definition,
  formPropertyContainer,
  handleBackClick,
}) => {
  const history = useHistory() as unknown[];
  const dispatch = useAppDispatch();

  const { commitStepCompleted } = useSaveAndResumeLogic(
    PersistableSections.AboutYou
  );
  const [yourPropertyContentState, setYourPropertyContentState] = useState(
    YourPropertyContentStates.Uninitialised
  );
  const {
    aboutYou: { properties },
    financialAdvicePage: {
      clientInfo: {
        salutation: { [CLIENT_1_SCOPE_NAME]: client1Salutation },
        category,
      },
    },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formContext = useFormContext<
    AboutYouStepState,
    AboutYouStepErrors,
    AboutYouStepTouched
  >();

  const {
    touched,
    errors,
    handleFieldUpdate,
    values,
    validateForm,
    setFieldTouched,
    isValid,
  } = formContext;

  const formValues = useRef(values);

  useEffect(() => {
    formValues.current = values;
  }, [values]);

  const handleCommitStepCompleted = async (): Promise<void> => {
    try {
      setYourPropertyContentState(YourPropertyContentStates.Initialising);

      await commitStepCompleted();

      setYourPropertyContentState(YourPropertyContentStates.Success);
      history.push(getYourMoneyRoute());
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setYourPropertyContentState(YourPropertyContentStates.Error);
    }
  };

  const handlePressContinue = useCallback(async () => {
    validateForm();
    setFieldTouched(
      `${YOUR_PROPERTY_SCOPE_NAME}.${ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD}`
    );

    if (isEmpty(errors[YOUR_PROPERTY_SCOPE_NAME])) {
      dispatch(setYourPropertyValues(values[YOUR_PROPERTY_SCOPE_NAME]));
      await handleCommitStepCompleted();
    } else {
      if (
        errors[YOUR_PROPERTY_SCOPE_NAME][
          ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
        ]
      ) {
        focusOnInputById(
          `${YOUR_PROPERTY_SCOPE_NAME}-${ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD}`,
          setFieldTouched
        );
      }
    }
  }, [validateForm, setFieldTouched, isValid, dispatch, values]);

  const handlePressPreviousPage = useCallback(() => {
    dispatch(setYourPropertyValues(values[YOUR_PROPERTY_SCOPE_NAME]));
    handleBackClick();
  }, [dispatch, values, handleBackClick]);

  const isSubmitButtonShown =
    !some(properties, ["isSaved", false]) ||
    values[YOUR_PROPERTY_SCOPE_NAME][
      ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
    ] === null ||
    values[YOUR_PROPERTY_SCOPE_NAME][
      ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
    ]?.id === "No";

  const checkYourPropertyContentState = (
    ...states: YourPropertyContentStates[]
  ) => !!states.find((state) => state === yourPropertyContentState);

  const renderYourPropertyContent = () => {
    return (
      <div className="about-you-step-property-form">
        <div className="about-you-step-property-form-wrapper">
          <div className="about-you-custom-error-wrapper">
            <fieldset
              id={`${YOUR_PROPERTY_SCOPE_NAME}-${ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD}`}
            >
              <FieldRenderer
                name={ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD}
                definition={
                  definition.yourPropertyForm.content.fields.content[
                    ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
                  ]
                }
                scope={scope}
                render={(fieldProps) => (
                  <div className="radio-group-wrapper">
                    <span className="radio-group-label">
                      {
                        definition.yourPropertyForm.content.fields.content[
                          ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
                        ].caption
                      }
                    </span>
                    {errors &&
                      errors[YOUR_PROPERTY_SCOPE_NAME] &&
                      errors[YOUR_PROPERTY_SCOPE_NAME][
                        ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
                      ] &&
                      touched &&
                      touched[YOUR_PROPERTY_SCOPE_NAME] &&
                      touched[YOUR_PROPERTY_SCOPE_NAME][
                        ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
                      ] && (
                        <div className="error-message">
                          Please answer this question
                        </div>
                      )}
                    <RadioGroupInput
                      {...fieldProps}
                      isVertical
                      isLarge
                      disabled={some(properties, ["isSaved", true])}
                      value={
                        values[YOUR_PROPERTY_SCOPE_NAME][
                          ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
                        ]
                      }
                      options={ownPropertyList.items || []}
                      onUpdate={handleFieldUpdate}
                    />
                  </div>
                )}
              />
            </fieldset>
          </div>
          {values[YOUR_PROPERTY_SCOPE_NAME][
            ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
          ]?.id === "Yes" && (
            <div className="form-properties-wrapper">
              <FormContextProvider value={formPropertyContainer}>
                <form
                  onSubmitCapture={(e) => e.preventDefault()}
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete="off"
                  noValidate
                >
                  <AccordionGroup
                    captions={{
                      headerTitle: "Property",
                      addButtonTitle: "Add another property",
                      saveButtonTitle: "Save & Proceed",
                    }}
                    items={properties}
                    setItems={setProperties}
                    defaultItem={defaultProperty}
                  />
                </form>
              </FormContextProvider>
            </div>
          )}
        </div>
        <div className="form-actions">
          <Button
            className="about-you-button decorated-button"
            onClick={handlePressPreviousPage}
            variant="outlined"
            id="previous-button"
            disableRipple={true}
            color="primary"
          >
            <span className="decoration">Previous page</span>
          </Button>
          {isSubmitButtonShown && (
            <div
              className={clsx("about-you-step-continue-button-wrapper", {
                error: checkYourPropertyContentState(
                  YourPropertyContentStates.Error
                ),
              })}
            >
              {checkYourPropertyContentState(
                YourPropertyContentStates.Error
              ) && (
                <div className="error-message">
                  Something went wrong. Please try again.
                </div>
              )}
              <SubmitButton
                className="about-you-button submit-button decorated-button"
                onClick={handlePressContinue}
                variant="contained"
                id="about-you-continue-button"
                disableRipple={true}
                color="primary"
                submitting={checkYourPropertyContentState(
                  YourPropertyContentStates.Initialising
                )}
              >
                <span className="decoration">I am ready to continue</span>
              </SubmitButton>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="about-you-step-property">
      <h1 className="step-title">About you – your property</h1>
      {category === CLIENT_CATEGORY_SINGLE && (
        <p>
          {`${
            client1Salutation?.forename ?? ""
          }, this information will help us prepare for your meeting`}
        </p>
      )}
      <h2 className="step-sub-title">Property</h2>
      <p className="paragraph-no-top-margin">
        To help us build a clearer overall picture of your financial
        circumstances, we need you to share a few details about any property you
        own.
      </p>

      {renderYourPropertyContent()}
    </div>
  );
};
