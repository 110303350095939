import React, { useCallback, useState, FC, useMemo } from "react";

import { OverlayContext } from "./OverlayContext";
import { OverlayParams } from "./types";
import { OverlayConsumer } from "./OverlayConsumer";

export const OverlayProvider: FC = ({ children }) => {
  const [currentOverlay, setCurrentOverlay] = useState<React.ReactNode | null>(
    null
  );

  const showOverlay = useCallback(
    <T,>({ content, props }: OverlayParams<T>) => {
      const ContentComponent = content;
      setCurrentOverlay(
        <ContentComponent
          {...((props || {}) as T & React.JSX.IntrinsicAttributes)}
        />
      );
    },
    []
  );

  const hideOverlay = useCallback(() => {
    setCurrentOverlay(null);
  }, []);

  const providerValue = useMemo(
    () => ({ showOverlay, setCurrentOverlay, hideOverlay }),
    [showOverlay, hideOverlay]
  );

  return (
    <>
      <OverlayContext.Provider value={providerValue}>
        {children}
      </OverlayContext.Provider>
      {currentOverlay && <OverlayConsumer overlayContent={currentOverlay} />}
    </>
  );
};
