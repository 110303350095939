import { createContext } from "react";
import noop from "lodash/noop";
import { OverlayParams } from "./types";

type OverlayContextProps = {
  showOverlay: <T>(params: OverlayParams<T>) => void;
  hideOverlay: () => void;
};

export const OverlayContext = createContext<OverlayContextProps>({
  showOverlay: noop,
  hideOverlay: noop,
});
