import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const propertyMortgageList: List = {
  listType: "propertyMortgage",
  lang: "en-GB",
  items: [
    {
      id: "Yes",
      displayText: "Yes",
    },
    {
      id: "No",
      displayText: "No",
    },
  ],
};
