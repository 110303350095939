import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const productTypeInvestmentsList: List = {
  listType: "productTypeInvestmentsList",
  lang: "en-GB",
  items: [
    {
      id: "CHILDT",
      displayText: "Child Trust Funds",
    },
    {
      id: "ENDOW",
      displayText: "Endowment",
    },
    {
      id: "EH",
      displayText: "Equity Holdings",
    },
    {
      id: "GIA",
      displayText: "General Investment Account",
    },
    {
      id: "ITRUST",
      displayText: "Investment Trust",
    },
    {
      id: "ISA",
      displayText: "ISA",
    },
    {
      id: "JISA",
      displayText: "Junior ISA",
    },
    {
      id: "LISA",
      displayText: "Lifetime ISA",
    },
    {
      id: "NATS",
      displayText: "National Savings",
    },
    {
      id: "OFFIB",
      displayText: "Offshore Investment Bond",
    },
    {
      id: "ONIB",
      displayText: "Onshore Investment Bond",
    },
    {
      id: "OEIC",
      displayText: "Open Ended Investment Company",
    },
    {
      id: "SHARES",
      displayText: "Share Portfolio",
    },
    {
      id: "STRPROD",
      displayText: "Structured Products",
    },
    {
      id: "UT",
      displayText: "Unit Trust",
    },
  ],
};
