import { DefinitionResource } from "@saturn-ui/components";
import { YourMoneyStepDefinition } from "src/feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";

export const yourMoneyStepDefinition: DefinitionResource<YourMoneyStepDefinition> =
  {
    content: {
      provisionForm: {
        content: {
          fields: {
            content: {
              typeOfHolding: {
                caption: "1. Type of holding",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              whoBelongTo: {
                caption: "2. Who does it belong to?",
                appearance: "select",
                placeholder: "Please Select",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              whoIsItHeldWith: {
                caption: "Who is it held with?",
                appearance: "select",
                placeholder: "Please Select",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              whoIsItHeldWithOther: {
                caption: "Please tell us the name of the provider",
                placeholder: "Type here",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              productType: {
                caption: "What kind of product is it? (Optional)",
                appearance: "select",
                placeholder: "Please Select",
              },
              isPolicyReferenceNumberKnown: {
                caption: "Do you know the policy reference number?",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              policyReferenceNumber: {
                placeholder: "Type here",
                caption: "Please type your policy reference number below",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
              howMuchItWorth: {
                caption: "How much is it worth?",
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question",
                  },
                },
              },
            },
          },
        },
      },
    },
  };
