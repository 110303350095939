import { EventEmitter, MainEmitter, TEventNames } from "./EventEmitter";
import { bindAllClass } from "../utils/classes";

type EventPayload = {
  date: Date;
  timeoutId: number;
};

const DEFAULT_EVENT_TIME_DELIMITER = "_";

export class Scheduler<EventNames extends TEventNames> {
  public get emitter(): EventEmitter<EventNames> {
    return this._emitter;
  }

  private events: Map<keyof EventNames, EventPayload[]> = new Map();

  constructor(
    private _emitter: EventEmitter<EventNames>,
    private delimiter: string = DEFAULT_EVENT_TIME_DELIMITER
  ) {
    bindAllClass(this);
  }

  public scheduleEvent(
    event: keyof EventNames,
    date: Date,
    actions?: Array<() => void>
  ): Scheduler<EventNames> {
    this.addEvent(event, date);

    if (actions?.length) {
      this.subscribeToScheduledEventDate(event, date, actions);
    }

    return this;
  }

  public subscribeToScheduledEventDate(
    event: keyof EventNames,
    date: Date,
    actions: Array<() => void>
  ): Scheduler<EventNames> {
    const eventTimeId = this.buildEventTimeId(event, date);

    if (this.emitter.has(eventTimeId)) {
      this.emitter.on(eventTimeId, actions);
    } else {
      console.log(
        `No scheduled event exists, for name [${String(
          event
        )}] and date [${date.toString()}]`
      );
    }

    return this;
  }

  public removeScheduledDate(
    event: keyof EventNames,
    time: Date
  ): Scheduler<EventNames> {
    this.removeEventDate(event, time);

    return this;
  }

  public clearScheduledEventDates(
    event: keyof EventNames
  ): Scheduler<EventNames> {
    this.setEventPayloads(event, []);

    return this;
  }

  public removeScheduledEvent(event: keyof EventNames): Scheduler<EventNames> {
    this.events.delete(event);

    return this;
  }

  private addEvent(event: keyof EventNames, date: Date): void {
    const scheduledDates = this.events.get(event) || [];
    const timeoutId = this.placeEventTimeout(event, date);

    this.setEventPayloads(event, [...scheduledDates, { date, timeoutId }]);
  }

  private removeEventDate(event: keyof EventNames, date: Date): void {
    const scheduledDates = this.events.get(event);

    if (!scheduledDates?.length) {
      this.logNoScheduledEvent(event, date);
    } else {
      const clearedDates = scheduledDates.filter((payload) => {
        payload.date.getTime() !== date.getTime();
      });

      this.clearEventTimeout(event, date);
      this.setEventPayloads(event, clearedDates);
    }
  }

  private setEventPayloads(
    event: keyof EventNames,
    dates: EventPayload[]
  ): void {
    this.events.set(event, dates);
  }

  private placeEventTimeout(event: keyof EventNames, date: Date): number {
    const timeout = this.getTimeDurationFromNow(date);
    const eventTimeId = this.buildEventTimeId(event, date);

    return setTimeout(() => {
      this.emitter.emit(event);
      this.emitter.emit(eventTimeId);
    }, timeout) as unknown as number;
  }

  private clearEventTimeout(event: keyof EventNames, date: Date): void {
    const payload = this.events
      .get(event)
      ?.find((nextEvent) => nextEvent?.date === date);

    if (!payload) {
      this.logNoScheduledEvent(event, date);
    } else {
      clearTimeout(payload.timeoutId);
    }
  }

  public getTimeDurationFromNow(date: Date): number {
    const currentTimestamp = new Date().getTime();

    return date.getTime() - currentTimestamp;
  }

  private buildEventTimeId(event: keyof EventNames, date: Date): string {
    return [event, date.getTime()].join(this.delimiter);
  }

  private logNoScheduledEvent(event: keyof EventNames, date: Date): void {
    console.log(
      `No scheduled dates exists for event with name [${String(
        event
      )}] and date [${date.toString()}]`
    );
  }
}

export const MainScheduler = new Scheduler(MainEmitter);
