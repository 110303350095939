import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { YourMoneyStepMeta } from "./YourMoneyStepTypes";
import { defaultProvision } from "./YourMoneyStepInitialState";
import { JourneyStepsAccordionItemProps } from "../../JourneyStepsTypes";
import {
  PROVISION_KNOW_POLICY_REF_NUM_LIST,
  PROVISION_PRODUCT_TYPE_LIST_INVESTMENT,
  PROVISION_PRODUCT_TYPE_LIST_PENSION,
  PROVISION_PRODUCT_TYPE_LIST_SAVINGS,
  PROVISION_TYPE_OF_HOLDING_LIST,
  PROVISION_WHO_BELONG_TO_LIST,
  PROVISION_WHO_IS_IT_HELD_WITH_LIST,
} from "./YourMoneyStepConstants";
import {
  holdingTypeList,
  isReferenceNumberKnownList,
  productTypeInvestmentsList,
  productTypePensionsList,
  productTypeSavingsList,
  providerNameList,
} from "../../../../components/journeySteps/steps/yourMoneyStep/yourMoneyStepLists";

const defaultYourMoneyStepState: YourMoneyStepMeta = {
  provisions: [defaultProvision],
  activeProvisionIndex: 0,
  navigationDisabled: false,
  lists: {
    [PROVISION_KNOW_POLICY_REF_NUM_LIST]: isReferenceNumberKnownList.items,
    [PROVISION_PRODUCT_TYPE_LIST_INVESTMENT]: productTypeInvestmentsList.items,
    [PROVISION_PRODUCT_TYPE_LIST_PENSION]: productTypePensionsList.items,
    [PROVISION_PRODUCT_TYPE_LIST_SAVINGS]: productTypeSavingsList.items,
    [PROVISION_TYPE_OF_HOLDING_LIST]: holdingTypeList.items,
    [PROVISION_WHO_BELONG_TO_LIST]: [],
    [PROVISION_WHO_IS_IT_HELD_WITH_LIST]: providerNameList.items,
  },
};

const yourMoneyStepSlice = createSlice({
  name: "yourMoneyStep",
  initialState: defaultYourMoneyStepState,
  reducers: {
    setYourMoneyValues: (state, action: PayloadAction<YourMoneyStepMeta>) => {
      state = action.payload;
    },
    setProvisions: (
      state,
      action: PayloadAction<JourneyStepsAccordionItemProps[]>
    ) => {
      state.provisions = action.payload;
    },
    setActiveProvisionIndex: (state, action: PayloadAction<number>) => {
      state.activeProvisionIndex = action.payload;
    },
    setNavigationDisabled: (state, action: PayloadAction<boolean>) => {
      state.navigationDisabled = action.payload;
    },
    resetToInitialValues: () => defaultYourMoneyStepState,
  },
});

export const {
  setYourMoneyValues,
  resetToInitialValues,
  setProvisions,
  setActiveProvisionIndex,
  setNavigationDisabled,
} = yourMoneyStepSlice.actions;
export default yourMoneyStepSlice.reducer;
