import { FormsConfigurationResource } from "@saturn-ui/components";

const formsConfiguration: FormsConfigurationResource = {
  content: {
    boolean: {
      trueCaption: "Yes",
      falseCaption: "No",
    },
    fieldLayout: {
      requiredIndicator: "",
    },
    date: {
      createPlaceholders: true,
    },
    datePicker: {
      todayCaption: "Today",
      openButtonAriaLabel: "Select Date",
    },
    help: {
      buttonText: "?",
      buttonAriaLabel: "Display Help",
      helpPosition: "afterCaption",
    },
    validation: {
      errors: {
        required: "This field is required",
        maxLength: "The maximum length is ${maxLength}.",
        minLength: "The minimum length is ${minLength}.",
        max: "The maximum value is ${maxValue}.",
        min: "The minimum value is ${minValue}.",
        maxDate: "The latest date is ${maxValue}.",
        minDate: "The earliest date is ${minValue}.",
        noFutureDate: "The date cannot be in the future",
        noPastDate: "The date cannot be in the past",
        invalid: "The value is invalid.",
        invalidDate: "Invalid date",
        regex: "The value is not in the expected format",
      },
      warnings: {
        required: "Warning: This field should be completed.",
        maxLength: "Warning: The recommended maximum length is ${maxLength}.",
        minLength: "Warning: The recommended minimum length is ${minLength}.",
        max: "Warning: The recommended maximum value is ${maxValue}.",
        min: "Warning: The recommended minimum value is ${minValue}.",
        maxDate: "Warning: The recommended latest date is ${maxValue}.",
        minDate: "Warning: The recommended earliest date is ${minValue}.",
        noFutureDate: "Warning: The date cannot be in the future",
        noPastDate: "Warning: The date cannot be in the past",
        invalid: "Warning: The value is invalid.",
        invalidDate: "Warning: Invalid date",
        regex: "Warning: The value is not in the expected format",
      },
    },
  },
};

export default formsConfiguration;
