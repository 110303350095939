import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import "./WelcomePage.scss";
import Modal from "../modal/Modal";
import { Layout } from "../layout/Layout";
import {
  FormContextProvider,
  FormsConfiguration,
  useFormContainer,
  useFormsConfiguration,
  useLocalization,
} from "@saturn-ui/components";
import ConfirmationFormContent from "./ConfirmationFormContent";
import { validate as confirmationFormValidate } from "./ConfirmationFormValidate";
import { validate as loginFormValidate } from "./LoginFormValidate";
import {
  ConfirmationFormError,
  ConfirmationFormState,
  ConfirmationFormTouched,
  LoginFormError,
  LoginFormState,
  LoginFormTouched,
  WelcomePageDefinition,
} from "src/feature/welcomePage/WelcomePageTypes";
import LoginFormContent from "./LoginFormContent";
import { InitializeUserData, useAuthLogic } from "../../hooks/useAuthLogic";
import { RootState, useRootSelector } from "skipton-features";
import { trackVirtualPageView } from "../../helpers/adobeAnalyticsHelpers";

type Props = {
  definition: WelcomePageDefinition;
};

export const WelcomePage: FC<Props> = ({ definition }) => {
  const [isStarted, setIsStarted] = useState(false);
  const { validation } = useFormsConfiguration<FormsConfiguration>();
  const localization = useLocalization();
  const { isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const { handleLogin, checkInitializeUserDataState } = useAuthLogic();

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      trackVirtualPageView({
        pageName: !isStarted ? "Welcome" : "Let's get started",
      });
    }
  }, [isAdobeAnalyticsLoaded, isStarted]);

  const validateConfirmationForm = (formState: ConfirmationFormState) => {
    return confirmationFormValidate(
      formState,
      definition.confirmationForm.content,
      validation,
      localization
    );
  };

  const validateLoginForm = (formState: LoginFormState) => {
    return loginFormValidate(
      formState,
      definition.loginForm.content,
      validation,
      localization
    );
  };

  const confirmationFormContainer = useFormContainer<
    ConfirmationFormState,
    ConfirmationFormError,
    ConfirmationFormTouched
  >({
    validateOnInitialize: false,
    validateFunc: validateConfirmationForm,
    initialValues: {
      confirmNoQuestions: false,
      confirmRead: false,
    },
  });

  const loginFormContainer = useFormContainer<
    LoginFormState,
    LoginFormError,
    LoginFormTouched
  >({
    validateOnInitialize: false,
    validateFunc: validateLoginForm,
    initialValues: {
      id: "",
    },
  });

  const handleStart = useCallback(() => {
    setIsStarted(true);
  }, []);

  const handleBackPress = useCallback(() => {
    setIsStarted(false);
  }, []);

  return (
    <Layout
      logoutIsHidden={!checkInitializeUserDataState(InitializeUserData.Success)}
    >
      <div className="welcome-page">
        <div className="left-side">
          <h1 className="page-title welcome-page-title">Welcome!</h1>
          <p>
            We understand just how important your long-term future is to you. To
            help us offer advice tailored to your situation, we would be
            grateful if you can do a few things ahead of your meeting. That way,
            your adviser can focus on giving you an even better service.
          </p>
          <p>
            There are four sections for you to complete before your meeting.
          </p>
          <ul className="welcome-steps-list">
            <li>Our advice approach</li>
            <li>About you</li>
            <li>Your money</li>
            <li>Your feelings towards risk and reward</li>
          </ul>
          <h2>Good to know</h2>
          <ul className="welcome-information-list">
            <li>
              In total, we expect this to take around 30 minutes of your time.
              If you need to pause and come back to it later, that is no
              problem. We will automatically save your progress, up to the
              section you are on. If you are part way through completing the
              section, it will not automatically save your progress.
            </li>
            <li>
              As we explained before you started, we will ask you to share
              copies of statements and other details about your current savings,
              investments and pensions along the way. If you do not have them to
              hand, it might be worth getting hold of them first – and coming
              back to start later.
            </li>
            <li>
              You will need to be working on a device that allows you to upload
              these documents. Or one that lets you take photos and share paper
              copies. On Skipton.co.uk/your-financial-advice-meeting we explain
              how you do this.
            </li>
            <li>
              If you are completing this information for both yourself and
              someone else (for example, your partner) you need to have their
              permission. They also need to be with you when you complete the
              risk and reward questionnaire in section four.
            </li>
          </ul>
          <h3 className="clarification-title">Here to help</h3>
          <p className="clarification-description">
            If you have any questions at this point, or would like assistance
            completing your information, please visit 'Help & FAQs' in the
            bottom left hand corner below.
          </p>
        </div>
        <div className="right-side welcome-page-bg">
          <Modal.InPage>
            {!isStarted ? (
              <FormContextProvider value={confirmationFormContainer}>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete="off"
                  noValidate
                >
                  <ConfirmationFormContent
                    onPressStart={handleStart}
                    definition={definition.confirmationForm.content}
                  />
                </form>
              </FormContextProvider>
            ) : (
              <FormContextProvider value={loginFormContainer}>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete="off"
                  noValidate
                >
                  <LoginFormContent
                    onBackPress={handleBackPress}
                    onLoginPress={handleLogin}
                    checkInitializeUserDataState={checkInitializeUserDataState}
                    definition={definition.loginForm.content}
                  />
                </form>
              </FormContextProvider>
            )}
          </Modal.InPage>
        </div>
      </div>
    </Layout>
  );
};

export default WelcomePage;
