import {
  JourneyConfiguration,
  JourneyConfigurationResources,
} from "./ConfigurationSliceTypes";
import { errorPageTemplate } from "skipton-common";

import {
  defaultFormsConfiguration,
  mergeDefinition,
  mergeFormsConfiguration,
} from "@saturn-ui/components";
import { template as logoTemplate } from "../logo/logoDefinitions";
import { template as welcomePageTemplate } from "../welcomePage/WelcomePageDefinition";
import { template as journeyStepsTemplate } from "../journeySteps/JourneyStepsDefinition";

const configurationTemplate: JourneyConfiguration = {
  definitions: {
    logo: logoTemplate,
    errorPage: errorPageTemplate,
    welcomePage: welcomePageTemplate,
    journeySteps: journeyStepsTemplate,
  },
  forms: defaultFormsConfiguration,
};

export const createConfiguration = (
  defaults: JourneyConfigurationResources
): JourneyConfiguration => {
  const templates = configurationTemplate;
  const templateDefinitions = templates.definitions;

  // Here we merge the default configuration values with our customer's overrides (if they exist).
  return {
    definitions: {
      logo: mergeDefinition(
        templateDefinitions.logo,
        defaults.definitions?.logo?.content
      ),
      errorPage: mergeDefinition(
        templateDefinitions.errorPage,
        defaults.definitions?.errorPage?.content
      ),
      welcomePage: mergeDefinition(
        templateDefinitions.welcomePage,
        defaults.definitions?.welcomePage?.content
      ),
      journeySteps: mergeDefinition(
        templateDefinitions.journeySteps,
        defaults.definitions?.journeySteps?.content
      ),
    },
    forms: mergeFormsConfiguration(templates.forms, defaults.forms?.content),
  };
};
