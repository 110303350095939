import {
  FieldValidator,
  Localization,
  ValidationConfiguration,
} from "@saturn-ui/components";
import {
  ProvisionFormDefinition,
  ProvisionFormErrors,
  YourMoneyStepErrors,
  YourMoneyStepState,
} from "../YourMoneyStepTypes";
import {
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_FORM_KNOW_POLICY_REF_NUM,
  PROVISION_FORM_POLICY_REFERENCE_NUMBER,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_FORM_WHO_BELONG_TO,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
  PROVISION_SCOPE_NAME,
} from "../YourMoneyStepConstants";
import { ClientCategory } from "../../../../../feature/financialAdvicePage/FinancialAdvicePageTypes";
import { CLIENT_CATEGORY_JOINT } from "../../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";

export const validate = (
  formValues: YourMoneyStepState,
  definition: ProvisionFormDefinition,
  config: ValidationConfiguration,
  localization: Localization,
  category: ClientCategory
): {
  errors: YourMoneyStepErrors;
  isValid: boolean;
} => {
  const errors: ProvisionFormErrors = {};

  const fv = new FieldValidator<ProvisionFormErrors>(
    errors,
    config,
    localization
  );

  fv.checkSelect(
    definition.fields.content[PROVISION_FORM_TYPE_OF_HOLDING],
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING],
    PROVISION_FORM_TYPE_OF_HOLDING
  );

  fv.checkSelect(
    definition.fields.content[PROVISION_FORM_WHO_IS_IT_HELD_WITH],
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH],
    PROVISION_FORM_WHO_IS_IT_HELD_WITH
  );

  fv.checkSelect(
    definition.fields.content[PROVISION_FORM_PRODUCT_TYPE],
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_PRODUCT_TYPE],
    PROVISION_FORM_PRODUCT_TYPE
  );

  fv.checkSelect(
    definition.fields.content[PROVISION_FORM_KNOW_POLICY_REF_NUM],
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_KNOW_POLICY_REF_NUM],
    PROVISION_FORM_KNOW_POLICY_REF_NUM
  );

  fv.checkNumber(
    definition.fields.content[PROVISION_FORM_HOW_MUCH_IS_IT_WORTH],
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_HOW_MUCH_IS_IT_WORTH],
    PROVISION_FORM_HOW_MUCH_IS_IT_WORTH
  );

  if (category === CLIENT_CATEGORY_JOINT) {
    fv.checkSelect(
      definition.fields.content[PROVISION_FORM_WHO_BELONG_TO],
      formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_BELONG_TO],
      PROVISION_FORM_WHO_BELONG_TO
    );
  }

  if (
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH] &&
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH].id ===
      "Other"
  ) {
    fv.checkText(
      definition.fields.content[PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER],
      formValues[PROVISION_SCOPE_NAME][
        PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER
      ],
      PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER
    );
  }

  if (
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_KNOW_POLICY_REF_NUM] &&
    formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_KNOW_POLICY_REF_NUM].id ===
      "Yes"
  ) {
    fv.checkText(
      definition.fields.content[PROVISION_FORM_POLICY_REFERENCE_NUMBER],
      formValues[PROVISION_SCOPE_NAME][PROVISION_FORM_POLICY_REFERENCE_NUMBER],
      PROVISION_FORM_POLICY_REFERENCE_NUMBER
    );
  }

  return {
    errors: { [PROVISION_SCOPE_NAME]: { ...errors } },
    isValid: fv.isValid(),
  };
};
