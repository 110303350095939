import React, { FC, useState } from "react";
import { YourMoneyStepState } from "../../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";
import { DocumentUpload } from "../../../../../../DocumentUpload/DocumentUpload";
import {
  PROVISION_FORM_UPLOADED_FILES,
  PROVISION_SCOPE_NAME,
} from "../../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepConstants";
import { DocumentUploaded } from "../../../../../../DocumentUpload/DocumentUploaded";

type Props = {
  values: YourMoneyStepState;
  setValuesAndAllowContinue: (values: YourMoneyStepState) => void;
};

export const UploadStatementsComponent: FC<Props> = ({
  values,
  setValuesAndAllowContinue,
}) => {
  const [documentsUploaded, setDocumentsUploaded] = useState<string[]>(
    values[PROVISION_SCOPE_NAME][PROVISION_FORM_UPLOADED_FILES]
  );

  const addUploadedDocument = (fileName: string) => {
    setDocumentsUploaded([...documentsUploaded, fileName]);
    setValuesAndAllowContinue({
      ...values,
      [PROVISION_SCOPE_NAME]: {
        ...values[PROVISION_SCOPE_NAME],
        [PROVISION_FORM_UPLOADED_FILES]: [
          ...values[PROVISION_SCOPE_NAME][PROVISION_FORM_UPLOADED_FILES],
          fileName,
        ],
      },
    });
  };
  return (
    <div className="upload-statement-component">
      <h3>Upload Statements</h3>
      <p>
        Use the button below to begin uploading the related documents about this
        holding. If your statement is longer than one page, please add each page
        individually below.
      </p>
      <p>Please note: each document needs to be under 10mb in size.</p>
      <p className="help">
        Having difficulty? Not to worry. Select 'Help & FAQs' in the bottom left
        corner to find our video guide for uploading documents.
      </p>

      {!!documentsUploaded.length &&
        documentsUploaded.map((fileName, index) => (
          <DocumentUploaded fileName={fileName} key={index} />
        ))}

      <DocumentUpload
        firstDocument={!documentsUploaded.length}
        provisionValues={values[PROVISION_SCOPE_NAME]}
        addUploadedDocument={addUploadedDocument}
        key={documentsUploaded.length}
      />
    </div>
  );
};
