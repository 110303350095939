import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const ErrorPageIcon: React.FC<Props> = ({
  className,
  height = 143,
  width = 160,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <path
      d="M92.2222 68.6666H67.0635V76.2857H92.2222V68.6666Z"
      fill="#009FE3"
    />
    <path
      d="M102.286 76.2856H92.2222V83.9047H102.286V76.2856Z"
      fill="#009FE3"
    />
    <path d="M67.0635 76.2858H57V83.9048H67.0635V76.2858Z" fill="#009FE3" />
    <path d="M97 42H87V52H97V42Z" fill="#009FE3" />
    <path d="M72 42H62V52H72V42Z" fill="#009FE3" />
    <path
      d="M154.286 0H5.71429C2.47619 0 0 2.99839 0 6.91935V117.629C0 121.55 2.47619 124.548 5.71429 124.548H76.1905V133.774H41.9048C39.8095 133.774 38.0952 135.85 38.0952 138.387C38.0952 140.924 39.8095 143 41.9048 143H76.1905H83.8095H118.095C120.19 143 121.905 140.924 121.905 138.387C121.905 135.85 120.19 133.774 118.095 133.774H83.8095V124.548H154.286C157.524 124.548 160 121.55 160 117.629V6.91935C160 2.99839 157.524 0 154.286 0ZM152.381 115.323H83.8095H76.1905H7.61905V9.22581H152.381V115.323Z"
      fill="#009FE3"
    />
  </SvgIcon>
);
