import React, { FC } from "react";
import { SubmitButton } from "@saturn-ui/components";
import "../journeySteps/JourneySteps.scss";
import "./ErrorRetry.scss";

interface Props {
  handleRetryButtonClick: () => void;
}

export const ErrorRetry: FC<Props> = ({ handleRetryButtonClick }) => {
  return (
    <div className="error-retry">
      <h1 className="step-title">
        Oops..
        <br />
        Something went wrong
      </h1>
      <p>
        An error seems to have occurred. Something has gone wrong while trying
        to display this webpage. To continue, try refreshing this page.
      </p>
      <SubmitButton
        className="error-page-button decorated-button"
        onClick={handleRetryButtonClick}
        variant="contained"
        id="retry-button"
        disableRipple={true}
        color="primary"
      >
        <span className="decoration">Refresh the page</span>
      </SubmitButton>
    </div>
  );
};
