import errorPage from "./ErrorPage";
import logo from "./logo";
import welcomePage from "./WelcomePage";
import journeySteps from "./journeySteps/JourneySteps";

const definitions = {
  logo,
  errorPage,
  welcomePage,
  journeySteps,
};

export default definitions;
