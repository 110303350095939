import { createAsyncThunk } from "@reduxjs/toolkit";
import { CaseApi, getCaseApi } from "skipton-common";
import { withSessionId } from "../../../utils/AuthorisationUtils";

export const getAdviser = createAsyncThunk<
  CaseApi.AdviserResponse,
  {
    baseUrl: string;
  }
>(
  "get-adviser",
  async (data: { baseUrl: string }): Promise<CaseApi.AdviserResponse> => {
    const { baseUrl } = data;

    const caseApi = withSessionId(getCaseApi(baseUrl));

    const response = await caseApi.getAdviser();

    return response.body;
  }
);

export const getAdviserSuccess = getAdviser.fulfilled;
export const getAdviserPending = getAdviser.pending;
export const getAdviserFailed = getAdviser.rejected;
