import React, { FC } from "react";
import { YourMoneyStepState } from "../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";
import {
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
  PROVISION_SCOPE_NAME,
} from "../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepConstants";
import { useLocalization } from "@saturn-ui/components";
import { formatCurrencyValueToView } from "../../../aboutYouStep/AboutYouStep.utils";
import "./ProvisionSummaryComponent.scss";

type Props = {
  values: YourMoneyStepState;
};

export const ProvisionSummaryComponent: FC<Props> = ({ values }) => {
  const localization = useLocalization();

  const provision = values[PROVISION_SCOPE_NAME];

  const whoIsItHeldWithText = provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH]
    ? provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH].id === "Other"
      ? provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER]
      : provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH].displayText
    : "";
  const holdingType = provision[PROVISION_FORM_TYPE_OF_HOLDING]
    ? provision[PROVISION_FORM_TYPE_OF_HOLDING].displayText
    : "";

  const amount = provision[PROVISION_FORM_HOW_MUCH_IS_IT_WORTH] || 0;
  const productType = provision[PROVISION_FORM_PRODUCT_TYPE]
    ? ` in ${provision[PROVISION_FORM_PRODUCT_TYPE].displayText || ""}`
    : "";

  return (
    <div className="provision-summary-component">
      <p>
        {whoIsItHeldWithText} {holdingType}
      </p>
      <p>
        {formatCurrencyValueToView(localization, amount)}
        {productType}
      </p>
    </div>
  );
};
