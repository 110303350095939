import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Answers,
  YourFeelingsStepFormState,
  YourFeelingsStepMeta,
} from "./YourFeelingsStepTypes";
import { initialiseYourFeelingsStepSuccess } from "./thunks/InitialiseYourFeelingsStep";
import { defaultYourFeelingsStepFormState } from "./YourFeelingsStepInitialState";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
} from "../../../financialAdvicePage/FinancialAdvicePageConstants";
import { ClientScopeName } from "../../../financialAdvicePage/FinancialAdvicePageTypes";

const defaultState: YourFeelingsStepMeta = {
  initialValues: defaultYourFeelingsStepFormState,
  atrQuestions: null,
  questionnaireId: null,
  currentScope: CLIENT_1_SCOPE_NAME,
  currentQuestion: {
    [CLIENT_1_SCOPE_NAME]: 0,
    [CLIENT_2_SCOPE_NAME]: 0,
  },
  answers: {
    [CLIENT_1_SCOPE_NAME]: {},
    [CLIENT_2_SCOPE_NAME]: {},
  },
};

const yourFeelingsStepSlice = createSlice({
  name: "yourFeelingsStep",
  initialState: defaultState,
  reducers: {
    setValues: (state, action: PayloadAction<YourFeelingsStepFormState>) => {
      state.initialValues = action.payload;
    },
    setCurrentScope: (state, { payload }: PayloadAction<ClientScopeName>) => ({
      ...state,
      currentScope: payload,
    }),
    setCurrentQuestion: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      currentQuestion: {
        ...state.currentQuestion,
        [state.currentScope]: payload,
      },
    }),
    setAnswer: (state, { payload }: PayloadAction<Answers>) => ({
      ...state,
      answers: {
        ...state.answers,
        [state.currentScope]: {
          ...state.answers[state.currentScope],
          ...payload,
        },
      },
    }),
    resetState: () => ({
      ...defaultState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      initialiseYourFeelingsStepSuccess,
      (state, { payload }) => ({ ...state, ...payload })
    );
  },
});

export const {
  setValues,
  setCurrentQuestion,
  setCurrentScope,
  setAnswer,
  resetState,
} = yourFeelingsStepSlice.actions;

export default yourFeelingsStepSlice.reducer;
