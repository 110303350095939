import React, { FC, useCallback } from "react";
import {
  Button,
  createFormScope,
  FieldRenderer,
  SubmitButton,
  TextField,
  useFormContext,
} from "@saturn-ui/components";
import {
  LoginFormDefinition,
  LoginFormError,
  LoginFormState,
  LoginFormTouched,
} from "../../feature/welcomePage/WelcomePageTypes";
import { ArrowBackIcon } from "../../assets/icons/ArrowBackIcon";
import { backButtonSx } from "../../theme/skiptonLightThemeOverrides";
import { useSelector } from "react-redux";
import { selectIsLoginFailed } from "../../redux/selectors/authentication";
import { InitializeUserData } from "../../hooks/useAuthLogic";
import { focusOnInputById } from "../../utils/formUtils";

type Props = {
  definition: LoginFormDefinition;
  onLoginPress: (id: string) => void;
  onBackPress: () => void;
  checkInitializeUserDataState: (...states: InitializeUserData[]) => boolean;
};

export const LoginFormContent: FC<Props> = ({
  onLoginPress,
  definition,
  onBackPress,
  checkInitializeUserDataState,
}) => {
  const isLoginFailed = useSelector(selectIsLoginFailed);
  const formContext = useFormContext<
    LoginFormState,
    LoginFormError,
    LoginFormTouched
  >();

  const {
    errors,
    values,
    handleFieldUpdate,
    validateForm,
    isValid,
    setFieldTouched,
    touched,
  } = formContext;

  const loginFormScope = createFormScope({
    name: "",
    values,
    errors,
    touched,
    readOnly: false,
    disabled: false,
  });

  const handlePressLogin = useCallback(() => {
    validateForm();
    setFieldTouched("id");
    if (isValid) {
      onLoginPress(values.id);
    } else {
      focusOnInputById("id", setFieldTouched);
    }
  }, [
    validateForm,
    setFieldTouched,
    isValid,
    onLoginPress,
    values.id,
    errors,
    errors.id,
    focusOnInputById,
  ]);

  return (
    <div data-testid="login-form" id="login-form">
      <Button
        id="return-to-confirmation-button"
        className="back-button"
        sx={backButtonSx}
        startIcon={<ArrowBackIcon />}
        onClick={onBackPress}
        disableRipple={true}
        variant="text"
      >
        Back
      </Button>
      <h2 className="welcome-page-label-important">Let's get you started</h2>
      <p>
        For peace of mind, you should know all the information you provide us
        will be kept securely. To help us protect your information, we need you
        to enter your unique Access Code.
      </p>
      <p>
        You will find your Access Code in the letter or email we sent you,
        confirming your financial advice meeting appointment.
      </p>
      <FieldRenderer
        name="id"
        definition={definition.fields.content.id}
        scope={loginFormScope}
        render={(fieldProps) => {
          return (
            <>
              <TextField
                {...fieldProps}
                value={values.id}
                isLarge
                onUpdate={handleFieldUpdate}
                errorMessage={
                  ((isLoginFailed ||
                    checkInitializeUserDataState(InitializeUserData.Error)) &&
                    "Something went wrong. Please try again.") ||
                  fieldProps.errorMessage
                }
              />
            </>
          );
        }}
      />
      <p>
        Experiencing problems? Not to worry, please visit 'Help & FAQs' in the
        bottom left hand corner below for further support.
      </p>
      <SubmitButton
        className="welcome-page-start-button submit-button decorated-button"
        onClick={handlePressLogin}
        variant="contained"
        id="welcome-page-start-button"
        disableRipple={true}
        color={"primary"}
        submitting={checkInitializeUserDataState(
          InitializeUserData.Initialising
        )}
      >
        <span className="decoration">I am ready to continue</span>
      </SubmitButton>
    </div>
  );
};

export default LoginFormContent;
