import {
  YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  YOUR_FEELINGS_FORM_IS_QUESTION_FIELD,
  YOUR_FEELINGS_FORM_QUESTION_FIELD,
} from "./YourFeelingsStepConstants";

import {
  BooleanFieldDef,
  FieldMessages,
  FormScope,
  GroupContent,
  GroupDef,
  ListFieldDef,
  ListItem,
  TextFieldDef,
} from "@saturn-ui/components";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
} from "../../../financialAdvicePage/FinancialAdvicePageConstants";
import { ClientScopeName } from "../../../financialAdvicePage/FinancialAdvicePageTypes";

export type YourFeelingsStepDefinition = {
  form: GroupDef & GroupContent<YourFeelingsStepFormDefinition>;
  ATRForm: GroupDef & GroupContent<ATRFormDefinition>;
};

export type YourFeelingsStepFormDefinition = {
  fields: GroupDef & GroupContent<YourFeelingsStepFormFieldsDefinition>;
};

export type YourFeelingsStepFormFieldsDefinition = {
  [YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD]: BooleanFieldDef;
  [YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]: BooleanFieldDef;
  [YOUR_FEELINGS_FORM_QUESTION_FIELD]: TextFieldDef;
};

export type YourFeelingsStepMeta = {
  currentScope: ClientScopeName;
  currentQuestion: {
    [CLIENT_1_SCOPE_NAME]: number;
    [CLIENT_2_SCOPE_NAME]: number;
  };
  answers: {
    [CLIENT_1_SCOPE_NAME]: Answers;
    [CLIENT_2_SCOPE_NAME]: Answers;
  };
  initialValues: YourFeelingsStepFormState;
  atrQuestions: ATRQuestionsList | null;
  questionnaireId: string | null;
};

export type ATRFormDefinition = {
  fields: GroupDef & GroupContent<ARTFormFieldsDefinition>;
};

export type ARTFormFieldsDefinition = {
  [key: string]: ListFieldDef;
};

export enum YourFeelingsStepContentStates {
  ConfirmNoQuestions = "ConfirmNoQuestions",
  ATR = "ATR",
}

export enum CompleteJourneyState {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}
export enum FinishATRState {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export enum InitialiseYourFeelingsStepStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export type YourFeelingsStepFormState = {
  [YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD]: boolean | null;
  [YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]: boolean | null;
  [YOUR_FEELINGS_FORM_QUESTION_FIELD]: string | null;
};

export type YourFeelingsStepFormErrors = {
  [YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD]?: FieldMessages;
  [YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]?: FieldMessages;
  [YOUR_FEELINGS_FORM_QUESTION_FIELD]?: FieldMessages;
};

export type YourFeelingsStepFormTouched = {
  [YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD]?: boolean;
  [YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]?: boolean;
  [YOUR_FEELINGS_FORM_QUESTION_FIELD]?: boolean;
};

export type ATRState = {
  [key: string]: ListItem;
};

export type ATRFormState = {
  [CLIENT_1_SCOPE_NAME]: ATRState;
  [CLIENT_2_SCOPE_NAME]: ATRState;
};

export type ATRErrors = {
  [key: string]: FieldMessages;
};

export type ATRFormErrors = {
  [CLIENT_1_SCOPE_NAME]: ATRErrors;
  [CLIENT_2_SCOPE_NAME]: ATRErrors;
};

export type ATRTouched = {
  [key: string]: boolean;
};

export type ATRFormTouched = {
  [CLIENT_1_SCOPE_NAME]: ATRTouched;
  [CLIENT_2_SCOPE_NAME]: ATRTouched;
};

export type ATRFormScope = FormScope<ATRState, ATRErrors, ATRTouched>;

export type ATRAnswer = {
  id: number;
  text: string;
};

export type AtrQuestion = {
  id: number;
  answers: ATRAnswer[];
  description: string;
};

export type ATRQuestionsList = AtrQuestion[];

export type InitialiseYourFeelingsResponse = {
  questionnaireId: string;
  questions: ATRQuestionsList;
};

export type Answers = {
  [key: string]: ListItem | null;
};
