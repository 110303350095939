import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const ownershipSingleClientList: List = {
  listType: "ownershipSingleClient",
  lang: "en-GB",
  items: [
    {
      id: "Myself",
      displayText: "I own the property myself",
    },
    {
      id: "Other",
      displayText: "Other",
    },
  ],
};
