import { CLIENT_CATEGORY_JOINT } from "../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import { ClientCategory } from "../../feature/financialAdvicePage/FinancialAdvicePageTypes";

export const getVirtualPageCustomerType = (
  clientCategory: ClientCategory
): string => {
  return clientCategory === CLIENT_CATEGORY_JOINT ? "joint" : "sole";
};

export const trackVirtualPageView = (virtualPageView: {
  pageName: string;
  customerType?: string;
  customerNumber?: number;
}): void => {
  const args = {
    pageName: virtualPageView.pageName,
    ...(virtualPageView.customerType !== undefined
      ? { customerType: virtualPageView.customerType }
      : {}),
    ...(virtualPageView.customerNumber !== undefined
      ? { customerNumber: virtualPageView.customerNumber }
      : {}),
  };

  window._satellite && window._satellite.track("virtualPageView", args);
};
