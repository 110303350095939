const DefaultRoute = "/";
const CookiePolicyRoute = "/cookiepolicy";
const FinancialAdviceRoute = "/welcome";
const AboutAdviserRoute = "/welcome/about-adviser";
const OurAdviceRoute = "/steps/our-advice";
const AboutYouRoute = "/steps/about-you";
const YourFeelingsRoute = "/steps/your-feelings";
const ThankYouRoute = "/thank-you";
const YourMoneyRoute = "/steps/your-money";
const ErrorPage = "/error";

export const getDefaultRoute = (): string => {
  return DefaultRoute;
};

export const getFinancialAdviceRoute = (): string => {
  return FinancialAdviceRoute;
};

export const getAboutAdviserRoute = (): string => {
  return AboutAdviserRoute;
};

export const getCookiePolicyRoute = (): string => {
  return CookiePolicyRoute;
};

export const getOurAdviceRoute = (): string => {
  return OurAdviceRoute;
};

export const getAboutYouRoute = (): string => {
  return AboutYouRoute;
};

export const getYourFeelingsRoute = (): string => {
  return YourFeelingsRoute;
};

export const getYourMoneyRoute = (): string => {
  return YourMoneyRoute;
};

export const getErrorRoute = (): string => {
  return ErrorPage;
};

export const getThankYouRoute = (): string => {
  return ThankYouRoute;
};
