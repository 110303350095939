import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const DoneIcon: React.FC<Props> = ({
  className,
  height = 16,
  width = 16,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <path
      d="M6.56005 11.7792L3.80005 9.01922L5.00005 7.87922L6.56005 9.43922L11 4.99922L12.2 6.19922L6.56005 11.7792Z"
      fill="currentColor"
    />
  </SvgIcon>
);
