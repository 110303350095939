import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCaseApi } from "skipton-common";
import { AppState } from "../../../../../types/AppState";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";
import { ProvisionFormState } from "../YourMoneyStepTypes";

export const postDocument = createAsyncThunk<
  void,
  {
    baseUrl: string;
    provisionValues: ProvisionFormState;
    file: File;
  },
  { state: AppState }
>(
  "post-document",
  async (data: {
    baseUrl: string;
    provisionValues: ProvisionFormState;
    file: File;
  }): Promise<void> => {
    const { baseUrl, provisionValues, file } = data;

    const caseApi = withSessionId(getCaseApi(baseUrl));
    const providerName =
      provisionValues.whoIsItHeldWith?.id === "Other"
        ? provisionValues.whoIsItHeldWithOther
        : provisionValues.whoIsItHeldWith?.displayText;
    let description = `${providerName || ""}`;
    if (
      provisionValues.productType &&
      provisionValues.productType.displayText
    ) {
      description += ` ${provisionValues.productType.displayText}`;
    }

    const requestData = {
      reference: "CFF",
      description,
      documentType: "CUD",
    };

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("request", JSON.stringify(requestData));

    if (provisionValues.provisionId) {
      await caseApi.postDocumentUpload(provisionValues.provisionId, formData);
    } else {
      throw new Error("Provision Id is not set!");
    }
  }
);

export const postDocumentUploadSuccess = postDocument.fulfilled;
export const postDocumentUploadPending = postDocument.pending;
export const postDocumentUploadFailed = postDocument.rejected;
