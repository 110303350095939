import React, { FC, useCallback, useEffect, useRef } from "react";
import { useAppDispatch } from "../../../../../redux/store";
import {
  BooleanField,
  createFormScope,
  FieldRenderer,
  FieldUpdateEventArgs,
  SubmitButton,
  TextField,
  useFormContext,
} from "@saturn-ui/components";
import { setValues } from "../../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepSlice";
import {
  OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  OUR_ADVICE_FORM_IS_QUESTION_FIELD,
  OUR_ADVICE_FORM_QUESTION_FIELD,
  ourAdviceStepFieldKeys,
} from "../../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepConstants";
import { SendCFFCustomDataStates } from "../../../../../feature/customData/CustomDataTypes";
import {
  OurAdviceStepDefinition,
  OurAdviceStepFormErrors,
  OurAdviceStepFormState,
  OurAdviceStepFormTouched,
} from "../../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepTypes";
import { handleIsQuestionFieldChange } from "../OurAdviceStep.utils";
import clsx from "clsx";
import {
  focusOnInputById,
  getFirstErrorKey,
} from "../../../../../utils/formUtils";

type Props = {
  definition: OurAdviceStepDefinition;
  sendCFFCustomDataState: SendCFFCustomDataStates;
  handleContinueClick: () => void;
};

export const OurAdviceStepFormContent: FC<Props> = ({
  definition,
  sendCFFCustomDataState,
  handleContinueClick,
}) => {
  const dispatch = useAppDispatch();

  const formContext = useFormContext<
    OurAdviceStepFormState,
    OurAdviceStepFormErrors,
    OurAdviceStepFormTouched
  >();

  const {
    touched,
    errors,
    handleFieldUpdate,
    values,
    validateForm,
    setFieldTouched,
    updateState,
    isValid,
  } = formContext;

  const formValues = useRef(values);

  const ourAdviceScope = createFormScope({
    name: "",
    values,
    errors,
    touched,
    readOnly: false,
    disabled: false,
  });

  useEffect(() => {
    formValues.current = values;
  }, [values]);

  const handlePressContinue = useCallback(() => {
    validateForm();
    setFieldTouched(OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD);
    setFieldTouched(OUR_ADVICE_FORM_IS_QUESTION_FIELD);
    setFieldTouched(OUR_ADVICE_FORM_QUESTION_FIELD);

    if (isValid) {
      dispatch(setValues(values));
      handleContinueClick();
    } else {
      const firstErrorKey = getFirstErrorKey(errors, ourAdviceStepFieldKeys);
      if (firstErrorKey) {
        focusOnInputById(firstErrorKey, setFieldTouched);
      }
    }
  }, [
    validateForm,
    setFieldTouched,
    errors,
    dispatch,
    values,
    handleContinueClick,
    getFirstErrorKey,
    focusOnInputById,
  ]);

  const handleIsQuestionFieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<boolean>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      handleIsQuestionFieldChange(updateState, setFieldTouched);
    },
    [handleFieldUpdate, setFieldTouched, updateState]
  );

  const checkSendCFFCustomDataState = (...states: SendCFFCustomDataStates[]) =>
    !!states.find((state) => state === sendCFFCustomDataState);

  return (
    <>
      <fieldset>
        <legend className="legend-no-top-margin">
          It is a requirement that everyone who is attending the financial
          advice meeting watches this video.
        </legend>
        <div className="our-advice-step-checkbox-wrapper">
          <FieldRenderer
            name={OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD}
            definition={
              definition.form.content.fields.content[
                OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD
              ]
            }
            scope={ourAdviceScope}
            render={(fieldProps) => (
              <BooleanField
                {...fieldProps}
                labelPosition="right"
                value={values[OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD]}
                onUpdate={handleFieldUpdate}
                errorMessage={fieldProps.errorMessage}
              />
            )}
          />
        </div>
      </fieldset>
      <fieldset className="fieldset-with-top-margin">
        <legend className="legend-important legend-no-bottom-margin">
          Do you have any questions about the information in the video?
        </legend>
        <div className="our-advice-question-wrapper">
          <p className="paragraph-no-margin">
            Select yes below to share your query, and we'll either answer when
            you meet your adviser or contact you before your meeting.
          </p>
          <div className="our-advice-question-radio-wrapper">
            {errors[OUR_ADVICE_FORM_IS_QUESTION_FIELD] &&
              touched[OUR_ADVICE_FORM_IS_QUESTION_FIELD] && (
                <div className="error-message">
                  Please select if you do or do not have questions.
                </div>
              )}
            <FieldRenderer
              name={OUR_ADVICE_FORM_IS_QUESTION_FIELD}
              definition={
                definition.form.content.fields.content[
                  OUR_ADVICE_FORM_IS_QUESTION_FIELD
                ]
              }
              scope={ourAdviceScope}
              render={(fieldProps) => (
                <BooleanField
                  {...fieldProps}
                  trueLabel="Yes, I do have questions"
                  falseLabel="No, I do not have questions"
                  value={values[OUR_ADVICE_FORM_IS_QUESTION_FIELD]}
                  onUpdate={handleIsQuestionFieldUpdate}
                />
              )}
            />
          </div>
          {values[OUR_ADVICE_FORM_IS_QUESTION_FIELD] && (
            <FieldRenderer
              name={OUR_ADVICE_FORM_QUESTION_FIELD}
              definition={
                definition.form.content.fields.content[
                  OUR_ADVICE_FORM_QUESTION_FIELD
                ]
              }
              scope={ourAdviceScope}
              render={(fieldProps) => (
                <TextField
                  {...fieldProps}
                  isLarge
                  value={values[OUR_ADVICE_FORM_QUESTION_FIELD]}
                  onUpdate={handleFieldUpdate}
                />
              )}
            />
          )}
        </div>
      </fieldset>
      <div
        className={clsx("our-advice-continue-button-wrapper", {
          error: checkSendCFFCustomDataState(SendCFFCustomDataStates.Error),
        })}
      >
        {checkSendCFFCustomDataState(SendCFFCustomDataStates.Error) && (
          <div className="error-message">
            Something went wrong. Please try again.
          </div>
        )}
        <SubmitButton
          className="our-advice-continue-button submit-button decorated-button"
          onClick={handlePressContinue}
          variant="contained"
          id="our-advice-continue-button"
          disableRipple={true}
          color="primary"
          submitting={checkSendCFFCustomDataState(
            SendCFFCustomDataStates.Initialising
          )}
        >
          <span className="decoration">I am ready to continue</span>
        </SubmitButton>
      </div>
    </>
  );
};
