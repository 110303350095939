import { Localization, UpdateStateFunction } from "@saturn-ui/components";
import {
  AboutYouStepState,
  AboutYouStepErrors,
  AboutYouStepTouched,
} from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  ABOUT_YOU_FORM_TAX_STATUS_FIELD,
  ABOUT_YOU_SCOPE_NAME,
} from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepConstants";
import { ClientScopeName } from "../../../../feature/financialAdvicePage/FinancialAdvicePageTypes";

export const resetTaxStatusField = (
  values: WritableDraft<AboutYouStepState>,
  setFieldTouched: (fieldPath: string, touched: boolean) => void,
  clientScopeName: ClientScopeName
): WritableDraft<AboutYouStepState> => {
  setFieldTouched(
    `${ABOUT_YOU_SCOPE_NAME}.${clientScopeName}.${ABOUT_YOU_FORM_TAX_STATUS_FIELD}`,
    false
  );

  values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
    ABOUT_YOU_FORM_TAX_STATUS_FIELD
  ] = null;
  return values;
};

export const handleTaxJurisdictionFieldChange = (
  updateState: (
    updateFunc: UpdateStateFunction<
      AboutYouStepState,
      AboutYouStepErrors,
      AboutYouStepTouched
    >
  ) => void,
  setFieldTouched: (fieldPath: string, touched: boolean) => void,
  clientScopeName: ClientScopeName
): void => {
  updateState((values) =>
    resetTaxStatusField(values, setFieldTouched, clientScopeName)
  );
};

export const formatCurrencyValueToView = (
  localization: Localization,
  value: number | null | undefined
): string => {
  const currencySymbol =
    localization.getCurrencySymbol(localization.defaultCurrencyCode) ?? "";
  const defaultCurrencyValue = 0;
  const formatterValue = localization.defaultFormatters.number.default.format(
    value ?? defaultCurrencyValue
  );

  const decimalPart = (value ?? defaultCurrencyValue).toString().split(".")[1];
  const showDecimalZero = decimalPart && decimalPart.length === 1;

  return `${currencySymbol}${formatterValue}${showDecimalZero ? "0" : ""}`;
};
