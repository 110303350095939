import React, { FC, useEffect } from "react";
import { Layout } from "../layout/Layout";
import { SubmitButton } from "@saturn-ui/components";
import { useHistory, useLocation } from "react-router-dom";
import { getDefaultRoute } from "../../helpers";
import "../journeySteps/JourneySteps.scss";
import "./ErrorPage.scss";
import { ErrorPageIcon } from "../../assets";
import { HTTP_UNAUTHORIZED_STATUS_CODE } from "skipton-common";
import { useAuthLogic } from "../../hooks/useAuthLogic";
import { useOnMount } from "../../hooks/useOnMount";
import { RootState, useRootSelector } from "skipton-features";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../helpers/adobeAnalyticsHelpers";
import { ClientCategory } from "../../feature/financialAdvicePage/FinancialAdvicePageTypes";

export type LocationState = {
  errorCode?: string;
  category?: ClientCategory;
};

export const ErrorPage: FC = () => {
  const history = useHistory() as unknown[];
  const { handleSessionStop, handleLogout } = useAuthLogic();
  const location = useLocation<LocationState>() as { state: LocationState };
  const { isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );

  useOnMount(() => {
    if (location.state?.errorCode === HTTP_UNAUTHORIZED_STATUS_CODE) {
      handleSessionStop();
    } else {
      handleLogout();
    }
  });

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = location.state?.category
        ? getVirtualPageCustomerType(location.state?.category)
        : undefined;

      trackVirtualPageView({
        pageName: "Error",
        ...(customerType
          ? {
              customerType,
            }
          : {}),
      });
    }
  }, [isAdobeAnalyticsLoaded]);

  const handleBackClick = () => {
    history.push(getDefaultRoute());
  };

  const renderErrorPageContent = () => {
    if (location.state?.errorCode === HTTP_UNAUTHORIZED_STATUS_CODE) {
      return (
        <>
          <p className="error-page-name">Authorisation Error (401)</p>
          <p className="paragraph-no-top-margin">
            An error seems to have occurred. Something has gone wrong while
            trying to display this webpage. To continue, try logging in again.
          </p>
        </>
      );
    }

    return (
      <p className="paragraph-no-top-margin">
        An error seems to have occurred. Something has gone wrong while trying
        to display this webpage. To continue, try logging in again.
      </p>
    );
  };

  return (
    <Layout borderRadiusNone logoutIsHidden>
      <div className="journey-step error-page">
        <div className="left-side">
          <h1 className="step-title">
            Oops..
            <br />
            Something went wrong
          </h1>
          {renderErrorPageContent()}
          <SubmitButton
            className="error-page-button decorated-button"
            onClick={handleBackClick}
            variant="contained"
            id="back-to-login-button"
            disableRipple={true}
            color="primary"
          >
            <span className="decoration">Back to login</span>
          </SubmitButton>
        </div>
        <div className="right-side">
          <div className="error-page-img-wrapper" aria-hidden={true}>
            <ErrorPageIcon />
          </div>
        </div>
      </div>
    </Layout>
  );
};
