import React, { FC } from "react";

import { Layout } from "../layout/Layout";
import "./CookiePolicyPage.scss";

export const CookiePolicyPage: FC = () => {
  return (
    <Layout.SubPage
      title="Cookie Policy"
      backButtonText=""
      alwaysHavePadding={true}
      emptyFooter={true}
    >
      <div className="cookie-policy-page">
        <h2 className="page-title">Cookie Policy</h2>
        <p>
          Our cookie policy has information about the different types of
          cookies, what they do and how we use them.
        </p>

        <h3>Cookies and "similar technologies"</h3>
        <p>
          A cookie is a small file that’s downloaded on to your computer, tablet
          or smartphone (device) when you visit a website.
        </p>
        <p>
          The cookies we set are called ‘first party’ cookies, but we also use
          ‘third party’ cookies, which are set by a domain other than the one
          you are visiting. Cookies do lots of different things, like helping us
          to remember your preferences and showing us how our website is being
          used so we can make it better.
        </p>
        <p>
          Our cookie policy also applies to similar technologies, which include
          things like scripts, tracking pixels and plugins that perform similar
          functions to cookies. So, when we refer to ‘cookies’ in this policy,
          we’re referring to those ‘similar technologies’ too.
        </p>

        <h3>Deleting cookies</h3>
        <p>
          You can delete cookies, but how you do it is different from device to
          device. Check your browser settings to see how to delete them from
          yours.
        </p>

        <h3>Essential cookies</h3>
        <p>
          These cookies make sure our website works properly. Some enable you to
          log on, some enable accessibility features and some manage your cookie
          preferences. They also let you move around our site and use its
          features.
        </p>
        <p>
          We can’t turn them off, but you can block them in your browser
          settings, which will cause parts of our website not to work.
        </p>

        <h3>Cookies that measure</h3>
        <p>
          These cookies help us measure the number of visitors to our site and
          how they use it, so we learn what’s working and improve its
          performance. For example, they might show us if visitors are giving up
          at a certain step when completing a form. We use this information to
          let us try out new ideas to help us improve the experience for you.
        </p>

        <h4>More Information</h4>
        <div className="cookie-information-wrapper">
          <table className="cookie-information" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Maximum Duration</th>
                <th>More Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Adobe</td>
                <td>2 years</td>
                <td>
                  <a
                    href="https://www.adobe.com/uk/privacy/opt-out.html#customeruse"
                    target="_blank"
                    className="link"
                  >
                    Adobe Privacy Choices
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout.SubPage>
  );
};
