import React, { useRef, useState } from "react";
import { RootState, useRootSelector } from "skipton-features";
import { IconButton, SubmitButton } from "@saturn-ui/components";
import "./DocumentUpload.scss";
import {
  DeleteIcon,
  FileDownloadDoneIcon,
  TextSnippetIcon,
} from "../../assets";
import {
  getExtensionFromFileName,
  getUniqueInputId,
} from "./DocumentUpload.utils";
import {
  ProvisionFormState,
  SendDocumentStates,
} from "../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";
import { useAppDispatch } from "../../redux/store";
import { postDocument } from "../../feature/journeySteps/steps/yourMoneyStep/thunks";
import clsx from "clsx";
import { setNavigationDisabled } from "../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepSlice";

type Props = {
  firstDocument?: boolean;
  provisionValues: ProvisionFormState;
  addUploadedDocument: (fileName: string) => void;
};

type UploadState =
  | "selectDocument"
  | "errorWithDocument"
  | "okayToUpload"
  | "documentUploaded"
  | "errorWithUpload";

export const DocumentUpload: React.FC<Props> = ({
  firstDocument,
  provisionValues,
  addUploadedDocument,
}) => {
  const {
    fileSizeLimitBytes,
    fileSizeLimitDisplayName,
    allowedFileExtensions,
    APIUri,
  } = useRootSelector((state: RootState) => state.configurationSettings);
  const dispatch = useAppDispatch();
  const fileSizeLimit = fileSizeLimitBytes || 0;
  const [uploadState, setUploadState] = useState<UploadState>("selectDocument");
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [sendDocumentState, setSendDocumentState] = useState(
    SendDocumentStates.Uninitialised
  );

  const uploadInput = useRef<HTMLInputElement>(null);

  const uploadDocumentButtonText = firstDocument
    ? "Select Document"
    : "Select Another Document";

  const validateDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearLocalStates();
    const newErrorMessages: string[] = [];
    const selectedFile = event.target.files && event.target.files[0];
    const selectedFileName = selectedFile ? selectedFile.name : "";
    const extension = getExtensionFromFileName(selectedFileName);
    setFileName(selectedFileName);
    if (!selectedFile) {
      return false;
    }
    if (!allowedFileExtensions?.find((element) => element === extension)) {
      setUploadState("errorWithDocument");
      newErrorMessages.push(
        "Sorry we do not accept the file type you are trying to upload. Please try again."
      );
    }
    if (selectedFile.size > fileSizeLimit) {
      const fileSizeLimitText = fileSizeLimitDisplayName || "";
      setUploadState("errorWithDocument");
      newErrorMessages.push(
        `Sorry the file you are trying to upload is above ${fileSizeLimitText} in size. Please try again.`
      );
    }

    if (newErrorMessages.length) {
      setErrorMessages(newErrorMessages);
      return false;
    } else {
      setUploadState("okayToUpload");
    }
  };

  const uploadDocument = async (selectedFile: File): Promise<void> => {
    try {
      if (selectedFile) {
        setSendDocumentState(SendDocumentStates.Initialising);
        dispatch(setNavigationDisabled(true));

        await dispatch(
          postDocument({
            baseUrl: APIUri ?? "",
            provisionValues,
            file: selectedFile,
          })
        ).unwrap();
        setSendDocumentState(SendDocumentStates.Success);
        addUploadedDocument(fileName);
        setUploadState("documentUploaded");
        dispatch(setNavigationDisabled(false));
      }
    } catch (error) {
      setSendDocumentState(SendDocumentStates.Error);
      setUploadState("errorWithDocument");
      setErrorMessages(["Hmmm, something went wrong. Please try again."]);
      dispatch(setNavigationDisabled(false));
    }
  };

  const handleUploadDocumentClick = async () => {
    uploadInput.current?.focus();
    const selectedFile =
      uploadInput.current?.files && uploadInput.current?.files[0];
    if (selectedFile) {
      await uploadDocument(selectedFile);
    } else {
      setUploadState("errorWithDocument");
      setErrorMessages(["Error during upload, please try again."]);
    }
  };

  const clearLocalStates = () => {
    setErrorMessages([]);
    setFileName("");
    setUploadState("selectDocument");
  };

  const clearForm = () => {
    clearLocalStates();
    if (uploadInput.current) {
      uploadInput.current.value = "";
    }
  };

  const handleDeleteDocumentClick = () => {
    clearForm();
  };

  const inputId = getUniqueInputId("document-upload");

  const checkSendDocumentState = (...states: SendDocumentStates[]) =>
    !!states.find((state) => state === sendDocumentState);

  return (
    <div className="document-upload">
      <form>
        {!!fileName && (
          <div
            className={clsx("the-document", {
              "has-error": uploadState === "errorWithDocument",
              "successfully-uploaded": uploadState === "documentUploaded",
            })}
          >
            <TextSnippetIcon className="text-snippet-icon" />
            <div className="file-name">{fileName}</div>
            {uploadState !== ("documentUploaded" && "errorWithDocument") && (
              <IconButton
                className="document-upload-delete"
                ariaLabel={"Delete Document"}
                onClick={handleDeleteDocumentClick}
                size="large"
                data-testid="delete-document"
              >
                <DeleteIcon />
              </IconButton>
            )}
            {uploadState === "documentUploaded" &&
              sendDocumentState === SendDocumentStates.Success && (
                <>
                  <span className="uploaded">Uploaded</span>
                  <FileDownloadDoneIcon />
                </>
              )}
          </div>
        )}
        <div className="errors" aria-live="assertive" role="alert">
          {uploadState === "errorWithDocument" &&
            errorMessages.map((errorMsg, index) => (
              <p key={index}>{errorMsg}</p>
            ))}
        </div>
        <div className="buttons">
          <div
            className={clsx("upload-label", {
              "do-not-show": !(
                uploadState === "selectDocument" ||
                uploadState === "errorWithDocument"
              ),
            })}
          >
            <label htmlFor={inputId} className="upload-document-label-button">
              <input
                ref={uploadInput}
                id={inputId}
                type="file"
                onChange={validateDocument}
                className="actual-document-input"
                data-testid="document-upload-input"
              />
              <span className="decoration">{uploadDocumentButtonText}</span>
            </label>
          </div>
          {uploadState === "okayToUpload" && (
            <SubmitButton
              className="decorated-button upload-document"
              onClick={handleUploadDocumentClick}
              variant="outlined"
              disableRipple={true}
              submitting={checkSendDocumentState(
                SendDocumentStates.Initialising
              )}
            >
              <span className="decoration">Upload Document</span>
            </SubmitButton>
          )}
        </div>
      </form>
    </div>
  );
};
