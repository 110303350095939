import { WelcomePageDefinition } from "./WelcomePageTypes";

export const template: WelcomePageDefinition = {
  confirmationForm: {
    type: "group",
    content: {
      fields: {
        type: "group",
        content: {
          confirmRead: {
            type: "boolean",
          },
          confirmNoQuestions: {
            type: "boolean",
          },
        },
      },
    },
  },
  loginForm: {
    type: "group",
    content: {
      fields: {
        type: "group",
        content: {
          id: {
            type: "string",
          },
        },
      },
    },
  },
};
