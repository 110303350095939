import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCaseApi } from "skipton-common";
import { withSessionId } from "../../../utils/AuthorisationUtils";
import { CFFCustomData } from "skipton-common/lib/api/utils/case";

export const getCFFCustomData = createAsyncThunk(
  "get-cff-custom-data",
  async (data: {
    baseUrl: string;
  }): Promise<Partial<CFFCustomData["CFF"]> | null> => {
    const { baseUrl } = data;

    const caseApi = withSessionId(getCaseApi(baseUrl));

    const response = await caseApi.getCFFCustomData();

    return response?.body?.CFF || null;
  }
);

export const getCFFCustomDataSuccess = getCFFCustomData.fulfilled;
export const getCFFCustomDataPending = getCFFCustomData.pending;
export const getCFFCustomDataFailed = getCFFCustomData.rejected;
