import { combineReducers } from "redux";

import configurationReducer from "../../feature/configuration/ConfigurationSlice";
import authenticationReducer from "../../feature/authentication/AuthenticationSlice";
import ourAdviceStepReducer from "../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepSlice";
import aboutYouStepReducer from "../../feature/journeySteps/steps/aboutYouStep/AboutYouStepSlice";
import yourFeelingsStepReducer from "../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepSlice";
import financialAdvicePageReducer from "../../feature/financialAdvicePage/FinancialAdvicePageSlice";
import videoIntegrationReducer from "../../feature/videoIntegration/VideoIntegrationSlice";
import customDataReducer from "../../feature/customData/CustomDataSlice";
import yourMoneyStepReducer from "../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepSlice";

export default combineReducers({
  customData: customDataReducer,
  authentication: authenticationReducer,
  configuration: configurationReducer,
  ourAdvice: ourAdviceStepReducer,
  aboutYou: aboutYouStepReducer,
  financialAdvicePage: financialAdvicePageReducer,
  videoIntegration: videoIntegrationReducer,
  yourFeelings: yourFeelingsStepReducer,
  yourMoney: yourMoneyStepReducer,
});
