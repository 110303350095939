import React, { FC, useCallback, useEffect, useState } from "react";
import "./YourMoneyStep.scss";
import { Layout } from "../../../layout/Layout";
import {
  ProvisionFormState,
  SendProvisionStates,
  YourMoneyStepDefinition,
  YourMoneyStepErrors,
  YourMoneyStepState,
  YourMoneyStepTouched,
} from "../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";
import { useAppDispatch } from "../../../../redux/store";
import {
  Button,
  FormContextProvider,
  FormsConfiguration,
  useFormContainer,
  useFormsConfiguration,
  useLocalization,
} from "@saturn-ui/components";
import { useSelector } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { useHistory } from "react-router-dom";
import {
  getAboutYouRoute,
  getErrorRoute,
  getYourFeelingsRoute,
} from "../../../../helpers";
import "../../JourneySteps.scss";
import { AccordionGroup } from "../../accordionGroup/AccordionGroup";
import { PROVISION_SCOPE_NAME } from "../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepConstants";
import { setProvisions } from "../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepSlice";
import { defaultProvision } from "../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepInitialState";
import { validate as validateProvision } from "../../../../feature/journeySteps/steps/yourMoneyStep/provision/ProvisionValidation";
import {
  PersistableSections,
  useSaveAndResumeLogic,
} from "../../../../hooks/useSaveAndResumeLogic";
import {
  HTTP_UNAUTHORIZED_STATUS_CODE,
  HttpRequestError,
} from "skipton-common";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../../../helpers/adobeAnalyticsHelpers";
import { RootState, useRootSelector } from "skipton-features";
import { CLIENT_CATEGORY_JOINT } from "../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";

type Props = {
  definition: YourMoneyStepDefinition;
};

export const YourMoneyStep: FC<Props> = ({ definition }) => {
  const {
    financialAdvicePage: {
      clientInfo: { category },
    },
    yourMoney: { provisions, activeProvisionIndex, navigationDisabled },
  } = useSelector((state: AppState) => state);
  const { isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const { commitStepStarted, commitStepCompleted } = useSaveAndResumeLogic(
    PersistableSections.YourMoney
  );

  const history = useHistory() as unknown[];
  const dispatch = useAppDispatch();

  const initialisePage = async () => {
    await commitStepStarted();
  };

  useEffect(() => {
    void initialisePage();
  }, []);

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = getVirtualPageCustomerType(category);

      trackVirtualPageView({
        pageName: "Your money",
        customerType,
      });
    }
  }, [isAdobeAnalyticsLoaded]);

  const localization = useLocalization();
  const { validation } = useFormsConfiguration<FormsConfiguration>();
  const [sendProvisionState, setSendProvisionState] = useState(
    SendProvisionStates.Initialising
  );

  const formContainer = useFormContainer<
    YourMoneyStepState,
    YourMoneyStepErrors,
    YourMoneyStepTouched
  >({
    initialValues: {
      [PROVISION_SCOPE_NAME]:
        (provisions[activeProvisionIndex]?.values as ProvisionFormState) ??
        defaultProvision,
    },
    validateFunc: (provisionValues) =>
      validateProvision(
        provisionValues,
        definition.provisionForm.content,
        validation,
        localization,
        category
      ),
    enableReinitialize: true,
  });

  const { values } = formContainer;

  const handleBackClick = useCallback(
    (yourMoneyStepValues: YourMoneyStepState) => {
      history.push(getAboutYouRoute());
      dispatch(
        setProvisions(
          provisions.map((item, index) => {
            if (index === activeProvisionIndex) {
              return {
                ...item,
                values: yourMoneyStepValues[PROVISION_SCOPE_NAME],
              };
            }

            return item;
          })
        )
      );
    },
    [dispatch, history, provisions, activeProvisionIndex]
  );

  const handlePressContinue = async (): Promise<void> => {
    try {
      setSendProvisionState(SendProvisionStates.Initialising);

      await commitStepCompleted();

      setSendProvisionState(SendProvisionStates.Success);
      history.push(getYourFeelingsRoute());
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code },
        });
      }
      setSendProvisionState(SendProvisionStates.Error);
    }
  };

  const isContinueButtonShown = !!(
    provisions.length &&
    !provisions.find((element) => !element.isSaved || !element.canContinue)
  );

  return (
    <Layout
      backButtonText="Back"
      backButtonDisabled={navigationDisabled}
      onBackPress={() => handleBackClick(values)}
      alwaysHavePadding={true}
      progressStepperProps={{
        numberOfSteps: 4,
        active: 3,
        maxStepDone: 2,
      }}
    >
      <div className="journey-step your-money-step full-width">
        <div className="journey-step-bg">
          <div className="content-wrapper">
            <div className="before-holdings">
              <div className="step-pagination" aria-hidden="true">
                Step 3 of 4
              </div>
              <h1 className="step-title">Your Money</h1>
              <p>
                This is the part where we need you to upload and share details
                of your current savings, investments and pensions. This is so
                your adviser can review them before your meeting, and consider
                how they are supporting your financial goals.
              </p>
              {category === CLIENT_CATEGORY_JOINT && (
                <p className="bold">
                  Just like the last step, we need every person attending the
                  financial advice meeting to share details of their plans.
                </p>
              )}
              <p>
                The more detail you can share the better. But at the very least,
                please check that the files you upload feature the name of your
                provider, the policy number and the amount of money you have
                with them.
              </p>
            </div>
            <div className="holdings">
              <FormContextProvider value={formContainer}>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete="off"
                  noValidate
                >
                  <AccordionGroup
                    captions={{
                      headerTitle: "Holding",
                      addButtonTitle: "I have another holding to share",
                      saveButtonTitle: "Save & Proceed",
                    }}
                    items={provisions}
                    setItems={setProvisions}
                    defaultItem={defaultProvision}
                  />
                </form>
              </FormContextProvider>
            </div>

            {sendProvisionState === SendProvisionStates.Error && (
              <div className="error-message your-money-error">
                Something went wrong. Please try again.
              </div>
            )}

            {isContinueButtonShown && (
              <div className="continue">
                <Button
                  className="decorated-button welcome-page-start-button"
                  onClick={handlePressContinue}
                  variant="contained"
                  disabled={navigationDisabled}
                  id="welcome-page-start-button"
                  disableRipple={true}
                >
                  <span className="decoration">I am ready to continue</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
