import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const productTypePensionsList: List = {
  listType: "productTypePensionsList",
  lang: "en-GB",
  items: [
    {
      id: "GRPPEN",
      displayText: "Group Scheme",
    },
    {
      id: "PERPEN",
      displayText: "Personal Scheme",
    },
  ],
};
