import { createSlice } from "@reduxjs/toolkit";
import { AuthenticationState } from "./AuthenticationTypes";
import {
  loginFailed,
  loginPending,
  loginSuccess,
  logoutFailed,
  logoutPending,
  logoutSuccess,
  refreshFailed,
  refreshPending,
  refreshSuccess,
} from "./thunks";

export const defaultAuthenticationState: AuthenticationState = {
  sessionId: null,
  securityId: null,

  loginPending: false,
  loginFailed: false,

  logoutPending: false,
  logoutFailed: false,

  refreshPending: false,
  refreshFailed: false,
};

const configurationSettingsSlice = createSlice({
  name: "authentication",
  initialState: defaultAuthenticationState,
  reducers: {
    resetUserData: (state) => {
      state.sessionId = null;
      state.securityId = null;
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(loginPending, (state) => ({
      ...state,
      loginPending: true,
      refreshFailed: false,
    }));
    builder.addCase(loginFailed, (state) => ({
      ...state,
      loginPending: false,
      loginFailed: true,
    }));
    builder.addCase(loginSuccess, (state, { payload }) => ({
      ...state,
      sessionId: payload.sessionId,
      securityId: payload.securityId,
      loginPending: false,
      loginFailed: false,
    }));
    // Logout
    builder.addCase(logoutPending, (state) => ({
      ...state,
      logoutPending: true,
      refreshFailed: false,
    }));
    builder.addCase(logoutFailed, (state) => ({
      ...state,
      sessionId: null, // clean user data anyway
      securityId: null, // clean user data anyway
      logoutPending: false,
      logoutFailed: true,
    }));
    builder.addCase(logoutSuccess, (state) => ({
      ...state,
      sessionId: null,
      securityId: null,
      logoutPending: false,
      logoutFailed: false,
    }));
    // Refresh
    builder.addCase(refreshPending, (state) => ({
      ...state,
      refreshPending: true,
    }));
    builder.addCase(refreshFailed, (state) => ({
      ...state,
      refreshPending: false,
      refreshFailed: true,
    }));
    builder.addCase(refreshSuccess, (state, { payload }) => ({
      ...state,
      securityId: payload.securityId,
      refreshPending: false,
      refreshFailed: false,
    }));
  },
});

export const { resetUserData } = configurationSettingsSlice.actions;
export default configurationSettingsSlice.reducer;
