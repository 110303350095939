import React, { FC, useCallback } from "react";
import {
  BooleanField,
  Button,
  createFormScope,
  FieldRenderer,
  useFormContext,
} from "@saturn-ui/components";
import {
  ConfirmationFormDefinition,
  ConfirmationFormError,
  ConfirmationFormState,
  ConfirmationFormTouched,
} from "../../feature/welcomePage/WelcomePageTypes";
import { focusOnInputById, getFirstErrorKey } from "../../utils/formUtils";
import { confirmationFormFieldKeys } from "../../feature/welcomePage/WelcomePageConstants";

type Props = {
  onPressStart: () => void;
  definition: ConfirmationFormDefinition;
};

export const ConfirmationFormContent: FC<Props> = ({
  onPressStart,
  definition,
}) => {
  const formContext = useFormContext<
    ConfirmationFormState,
    ConfirmationFormError,
    ConfirmationFormTouched
  >();
  const {
    errors,
    handleFieldUpdate,
    values,
    validateForm,
    isValid,
    setFieldTouched,
    touched,
  } = formContext;

  const confirmFormScope = createFormScope({
    name: "",
    values,
    errors,
    touched,
    readOnly: false,
    disabled: false,
  });

  const handlePressStart = useCallback(() => {
    validateForm();
    setFieldTouched("confirmRead");
    setFieldTouched("confirmNoQuestions");
    if (isValid) {
      onPressStart();
    } else {
      const firstErrorKey = getFirstErrorKey(errors, confirmationFormFieldKeys);
      if (firstErrorKey) {
        focusOnInputById(firstErrorKey, setFieldTouched);
      }
    }
  }, [
    validateForm,
    setFieldTouched,
    isValid,
    onPressStart,
    errors,
    errors.confirmNoQuestions,
    errors.confirmRead,
    getFirstErrorKey,
    focusOnInputById,
  ]);

  return (
    <div data-testid="confirmation-form">
      <h2 className="welcome-page-label-important no-top-margin">
        Ready to start?
      </h2>
      <FieldRenderer
        name="confirmRead"
        definition={definition.fields.content.confirmRead}
        scope={confirmFormScope}
        render={(fieldProps) => (
          <BooleanField
            {...fieldProps}
            labelPosition="right"
            value={values.confirmRead}
            onUpdate={handleFieldUpdate}
            errorMessage={fieldProps.errorMessage}
          />
        )}
      />
      <FieldRenderer
        name="confirmNoQuestions"
        definition={definition.fields.content.confirmNoQuestions}
        scope={confirmFormScope}
        render={(fieldProps) => (
          <BooleanField
            {...fieldProps}
            labelPosition="right"
            value={values.confirmNoQuestions}
            onUpdate={handleFieldUpdate}
            errorMessage={fieldProps.errorMessage}
          />
        )}
      />
      <Button
        className="decorated-button welcome-page-start-button"
        onClick={handlePressStart}
        variant="contained"
        id="welcome-page-start-button"
        disableRipple={true}
      >
        <span className="decoration">I am ready to continue</span>
      </Button>
    </div>
  );
};

export default ConfirmationFormContent;
