import React, { FC } from "react";
import { PropertyFormState } from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import {
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD,
} from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepConstants";
import { useLocalization } from "@saturn-ui/components";
import { formatCurrencyValueToView } from "../../AboutYouStep.utils";
import "./YourPropertySummaryComponent.scss";

type Props = {
  values: PropertyFormState;
};

export const YourPropertySummaryComponent: FC<Props> = ({ values }) => {
  const localization = useLocalization();

  return (
    <div className="your-property-summary-component">
      {values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD] && (
        <div className="summary-value">
          {values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]?.displayText}
        </div>
      )}
      {values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD] && (
        <div className="summary-value">
          Estimated value
          <span className="summary-value-important">
            {formatCurrencyValueToView(
              localization,
              values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]
            )}
          </span>
        </div>
      )}
    </div>
  );
};
