import React, { FC, useCallback, useEffect, useState } from "react";
import {
  CLIENT_CATEGORY_JOINT,
  createFormScope,
  CurrencyField,
  FieldDef,
  FieldRenderer,
  FieldUpdateEventArgs,
  ListField,
  ListItem,
  RadioGroupInput,
  SubmitButton,
  TextField,
  useFormContext,
  useLocalization,
} from "@saturn-ui/components";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../types/AppState";
import { setActiveProvisionIndex } from "../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepSlice";
import { useAppDispatch } from "../../../../../../redux/store";
import {
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_FORM_KNOW_POLICY_REF_NUM,
  PROVISION_FORM_POLICY_REFERENCE_NUMBER,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_FORM_WHO_BELONG_TO,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
  PROVISION_ID,
  PROVISION_SCOPE_NAME,
  provisionFormFieldKeys,
} from "../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepConstants";
import {
  SendProvisionStates,
  YourMoneyStepErrors,
  YourMoneyStepState,
  YourMoneyStepTouched,
} from "../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";
import {
  holdingTypeList,
  isReferenceNumberKnownList,
  productTypeInvestmentsList,
  productTypePensionsList,
  productTypeSavingsList,
  providerNameList,
} from "../../yourMoneyStepLists";
import {
  excludeEachISAFromList,
  getWhoBelongToList,
  handleHoldingTypeFieldChange,
  handleIsPolicyReferenceNumberKnownFieldChange,
  handleWhoBelongToFieldChange,
  handleWhoIsItHeldWithFieldChange,
} from "../../YourMoneyStep.utils";
import "./ProvisionDetailsComponent.scss";
import { postProvision } from "../../../../../../feature/journeySteps/steps/yourMoneyStep/thunks/PostProvision";
import {
  HTTP_UNAUTHORIZED_STATUS_CODE,
  HttpRequestError,
} from "skipton-common";
import { getErrorRoute } from "../../../../../../helpers";
import { RootState, useRootSelector } from "skipton-features";
import { useHistory } from "react-router-dom";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
} from "../../../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import { unwrapResult } from "@reduxjs/toolkit";
import clsx from "clsx";
import {
  focusOnInputById,
  getFirstErrorKey,
} from "../../../../../../utils/formUtils";

type Props = {
  index: number;
  handleSaveItem: (
    values: YourMoneyStepState,
    collapseSavedItem?: boolean
  ) => void;
  saveButtonTitle: string;
};

export const ProvisionDetailsComponent: FC<Props> = ({
  index: selectedIndex,
  handleSaveItem,
  saveButtonTitle,
}) => {
  const { APIUri } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const {
    financialAdvicePage: {
      clientInfo: {
        salutation: {
          [CLIENT_1_SCOPE_NAME]: client1Salutation,
          [CLIENT_2_SCOPE_NAME]: client2Salutation,
        },
        category,
      },
    },
  } = useSelector((state: AppState) => state);

  const definition = useSelector(
    (state: AppState) =>
      state.configuration.data.definitions.journeySteps.steps.content.yourMoney
        .content.provisionForm.content.fields.content
  );
  const dispatch = useAppDispatch();
  const history = useHistory() as unknown[];
  const localization = useLocalization();

  const [sendProvisionState, setSendProvisionState] = useState(
    SendProvisionStates.Uninitialised
  );

  const formContext = useFormContext<
    YourMoneyStepState,
    YourMoneyStepErrors,
    YourMoneyStepTouched
  >();

  const {
    touched,
    errors,
    values,
    handleFieldUpdate,
    setFieldTouched,
    isValid,
    validateForm,
    resetForm,
    updateState,
  } = formContext;

  const provisionScope = createFormScope({
    name: PROVISION_SCOPE_NAME,
    values,
    errors,
    touched,
    readOnly: false,
    disabled: false,
  });

  const getDefaultProductTypeOptions = (): ListItem[] => {
    const holdingTypeId =
      values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING]?.id || "";
    if (holdingTypeId === "Investment") {
      return productTypeInvestmentsList.items;
    } else if (holdingTypeId === "Saving") {
      return productTypeSavingsList.items;
    } else if (holdingTypeId === "Pension") {
      return productTypePensionsList.items;
    } else {
      return [];
    }
  };

  const [productTypeOptions, setProductTypeOptions] = useState<ListItem[]>(
    getDefaultProductTypeOptions()
  );

  useEffect(() => {
    dispatch(setActiveProvisionIndex(selectedIndex));
    resetForm();
  }, []);

  const getWhoBelongToListItems = useCallback((): ListItem[] => {
    const holdingType =
      values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING]?.id;
    if (!holdingType || category !== CLIENT_CATEGORY_JOINT) {
      return [];
    } else {
      return getWhoBelongToList(
        client1Salutation?.forename,
        client2Salutation?.forename,
        holdingType !== "Pension"
      ).items;
    }
  }, [category, values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING]]);

  const saveProvision = async () => {
    try {
      setSendProvisionState(SendProvisionStates.Initialising);

      const response = await dispatch(
        postProvision({
          baseUrl: APIUri ?? "",
          provision: values[PROVISION_SCOPE_NAME],
        })
      );

      const { id } = unwrapResult(response);
      const collapseSavedItem =
        values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING]?.id ===
        "Saving";

      handleSaveItem(
        {
          ...values,
          [PROVISION_SCOPE_NAME]: {
            ...values[PROVISION_SCOPE_NAME],
            [PROVISION_ID]: id,
          },
        },
        collapseSavedItem
      );

      // only set new active index if we collapsed the saved item
      if (collapseSavedItem) {
        dispatch(setActiveProvisionIndex(selectedIndex + 1));
      }

      setSendProvisionState(SendProvisionStates.Success);
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setSendProvisionState(SendProvisionStates.Error);
    }
  };

  const onSaveClick = async () => {
    validateForm();
    setFieldTouched(
      `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_HOW_MUCH_IS_IT_WORTH}`
    );
    setFieldTouched(
      `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_KNOW_POLICY_REF_NUM}`
    );
    setFieldTouched(`${PROVISION_SCOPE_NAME}.${PROVISION_FORM_PRODUCT_TYPE}`);
    setFieldTouched(
      `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_TYPE_OF_HOLDING}`
    );
    setFieldTouched(
      `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_WHO_IS_IT_HELD_WITH}`
    );
    setFieldTouched(
      `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER}`
    );
    setFieldTouched(`${PROVISION_SCOPE_NAME}.${PROVISION_FORM_WHO_BELONG_TO}`);
    setFieldTouched(
      `${PROVISION_SCOPE_NAME}.${PROVISION_FORM_POLICY_REFERENCE_NUMBER}`
    );

    if (isValid) {
      await saveProvision();
    } else {
      const firstErrorKey = getFirstErrorKey(
        errors[PROVISION_SCOPE_NAME],
        provisionFormFieldKeys
      );
      if (firstErrorKey) {
        focusOnInputById(
          `${PROVISION_SCOPE_NAME}-${firstErrorKey}`,
          setFieldTouched
        );
      }
    }
  };

  const handleIsPolicyReferenceNumberKnownFieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<ListItem>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      handleIsPolicyReferenceNumberKnownFieldChange(
        updateState,
        setFieldTouched
      );
    },
    [handleFieldUpdate, setFieldTouched, updateState, category]
  );

  const handleWhoIsItHeldWithFieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<ListItem>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      handleWhoIsItHeldWithFieldChange(updateState, setFieldTouched);
    },
    [handleFieldUpdate, setFieldTouched, updateState, category]
  );

  const handleHoldingTypeFieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<ListItem>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      handleHoldingTypeFieldChange(updateState, setFieldTouched);

      if (args.value) {
        if (args.value.id === "Investment") {
          setProductTypeOptions(productTypeInvestmentsList.items);
        } else if (args.value.id === "Saving") {
          setProductTypeOptions(productTypeSavingsList.items);
        } else if (args.value.id === "Pension") {
          setProductTypeOptions(productTypePensionsList.items);
        }
      }
    },
    [handleFieldUpdate, setFieldTouched, updateState, category]
  );

  const handleWhoBelongToFieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<ListItem>) => {
      const previousValue =
        values[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_BELONG_TO]?.id || "";

      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      if (args.value) {
        const needToChangeProductType =
          previousValue === "" ||
          (previousValue.startsWith("Party") && args.value.id === "J") ||
          (previousValue === "J" && args.value.id.startsWith("Party"));
        if (!needToChangeProductType) {
          return;
        }

        handleWhoBelongToFieldChange(updateState, setFieldTouched);

        const holdingTypeId =
          values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING]?.id ||
          "";
        if (args.value.id !== "J" && holdingTypeId === "Investment") {
          setProductTypeOptions(productTypeInvestmentsList.items);
        } else if (args.value.id === "J" && holdingTypeId === "Investment") {
          setProductTypeOptions(
            excludeEachISAFromList(productTypeInvestmentsList.items)
          );
        } else if (args.value.id !== "J" && holdingTypeId === "Saving") {
          setProductTypeOptions(productTypeSavingsList.items);
        } else if (args.value.id === "J" && holdingTypeId === "Saving") {
          setProductTypeOptions(
            excludeEachISAFromList(productTypeSavingsList.items)
          );
        } else if (holdingTypeId === "Pension") {
          setProductTypeOptions(productTypePensionsList.items);
        }
      }
    },
    [
      handleFieldUpdate,
      setFieldTouched,
      updateState,
      category,
      values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING],
      values[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_BELONG_TO],
    ]
  );

  // adds the `num` number before the caption if it is not joint case, and adds
  // `num +1` number if it is joint case, so the numbers will be as on design
  const udpateFieldDefCaptionWithNumber = (
    definition: FieldDef,
    num: number
  ): FieldDef => {
    const numberToAdd = category === CLIENT_CATEGORY_JOINT ? num + 1 : num;
    return {
      ...definition,
      caption: `${numberToAdd}. ${definition.caption || ""}`,
    };
  };

  const renderProductTypeInput = () => (
    <li>
      <FieldRenderer
        name={PROVISION_FORM_PRODUCT_TYPE}
        definition={udpateFieldDefCaptionWithNumber(
          definition[PROVISION_FORM_PRODUCT_TYPE],
          3
        )}
        scope={provisionScope}
        disabledWhen={
          !values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING]
        }
        render={(fieldProps) => (
          <ListField
            {...fieldProps}
            isLarge
            options={productTypeOptions || []}
            value={values[PROVISION_SCOPE_NAME][PROVISION_FORM_PRODUCT_TYPE]}
            onUpdate={handleFieldUpdate}
          />
        )}
      />
    </li>
  );

  // TODO: handle this form with proper scope so additional css/error handling not needed
  const hasErrortypeOfHolding =
    errors &&
    errors[PROVISION_SCOPE_NAME] &&
    errors[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING] &&
    touched &&
    touched[PROVISION_SCOPE_NAME] &&
    touched[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING];

  const hasErrorWhoBelongTo =
    errors &&
    errors[PROVISION_SCOPE_NAME] &&
    errors[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_BELONG_TO] &&
    touched &&
    touched[PROVISION_SCOPE_NAME] &&
    touched[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_BELONG_TO];

  const hasErrorWhoIsItHeldWith =
    errors &&
    errors[PROVISION_SCOPE_NAME] &&
    errors[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH] &&
    touched &&
    touched[PROVISION_SCOPE_NAME] &&
    touched[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH];

  const hasErrorWhoIsItHeldWithOther =
    errors &&
    errors[PROVISION_SCOPE_NAME] &&
    errors[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER] &&
    touched &&
    touched[PROVISION_SCOPE_NAME] &&
    touched[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER];

  const hasErrorPolicyReferenceNumber =
    errors &&
    errors[PROVISION_SCOPE_NAME] &&
    errors[PROVISION_SCOPE_NAME][PROVISION_FORM_POLICY_REFERENCE_NUMBER] &&
    touched &&
    touched[PROVISION_SCOPE_NAME] &&
    touched[PROVISION_SCOPE_NAME][PROVISION_FORM_POLICY_REFERENCE_NUMBER];

  const hasErrorHowMuchIsItWorth =
    errors &&
    errors[PROVISION_SCOPE_NAME] &&
    errors[PROVISION_SCOPE_NAME][PROVISION_FORM_HOW_MUCH_IS_IT_WORTH] &&
    touched &&
    touched[PROVISION_SCOPE_NAME] &&
    touched[PROVISION_SCOPE_NAME][PROVISION_FORM_HOW_MUCH_IS_IT_WORTH];

  const hasErrorKnowPolicyReferenceNumber =
    errors &&
    errors[PROVISION_SCOPE_NAME] &&
    errors[PROVISION_SCOPE_NAME][PROVISION_FORM_KNOW_POLICY_REF_NUM] &&
    touched &&
    touched[PROVISION_SCOPE_NAME] &&
    touched[PROVISION_SCOPE_NAME][PROVISION_FORM_KNOW_POLICY_REF_NUM];

  return (
    <div className="provision-details-component">
      <div className="provision-details-form">
        <div className="column">
          <ol className="holding-form">
            <li>
              <fieldset
                id={`${PROVISION_SCOPE_NAME}-${PROVISION_FORM_TYPE_OF_HOLDING}`}
              >
                <FieldRenderer
                  name={PROVISION_FORM_TYPE_OF_HOLDING}
                  definition={definition[PROVISION_FORM_TYPE_OF_HOLDING]}
                  scope={provisionScope}
                  render={(fieldProps) => (
                    <div className="radio-group-wrapper">
                      <span className="radio-group-label">
                        {definition[PROVISION_FORM_TYPE_OF_HOLDING].caption}
                      </span>
                      {hasErrortypeOfHolding && (
                        <div className="error-message">
                          Please answer this question
                        </div>
                      )}
                      <RadioGroupInput
                        {...fieldProps}
                        isVertical
                        isLarge
                        value={
                          values[PROVISION_SCOPE_NAME][
                            PROVISION_FORM_TYPE_OF_HOLDING
                          ]
                        }
                        options={holdingTypeList.items || []}
                        onUpdate={handleHoldingTypeFieldUpdate}
                      />
                    </div>
                  )}
                />
              </fieldset>
            </li>

            {category === CLIENT_CATEGORY_JOINT && (
              <li
                className={clsx("non-radio", {
                  "has-error": hasErrorWhoBelongTo,
                })}
              >
                <FieldRenderer
                  name={PROVISION_FORM_WHO_BELONG_TO}
                  definition={definition[PROVISION_FORM_WHO_BELONG_TO]}
                  scope={provisionScope}
                  disabledWhen={
                    !values[PROVISION_SCOPE_NAME][
                      PROVISION_FORM_TYPE_OF_HOLDING
                    ]
                  }
                  render={(fieldProps) => (
                    <>
                      <ListField
                        {...fieldProps}
                        isLarge
                        options={getWhoBelongToListItems()}
                        value={
                          values[PROVISION_SCOPE_NAME][
                            PROVISION_FORM_WHO_BELONG_TO
                          ]
                        }
                        onUpdate={handleWhoBelongToFieldUpdate}
                      />
                      {hasErrorWhoBelongTo && (
                        <div className="error-message">
                          Please answer this question
                        </div>
                      )}
                    </>
                  )}
                />
              </li>
            )}

            <li className="non-radio">
              <div
                className={clsx({
                  "has-error": hasErrorWhoIsItHeldWith,
                })}
              >
                <FieldRenderer
                  name={PROVISION_FORM_WHO_IS_IT_HELD_WITH}
                  definition={udpateFieldDefCaptionWithNumber(
                    definition[PROVISION_FORM_WHO_IS_IT_HELD_WITH],
                    2
                  )}
                  scope={provisionScope}
                  render={(fieldProps) => (
                    <>
                      <ListField
                        {...fieldProps}
                        isLarge
                        options={providerNameList.items || []}
                        value={
                          values[PROVISION_SCOPE_NAME][
                            PROVISION_FORM_WHO_IS_IT_HELD_WITH
                          ]
                        }
                        onUpdate={handleWhoIsItHeldWithFieldUpdate}
                      />
                      {hasErrorWhoIsItHeldWith && (
                        <div className="error-message">
                          Please answer this question
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              {values[PROVISION_SCOPE_NAME][
                PROVISION_FORM_WHO_IS_IT_HELD_WITH
              ] &&
                values[PROVISION_SCOPE_NAME][PROVISION_FORM_WHO_IS_IT_HELD_WITH]
                  .id === "Other" && (
                  <div
                    className={clsx({
                      "has-error": hasErrorWhoIsItHeldWithOther,
                    })}
                  >
                    <FieldRenderer
                      name={PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER}
                      definition={
                        definition[PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER]
                      }
                      scope={provisionScope}
                      render={(fieldProps) => (
                        <>
                          <TextField
                            {...fieldProps}
                            isLarge
                            value={
                              values[PROVISION_SCOPE_NAME][
                                PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER
                              ]
                            }
                            onUpdate={handleFieldUpdate}
                          />
                          {hasErrorWhoIsItHeldWithOther && (
                            <div className="error-message">
                              Please answer this question
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                )}
            </li>
            {category !== CLIENT_CATEGORY_JOINT && renderProductTypeInput()}
          </ol>
        </div>
        <div className="column">
          <ol className="holding-form">
            {category === CLIENT_CATEGORY_JOINT && renderProductTypeInput()}
            <li>
              <fieldset
                id={`${PROVISION_SCOPE_NAME}-${PROVISION_FORM_KNOW_POLICY_REF_NUM}`}
              >
                <FieldRenderer
                  name={PROVISION_FORM_KNOW_POLICY_REF_NUM}
                  definition={definition[PROVISION_FORM_KNOW_POLICY_REF_NUM]}
                  scope={provisionScope}
                  render={(fieldProps) => (
                    <div className="radio-group-wrapper">
                      <span className="radio-group-label">
                        {
                          udpateFieldDefCaptionWithNumber(
                            definition[PROVISION_FORM_KNOW_POLICY_REF_NUM],
                            4
                          ).caption
                        }
                      </span>
                      {hasErrorKnowPolicyReferenceNumber && (
                        <div className="error-message">
                          Please answer this question
                        </div>
                      )}
                      <RadioGroupInput
                        {...fieldProps}
                        isVertical
                        isLarge
                        value={
                          values[PROVISION_SCOPE_NAME][
                            PROVISION_FORM_KNOW_POLICY_REF_NUM
                          ]
                        }
                        options={isReferenceNumberKnownList.items || []}
                        onUpdate={handleIsPolicyReferenceNumberKnownFieldUpdate}
                      />
                    </div>
                  )}
                />

                {values[PROVISION_SCOPE_NAME][
                  PROVISION_FORM_KNOW_POLICY_REF_NUM
                ] &&
                  values[PROVISION_SCOPE_NAME][
                    PROVISION_FORM_KNOW_POLICY_REF_NUM
                  ].id === "Yes" && (
                    <div
                      className={clsx("text-field-wrapper", {
                        "has-error": hasErrorPolicyReferenceNumber,
                      })}
                    >
                      <FieldRenderer
                        name={PROVISION_FORM_POLICY_REFERENCE_NUMBER}
                        definition={
                          definition[PROVISION_FORM_POLICY_REFERENCE_NUMBER]
                        }
                        scope={provisionScope}
                        render={(fieldProps) => (
                          <>
                            <TextField
                              {...fieldProps}
                              isLarge
                              value={
                                values[PROVISION_SCOPE_NAME][
                                  PROVISION_FORM_POLICY_REFERENCE_NUMBER
                                ]
                              }
                              onUpdate={handleFieldUpdate}
                            />
                            {hasErrorPolicyReferenceNumber && (
                              <div className="error-message">
                                Please answer this question
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  )}
              </fieldset>
            </li>
            <li
              className={clsx("non-radio", {
                "has-error": hasErrorHowMuchIsItWorth,
              })}
            >
              <FieldRenderer
                name={PROVISION_FORM_HOW_MUCH_IS_IT_WORTH}
                definition={udpateFieldDefCaptionWithNumber(
                  definition[PROVISION_FORM_HOW_MUCH_IS_IT_WORTH],
                  5
                )}
                scope={provisionScope}
                render={(fieldProps) => (
                  <>
                    <CurrencyField
                      {...fieldProps}
                      formatterName="Default"
                      currencyCode={localization.defaultCurrencyCode}
                      min={0}
                      isLarge
                      value={
                        values[PROVISION_SCOPE_NAME][
                          PROVISION_FORM_HOW_MUCH_IS_IT_WORTH
                        ]
                      }
                      onUpdate={handleFieldUpdate}
                    />
                    {hasErrorHowMuchIsItWorth && (
                      <div className="error-message">
                        Please answer this question
                      </div>
                    )}
                  </>
                )}
              />
            </li>
          </ol>
        </div>
      </div>
      <div
        className={clsx("provision-details-component-actions", {
          error: sendProvisionState === SendProvisionStates.Error,
        })}
      >
        {sendProvisionState === SendProvisionStates.Error && (
          <div className="error-message">
            Something went wrong. Please try again.
          </div>
        )}

        <SubmitButton
          className="submit-button decorated-button"
          onClick={onSaveClick}
          variant="outlined"
          id="save-button"
          disableRipple={true}
          color="primary"
          submitting={sendProvisionState === SendProvisionStates.Initialising}
        >
          <span className="decoration">{saveButtonTitle}</span>
        </SubmitButton>
      </div>
    </div>
  );
};
