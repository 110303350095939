import { useCallback, useRef, useState } from "react";
import { Timer, TimerPayload, TimerState } from "../classes/Timer";

type ResultPayload = {
  timerRef: Timer;
  state?: TimerState;
};

export const useTimer = (payload: TimerPayload): ResultPayload => {
  const [state, setState] = useState<TimerState | undefined>();

  const timerRef = useRef<Timer>(new Timer(payload));

  const handleTimerUpdate = useCallback((newState: TimerState) => {
    setState(newState);
  }, []);

  timerRef.current.setIntervalUpdateCallback(handleTimerUpdate);

  return {
    state,
    timerRef: timerRef.current,
  };
};
