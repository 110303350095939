import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListApi } from "skipton-common";
import { AboutYouStepLists } from "../AboutYouStepTypes";
import { AppState } from "../../../../../types/AppState";
import { mapListToState } from "../../../../list/mappers";
import {
  ABOUT_YOU_STEP_MARITAL_STATUS_LIST,
  ABOUT_YOU_STEP_TAX_JURISDICTION_LIST,
  ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST,
  ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST,
} from "../AboutYouStepConstants";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";

export const initialiseAboutYouStep = createAsyncThunk<
  AboutYouStepLists,
  {
    baseUrl: string;
  },
  { state: AppState }
>(
  "initialise-about-you-step",
  async (
    data: {
      baseUrl: string;
    },
    { getState }
  ): Promise<AboutYouStepLists> => {
    const { baseUrl } = data;
    const {
      aboutYou: { lists },
    } = getState();

    const listApi = withSessionId(getListApi(baseUrl));
    let maritalStatusList = lists[ABOUT_YOU_STEP_MARITAL_STATUS_LIST];
    let taxJurisdictionList = lists[ABOUT_YOU_STEP_TAX_JURISDICTION_LIST];
    const taxStatusRestUkList = lists[ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST];
    const taxStatusScotlandList =
      lists[ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST];

    if (maritalStatusList === null) {
      const maritalStatusResponse = await listApi.getList(
        ABOUT_YOU_STEP_MARITAL_STATUS_LIST
      );

      maritalStatusList = mapListToState(maritalStatusResponse.body);
    }

    if (taxJurisdictionList === null) {
      const taxJurisdictionResponse = await listApi.getList(
        ABOUT_YOU_STEP_TAX_JURISDICTION_LIST
      );

      taxJurisdictionList = mapListToState(taxJurisdictionResponse.body);
    }

    return {
      [ABOUT_YOU_STEP_MARITAL_STATUS_LIST]: maritalStatusList,
      [ABOUT_YOU_STEP_TAX_JURISDICTION_LIST]: taxJurisdictionList,
      [ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST]: taxStatusRestUkList,
      [ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST]: taxStatusScotlandList,
    };
  }
);

export const initialiseAboutYouSuccess = initialiseAboutYouStep.fulfilled;
export const initialiseAboutYouPending = initialiseAboutYouStep.pending;
export const initialiseAboutYouFailed = initialiseAboutYouStep.rejected;
