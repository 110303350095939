import React from "react";
import { Button, Dialog, IconButton } from "@saturn-ui/components";
import { CloseIcon } from "../../../../../assets/icons/CloseIcon";
import "./JointCaseDetailsDialog.scss";

type Props = {
  onCancelClick?: () => void;
  onProceedClick?: () => void;
};
export const JointCaseDetailsDialog: React.FC<Props> = ({
  onCancelClick,
  onProceedClick,
}) => {
  return (
    <Dialog isOpen={true} onClose={onCancelClick} className="auth-dialog">
      <IconButton
        className="dialog-close-button"
        ariaLabel={"Close"}
        onClick={onCancelClick}
      >
        <CloseIcon />
      </IconButton>
      <h2 className="dialog-title">Risk and reward</h2>
      <p>
        This is the part where we need you both to provide your views on risk
        and reward.
      </p>
      <p>
        We will ask you to do this one at a time. The first person will be asked
        to answer a series of questions. When they have finished, it will be the
        other person’s turn to do the same.
      </p>
      <div className="dialog-single-action">
        <Button
          className="decorated-button"
          onClick={onProceedClick}
          variant="contained"
          id="proceed-button"
          disableRipple={true}
        >
          <span className="decoration">Continue</span>
        </Button>
      </div>
    </Dialog>
  );
};
