import {
  FieldValidator,
  Localization,
  ValidationConfiguration,
} from "@saturn-ui/components";
import {
  AboutYouFormDefinition,
  AboutYouFormErrors,
  AboutYouFormState,
  AboutYouStepDefinition,
  AboutYouStepErrors,
  AboutYouStepState,
  YourPropertyFormDefinition,
  YourPropertyFormErrors,
  YourPropertyFormState,
} from "./AboutYouStepTypes";
import {
  ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD,
  ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD,
  ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD,
  ABOUT_YOU_FORM_MARITAL_STATUS_FIELD,
  ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD,
  ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD,
  ABOUT_YOU_FORM_TAX_STATUS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD,
  ABOUT_YOU_SCOPE_NAME,
  YOUR_PROPERTY_SCOPE_NAME,
} from "./AboutYouStepConstants";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
} from "../../../financialAdvicePage/FinancialAdvicePageConstants";
import { ClientCategory } from "../../../financialAdvicePage/FinancialAdvicePageTypes";

export const validate = (
  aboutYouStepValues: AboutYouStepState,
  definition: AboutYouStepDefinition,
  config: ValidationConfiguration,
  localization: Localization,
  clientCategory: ClientCategory
): {
  errors: AboutYouStepErrors;
  isValid: boolean;
} => {
  const errors: AboutYouStepErrors = {
    [ABOUT_YOU_SCOPE_NAME]: {
      [CLIENT_1_SCOPE_NAME]: {},
      [CLIENT_2_SCOPE_NAME]: {},
    },
    [YOUR_PROPERTY_SCOPE_NAME]: {},
  };
  let isValid = true;

  const client1Errors = validateClient(
    aboutYouStepValues[ABOUT_YOU_SCOPE_NAME][CLIENT_1_SCOPE_NAME],
    definition.form.content,
    config,
    localization
  );
  const isClient1Valid = client1Errors.isValid;
  isValid = isValid && isClient1Valid;

  if (Object.keys(client1Errors.errors).length > 0) {
    errors[ABOUT_YOU_SCOPE_NAME][CLIENT_1_SCOPE_NAME] = client1Errors.errors;
  }

  let isClient2Valid;
  if (clientCategory === CLIENT_CATEGORY_JOINT) {
    const client2Errors = validateClient(
      aboutYouStepValues[ABOUT_YOU_SCOPE_NAME][CLIENT_2_SCOPE_NAME],
      definition.form.content,
      config,
      localization
    );
    isClient2Valid = client2Errors.isValid;
    isValid = isValid && isClient2Valid;

    if (Object.keys(client2Errors.errors).length > 0) {
      errors[ABOUT_YOU_SCOPE_NAME][CLIENT_2_SCOPE_NAME] = client2Errors.errors;
    }
  }

  const yourPropertyErrors = validateYourProperty(
    aboutYouStepValues[YOUR_PROPERTY_SCOPE_NAME],
    definition.yourPropertyForm.content,
    config,
    localization
  );
  const isYourPropertyValid = yourPropertyErrors.isValid;
  isValid = isValid && isYourPropertyValid;

  if (Object.keys(yourPropertyErrors.errors).length > 0) {
    errors[YOUR_PROPERTY_SCOPE_NAME] = yourPropertyErrors.errors;
  }

  return {
    errors,
    isValid,
  };
};

const validateClient = (
  aboutYouValues: AboutYouFormState,
  definition: AboutYouFormDefinition,
  config: ValidationConfiguration,
  localization: Localization
): { errors: AboutYouFormErrors; isValid: boolean } => {
  const errors: AboutYouFormErrors = {};

  const fv = new FieldValidator<AboutYouFormErrors>(
    errors,
    config,
    localization
  );

  fv.checkSelect(
    definition.fields.content[ABOUT_YOU_FORM_MARITAL_STATUS_FIELD],
    aboutYouValues[ABOUT_YOU_FORM_MARITAL_STATUS_FIELD],
    ABOUT_YOU_FORM_MARITAL_STATUS_FIELD
  );

  fv.checkSelect(
    definition.fields.content[ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD],
    aboutYouValues[ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD],
    ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD
  );

  fv.checkNumber(
    definition.fields.content[ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD],
    aboutYouValues[ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD],
    ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD
  );

  fv.checkSelect(
    definition.fields.content[ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD],
    aboutYouValues[ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD],
    ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
  );

  fv.checkSelect(
    definition.fields.content[ABOUT_YOU_FORM_TAX_STATUS_FIELD],
    aboutYouValues[ABOUT_YOU_FORM_TAX_STATUS_FIELD],
    ABOUT_YOU_FORM_TAX_STATUS_FIELD
  );

  fv.checkSelect(
    definition.fields.content[ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD],
    aboutYouValues[ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD],
    ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD
  );

  fv.checkText(
    definition.fields.content[ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD],
    aboutYouValues[ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD],
    ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD
  );

  return {
    errors,
    isValid: fv.isValid(),
  };
};

const validateYourProperty = (
  yourPropertyValues: YourPropertyFormState,
  definition: YourPropertyFormDefinition,
  config: ValidationConfiguration,
  localization: Localization
): { errors: YourPropertyFormErrors; isValid: boolean } => {
  const errors: YourPropertyFormErrors = {};

  const fv = new FieldValidator<YourPropertyFormErrors>(
    errors,
    config,
    localization
  );

  fv.checkSelect(
    definition.fields.content[ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD],
    yourPropertyValues[ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD],
    ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD
  );

  return {
    errors,
    isValid: fv.isValid(),
  };
};
