import React, { FC } from "react";
import "./YourPropertySavedDetailsComponent.scss";
import { PropertyFormState } from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import {
  ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD,
} from "../../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepConstants";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
  CLIENT_CATEGORY_SINGLE,
} from "../../../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../types/AppState";
import { formatCurrencyValueToView } from "../../AboutYouStep.utils";
import { useLocalization } from "@saturn-ui/components";

type Props = {
  values: PropertyFormState;
};

export const YourPropertySavedDetailsComponent: FC<Props> = ({ values }) => {
  const {
    financialAdvicePage: {
      clientInfo: {
        salutation: {
          [CLIENT_1_SCOPE_NAME]: client1Salutation,
          [CLIENT_2_SCOPE_NAME]: client2Salutation,
        },
        category,
      },
    },
  } = useSelector((state: AppState) => state);
  const localization = useLocalization();

  return (
    <div className="your-property-saved-details-component">
      <div className="column">
        {values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD] && (
          <div className="saved-detail">
            <span className="title">What type of property do you own?</span>
            <span className="value">
              {values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]?.displayText}
            </span>
          </div>
        )}
        {values[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD] && (
          <div className="saved-detail">
            <span className="title">Who owns the property?</span>
            <span className="value">
              {values[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?.displayText}
            </span>
          </div>
        )}
        {values[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?.id === "Other" && (
          <>
            {category === CLIENT_CATEGORY_SINGLE && (
              <div className="saved-detail">
                <span className="title">
                  How much of the property do you own, as a percentage?
                </span>
                <span className="value">
                  {100 *
                    (values[
                      ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD
                    ] ?? 0)}
                  %
                </span>
              </div>
            )}
            {category === CLIENT_CATEGORY_JOINT && (
              <>
                <div className="saved-detail">
                  <span className="title">
                    {`${
                      client1Salutation?.forename ?? ""
                    }, how much of the property do you own, as a percentage?`}
                  </span>
                  <span className="value">
                    {(values[
                      ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD
                    ] ?? 0) * 100}
                    %
                  </span>
                </div>
                <div className="saved-detail">
                  <span className="title">
                    {`${
                      client2Salutation?.forename ?? ""
                    }, how much of the property do you own, as a percentage?`}
                  </span>
                  <span className="value">
                    {(values[
                      ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD
                    ] ?? 0) * 100}
                    %
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="column">
        {values[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD] && (
          <div className="saved-detail">
            <span className="title">Other owner details</span>
            <span className="value">
              {values[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]}
            </span>
          </div>
        )}
        {values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD] && (
          <div className="saved-detail">
            <span className="title">Property estimated value</span>
            <span className="value">
              {formatCurrencyValueToView(
                localization,
                values[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]
              )}
            </span>
          </div>
        )}
        {values[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD] && (
          <div className="saved-detail">
            <span className="title">Does the property have a mortgage?</span>
            <span className="value">
              {
                values[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]
                  ?.displayText
              }
            </span>
          </div>
        )}
        {values[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]?.id ===
          "Yes" &&
          values[ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD] && (
            <div className="saved-detail">
              <span className="title">
                How much approximately do you have left to repay on the
                mortgage?
              </span>
              <span className="value">
                {formatCurrencyValueToView(
                  localization,
                  values[ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]
                )}
              </span>
            </div>
          )}
      </div>
    </div>
  );
};
