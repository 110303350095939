import React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

const Logo: React.FC<Props> = ({ className, height = 40, width = 140 }) => {
  return (
    <SvgIcon
      viewBox={`0 0 ${width} ${height}`}
      className={className}
      width={width}
      height={height}
      sx={{ width, height }}
    >
      <g>
        <path
          d="M1.41791 28.8536H2.61576C4.15349 28.8536 4.32419 27.8863 4.32419 27.488C4.32419 26.2782 3.21168 26.0132 1.50325 26.0132H1.42539L1.41791 28.8536ZM1.41791 25.6254C2.5888 25.5969 3.86751 25.4517 3.86751 24.2419C3.86751 23.4109 3.2042 23.1324 2.478 23.1324H1.41791V25.6254ZM2.90174 22.5409C4.12055 22.5409 4.81231 23.2432 4.81231 24.1326C4.81231 24.9426 4.17745 25.5969 3.36741 25.7332H3.45724C4.46194 25.7332 5.29295 26.3321 5.29295 27.4237C5.29095 27.7194 5.22266 28.0109 5.09309 28.2768C4.96353 28.5427 4.77599 28.7761 4.54429 28.9599C4.01424 29.3597 3.5351 29.4211 2.63672 29.4211H0.480591V22.5334L2.90174 22.5409Z"
          fill="currentColor"
        ></path>
        <path
          d="M10.872 29.4346V28.4104C10.2461 29.2204 9.53491 29.6397 8.50177 29.6397C7.57793 29.6397 6.59719 29.2489 6.27677 28.3116C6.16896 28.0046 6.15698 27.5629 6.15698 27.0433V22.5409H7.0913V26.8202C7.0913 27.6707 7.0913 28.3325 7.82499 28.7443C8.11687 28.9013 8.4444 28.9803 8.77578 28.9734C9.57684 28.9734 10.3135 28.4838 10.872 27.8968V22.5409H11.8078V29.4286L10.872 29.4346Z"
          fill="currentColor"
        ></path>
        <path
          d="M14.2679 22.5409H13.3306V29.4331H14.2679V22.5409Z"
          fill="currentColor"
        ></path>
        <path
          d="M15.7742 29.4331V22.5409H16.7324V28.8281H20.0984V29.4331H15.7742Z"
          fill="currentColor"
        ></path>
        <path
          d="M21.8743 28.8865H22.7008C23.1619 28.8865 23.7489 28.8761 24.1846 28.7188C25.3271 28.3191 25.8631 27.2215 25.8631 26.0641C25.8631 24.8348 25.3061 23.634 24.0379 23.2821C23.6704 23.1969 23.2933 23.1606 22.9164 23.1743H21.8683L21.8743 28.8865ZM23.2802 22.5859C23.7964 22.5787 24.3106 22.6514 24.8045 22.8015C26.1521 23.2791 26.8633 24.6252 26.8633 26.0117C26.8633 27.4746 26.0952 28.7787 24.6877 29.2624C24.1591 29.448 23.4899 29.4765 22.9299 29.4765H20.9235V22.5859H23.2802Z"
          fill="currentColor"
        ></path>
        <path
          d="M28.7903 22.5409H27.853V29.4331H28.7903V22.5409Z"
          fill="currentColor"
        ></path>
        <path
          d="M35.6809 29.4735L30.9763 23.8795V29.3851H30.1663V22.4975H30.9688L35.1837 27.527C35.1837 27.527 35.1463 23.2716 35.1343 22.493H35.9653V29.4735H35.6809Z"
          fill="currentColor"
        ></path>
        <path
          d="M43.0881 26.2408V28.9255C42.2631 29.3731 41.3432 29.6174 40.4049 29.6382C39.0468 29.6382 36.9386 28.8386 36.9386 26.0866C36.9386 24.0173 38.3536 22.3433 40.4917 22.3433C41.5848 22.3433 42.7077 22.8404 42.9129 24.0218L42.6868 24.1101C42.1298 23.3615 41.5264 23.0051 40.5905 23.0051C38.8537 23.0051 37.9448 24.3886 37.9448 25.9997C37.9448 27.6108 38.8327 29.0348 40.5801 29.0348C41.1357 29.0241 41.6822 28.8912 42.1807 28.6455V26.8202H40.619V26.2438L43.0881 26.2408Z"
          fill="currentColor"
        ></path>
        <path
          d="M49.7106 28.9839C50.3934 28.9839 51.3008 28.641 51.3008 27.6003C51.3008 26.7723 50.7153 26.5088 50.043 26.3306L49.2944 26.1375C47.9468 25.7856 47.6728 24.9396 47.6728 24.3018C47.6728 23.354 48.4065 22.3298 49.9083 22.3298C51.0223 22.3298 51.8698 22.9542 52.0375 24.164L51.7934 24.2524C51.6272 23.9304 51.1585 22.9841 50.0071 22.9841C48.8557 22.9841 48.6041 23.8136 48.6041 24.2134C48.6041 25.0145 49.1806 25.2481 50.1269 25.4742L50.4099 25.5415C51.2589 25.7467 52.2651 26.1959 52.2651 27.4656C52.2651 27.8729 52.0315 29.6322 49.7855 29.6322C48.6041 29.6322 47.5096 29.0647 47.4048 27.6213L47.6578 27.5524C47.8899 28.1244 48.4843 28.9839 49.7166 28.9839"
          fill="currentColor"
        ></path>
        <path
          d="M56.1986 22.9422C54.5291 22.9422 53.8358 24.5728 53.8358 26.0162C53.8358 27.4596 54.568 29.0243 56.2076 29.0243C57.898 29.0243 58.5419 27.3952 58.5419 25.9503C58.5419 24.5054 57.8396 22.9437 56.1986 22.9437M56.2076 29.6397C54.0889 29.6397 52.8506 28.0196 52.8506 25.9997C52.8506 23.9799 54.0484 22.3388 56.1986 22.3388C58.3173 22.3388 59.5376 23.9694 59.5376 25.9803C59.5376 27.9911 58.3397 29.6397 56.2076 29.6397Z"
          fill="currentColor"
        ></path>
        <path
          d="M65.8233 24.2898C65.611 23.8987 65.2962 23.5728 64.9126 23.3473C64.529 23.1217 64.0912 23.005 63.6462 23.0096C61.8779 23.0096 61.1277 24.3931 61.1277 25.9877C61.1277 28.0376 62.4364 28.9554 63.6552 28.9554C64.6135 28.9554 65.3456 28.4778 65.8727 27.6977L66.1362 27.8025C65.9311 28.5856 65.1989 29.6382 63.48 29.6382C61.3628 29.6382 60.141 28.0376 60.141 26.0162C60.141 23.9469 61.3912 22.3373 63.5564 22.3373C64.7767 22.3373 65.8817 22.9512 66.0763 24.1625L65.8233 24.2898Z"
          fill="currentColor"
        ></path>
        <path
          d="M68.1307 22.5409H67.1934V29.4331H68.1307V22.5409Z"
          fill="currentColor"
        ></path>
        <path
          d="M69.6145 29.4331V22.5409H73.8309V23.1309H70.5518V25.5341H73.4506V26.1285H70.5518V28.8326H74.2637V29.4331H69.6145Z"
          fill="currentColor"
        ></path>
        <path
          d="M77.7209 23.1668V29.4331H76.7536V23.1668H74.587V22.5409H79.877V23.1668H77.7209Z"
          fill="currentColor"
        ></path>
        <path
          d="M83.6413 29.7729C83.0933 31.0217 82.3132 31.5113 80.9371 31.5113H80.6616V31.1804C82.0451 31.0831 82.4869 30.3988 83.0334 29.2758L83.1831 28.9689L80.5089 22.5305H81.5136L83.6712 27.8264L85.9936 22.5349H86.8141L83.6413 29.7729Z"
          fill="currentColor"
        ></path>
        <path
          d="M10.2192 6.36549C10.3794 6.29811 10.6264 6.20827 10.7866 6.1394C10.3913 3.29451 8.44933 1.89302 5.81556 1.89302C2.66073 1.89302 0.640857 4.00273 0.640857 6.47929C0.640857 9.67904 3.47826 10.4756 4.38413 10.7242C4.63568 10.7915 5.81556 11.1105 6.06561 11.1554C7.60784 11.5851 8.1319 11.928 8.53917 12.4715C8.90759 12.9526 9.09949 13.5458 9.08269 14.1515C9.08269 16.1474 7.47159 17.3453 5.38134 17.3453C4.24703 17.3523 3.14348 16.9767 2.24897 16.2792C1.50816 15.6802 0.937196 14.8976 0.592943 14.0093C0.396887 14.0809 0.196925 14.1414 -0.00598145 14.1904C0.130274 16.3481 1.51379 18.8441 5.53407 18.8441C9.55435 18.8441 11.3017 16.1878 11.3017 13.8266C11.3017 10.6298 8.50922 9.78385 7.64527 9.53829C7.24549 9.423 5.35439 8.97081 4.99054 8.83456C3.94243 8.49317 2.7895 7.84483 2.7895 6.26068C2.7895 4.6346 4.07718 3.38584 6.05663 3.38584C8.69189 3.38584 9.75798 5.47309 10.2132 6.3595"
          fill="currentColor"
        ></path>
        <path
          d="M17.4228 9.70299L24.7102 2.37067H22.4193L15.1528 9.70299V2.37067H12.9742V18.3934H15.1528V10.269L23.0077 18.3934H25.8227L17.4228 9.70299Z"
          fill="currentColor"
        ></path>
        <path
          d="M29.5779 2.37067H27.3993V18.3949H29.5779V2.37067Z"
          fill="currentColor"
        ></path>
        <path
          d="M34.5863 11.109C35.9025 11.1778 36.0402 11.1778 36.5164 11.1778C41.2149 11.1778 43.0776 8.9768 43.0776 6.41041C43.0776 4.36658 41.8902 3.3005 40.8765 2.84681C39.9677 2.43954 38.9225 2.37067 37.4028 2.37067H32.3838V18.4009H34.5848L34.5863 11.109ZM34.5863 3.70776H37.0165C38.963 3.70776 39.6727 4.15696 40.1503 4.72893C40.5796 5.2626 40.8122 5.92768 40.8092 6.61255C40.8092 7.99906 39.9467 9.38407 37.8595 9.95155C36.7912 10.2156 35.6892 10.3166 34.5908 10.251L34.5863 3.70776Z"
          fill="currentColor"
        ></path>
        <path
          d="M51.0389 3.82306H56.0549V2.37067H43.7739V3.82306H48.8139V18.3949H51.0389V3.82306Z"
          fill="currentColor"
        ></path>
        <path
          d="M71.3124 10.3244C71.3124 6.14838 68.8389 1.90351 63.5489 1.90351C58.3712 1.89303 55.7374 5.88785 55.7374 10.4277C55.7374 15.5186 58.9611 18.871 63.5699 18.871C68.4032 18.871 71.3109 15.2176 71.3109 10.3154L71.3124 10.3244ZM68.9976 10.3079C68.9976 14.3926 67.0182 17.48 63.5714 17.48C60.5767 17.48 58.0553 14.9615 58.0553 10.3753C58.0553 6.35801 60.1904 3.31697 63.5489 3.31697C67.2472 3.3005 68.9976 6.77426 68.9976 10.3079Z"
          fill="currentColor"
        ></path>
        <path
          d="M86.4592 2.37217H84.5127L84.625 14.2458C84.1758 13.5196 84.014 13.3385 83.1516 12.3173L74.846 2.37217H72.9849V18.4024H74.8685V5.41021C75.2652 6.03366 75.7045 6.62901 76.1831 7.19201L85.7809 18.6105H86.4651L86.4592 2.37217Z"
          fill="currentColor"
        ></path>
        <path
          d="M139.998 31.3526L133.334 4.37557L135.382 0.464592H130.824V1.5741H129.424V0.406197H125.457V1.58907H124.109V0.464592H119.499L120.9 3.27055L120.438 4.20038H113.538L113.078 3.27055L114.486 0.464592H109.862V1.58907H108.515V0.406197H104.553V1.5741H103.151V0.464592H98.5934L100.642 4.37407L93.9727 31.3541L113.006 29.5978V20.9283C113.006 18.6479 114.788 16.7987 116.982 16.7987C119.175 16.7987 120.959 18.6479 120.959 20.9283V29.5978V29.6576L139.998 31.3526Z"
          fill="currentColor"
        ></path>
        <path
          d="M101.946 39.5878C101.49 39.5874 101.042 39.4682 100.646 39.2419L100.456 39.4725H100.185L100.137 37.8255H100.411C100.648 38.5217 101.101 39.2749 101.935 39.2749C102.545 39.2749 102.843 38.9425 102.843 38.4753C102.843 38.1025 102.599 37.8105 102.111 37.6353C101.753 37.5066 101.453 37.4107 101.149 37.282C100.573 37.0394 100.261 36.6052 100.261 36.0092C100.261 35.2711 100.913 34.6617 101.855 34.6617C102.261 34.666 102.66 34.7759 103.012 34.9806L103.181 34.7695H103.46L103.487 36.3491H103.208C102.966 35.6244 102.668 34.9671 101.895 34.9671C101.446 34.9671 101.109 35.2666 101.109 35.6649C101.109 36.0631 101.332 36.2877 101.793 36.4704C102.105 36.5992 102.45 36.701 102.727 36.8223C103.283 37.0529 103.717 37.3928 103.717 38.116C103.717 38.9574 102.998 39.5803 101.941 39.5803"
          fill="currentColor"
        ></path>
        <path
          d="M105.036 35.4627C104.897 35.4627 104.764 35.4079 104.665 35.31C104.566 35.2122 104.509 35.0793 104.508 34.9402C104.507 34.8694 104.52 34.7991 104.546 34.7334C104.572 34.6676 104.611 34.6077 104.661 34.557C104.761 34.4547 104.897 34.3962 105.04 34.3944C105.183 34.3926 105.321 34.4477 105.423 34.5475C105.525 34.6473 105.584 34.7837 105.586 34.9267C105.583 35.0705 105.524 35.2075 105.421 35.3079C105.318 35.4083 105.18 35.4639 105.036 35.4627ZM104.196 39.4725V39.2419C104.297 39.2401 104.398 39.2266 104.496 39.2015C104.555 39.1781 104.605 39.1365 104.639 39.0826C104.673 39.0288 104.689 38.9655 104.684 38.902V36.9256C104.684 36.8569 104.666 36.7895 104.633 36.7292C104.6 36.6689 104.553 36.6176 104.496 36.5797C104.372 36.5159 104.235 36.4792 104.096 36.4719V36.2413L105.484 36.1665L105.532 36.2144V38.8556C105.528 38.921 105.544 38.9861 105.578 39.0421C105.612 39.0981 105.662 39.1423 105.722 39.1686C105.818 39.2063 105.919 39.2309 106.021 39.2419V39.4725H104.196Z"
          fill="currentColor"
        ></path>
        <path
          d="M108.573 39.4725V39.2419C108.674 39.2408 108.775 39.2272 108.872 39.2015C108.988 39.1536 109.022 39.0518 109.022 38.902V37.285C109.022 36.8163 108.813 36.5797 108.501 36.5797C108.343 36.5817 108.187 36.624 108.05 36.7026C107.912 36.7811 107.797 36.8935 107.715 37.0289V38.8586C107.711 38.9189 107.724 38.9792 107.753 39.0323C107.782 39.0853 107.825 39.129 107.878 39.1581C107.975 39.195 108.075 39.2196 108.178 39.2315V39.462H106.378V39.2315C106.479 39.2302 106.58 39.2166 106.677 39.191C106.739 39.1703 106.791 39.1293 106.825 39.0748C106.86 39.0203 106.875 38.9557 106.868 38.8916V36.9256C106.865 36.8542 106.846 36.7843 106.814 36.7209C106.781 36.6575 106.734 36.6021 106.677 36.5588C106.57 36.5055 106.452 36.4759 106.332 36.4719V36.2413L107.666 36.1665L107.714 36.2144V36.6815H107.727C108.006 36.424 108.263 36.1126 108.886 36.1126C109.563 36.1126 109.882 36.5453 109.882 37.2101V38.8691C109.876 38.9312 109.89 38.9934 109.922 39.0471C109.954 39.1008 110.001 39.1432 110.058 39.1686C110.154 39.207 110.255 39.2297 110.358 39.2359V39.4665L108.573 39.4725Z"
          fill="currentColor"
        ></path>
        <path
          d="M112.167 39.5743C111.042 39.5743 110.494 38.8257 110.494 37.827C110.489 37.5997 110.531 37.3738 110.615 37.1629C110.7 36.952 110.827 36.7603 110.987 36.5995C111.148 36.4387 111.34 36.312 111.55 36.227C111.761 36.1421 111.987 36.1006 112.214 36.1051C113.02 36.1051 113.412 36.4914 113.412 36.9585C113.412 37.2296 113.283 37.4332 112.963 37.4332C112.706 37.4332 112.469 37.2984 112.469 37.0604C112.485 36.8748 112.519 36.6913 112.571 36.5123C112.509 36.403 112.387 36.3701 112.217 36.3701C111.831 36.3701 111.433 36.728 111.433 37.7925C111.433 38.5921 111.825 39.1476 112.394 39.1476C112.855 39.1476 113.105 38.923 113.343 38.5232L113.58 38.673C113.448 38.9372 113.246 39.1604 112.996 39.3187C112.747 39.4769 112.459 39.5643 112.164 39.5713"
          fill="currentColor"
        ></path>
        <path
          d="M115.311 36.3701C114.919 36.3701 114.633 36.7354 114.613 37.4541L115.899 37.4467C115.899 36.8852 115.791 36.3701 115.311 36.3701ZM115.413 39.5743C114.234 39.5743 113.692 38.7882 113.692 37.8195C113.692 36.9391 114.302 36.1051 115.339 36.1051C116.416 36.1051 116.81 36.7699 116.81 37.514V37.7311H114.613C114.613 38.5981 114.938 39.1476 115.703 39.1476C116.152 39.1476 116.414 38.9035 116.652 38.5307L116.897 38.6939C116.752 38.9609 116.537 39.1837 116.276 39.3387C116.015 39.4937 115.716 39.5752 115.413 39.5743Z"
          fill="currentColor"
        ></path>
        <path
          d="M119.042 39.2285C119.233 39.2208 119.423 39.2028 119.611 39.1746C119.855 39.1266 119.997 39.0054 119.997 38.8017V35.4747H119.048V35.1752H119.277C119.501 35.1829 119.724 35.1423 119.931 35.056C120.137 34.9696 120.323 34.8398 120.475 34.6751H120.908C120.888 35.1618 120.875 35.4672 120.875 35.6978V38.7793C120.875 38.9754 120.983 39.0787 121.213 39.1521C121.398 39.1938 121.587 39.2189 121.776 39.227V39.471H119.042V39.2285Z"
          fill="currentColor"
        ></path>
        <path
          d="M124.009 34.9267C123.907 34.9266 123.807 34.9471 123.713 34.987C123.62 35.027 123.536 35.0855 123.466 35.159C123.396 35.2326 123.341 35.3196 123.306 35.4148C123.271 35.51 123.255 35.6114 123.26 35.7128C123.26 36.2548 123.673 36.4375 124.458 36.7968C124.675 36.5797 124.769 36.2278 124.769 35.8071C124.769 35.2995 124.491 34.9267 124.021 34.9267M123.507 37.2595C123.359 37.3763 123.24 37.527 123.162 37.699C123.084 37.8709 123.048 38.0591 123.058 38.2477C123.058 38.8991 123.437 39.3123 124.007 39.3123C124.578 39.3123 124.915 38.953 124.915 38.4319C124.915 37.8494 124.413 37.6728 123.506 37.2595M123.925 39.5893C122.835 39.5893 122.183 39.0338 122.183 38.3031C122.183 37.7401 122.543 37.3613 123.269 37.1442C122.652 36.8447 122.371 36.406 122.371 35.9314C122.371 35.2142 123.097 34.6437 124.072 34.6437C125.007 34.6437 125.63 35.1453 125.63 35.8236C125.63 36.2847 125.34 36.6696 124.688 36.9076C125.488 37.2191 125.807 37.6189 125.807 38.1878C125.807 38.9664 125.075 39.5893 123.929 39.5893"
          fill="currentColor"
        ></path>
        <path
          d="M127.87 39.5474C127.03 39.5474 126.394 39.1206 126.394 38.5981C126.389 38.536 126.396 38.4736 126.417 38.4147C126.437 38.3558 126.469 38.3017 126.511 38.256C126.554 38.2102 126.605 38.1737 126.662 38.1489C126.719 38.124 126.781 38.1112 126.843 38.1115C127.155 38.1115 127.345 38.3001 127.345 38.5382C127.327 38.734 127.286 38.927 127.223 39.1132C127.389 39.2126 127.578 39.2644 127.771 39.2629C128.327 39.2629 128.836 38.8137 128.836 37.9078C128.836 37.2834 128.436 36.8373 127.879 36.8373C127.701 36.8335 127.525 36.8714 127.364 36.9478C127.203 37.0242 127.062 37.1371 126.952 37.2775L126.714 37.1891L127.014 34.7785H129.594V35.5915H127.261L127.111 36.7759C127.428 36.5706 127.797 36.4605 128.174 36.4584C128.852 36.4584 129.806 36.8103 129.806 37.9003C129.806 38.8961 128.926 39.5474 127.876 39.5474"
          fill="currentColor"
        ></path>
        <path
          d="M131.895 39.5534C131.007 39.5534 130.398 39.1042 130.398 38.5846C130.393 38.5226 130.4 38.4601 130.421 38.4012C130.441 38.3423 130.473 38.2883 130.515 38.2425C130.558 38.1967 130.609 38.1603 130.666 38.1354C130.723 38.1105 130.785 38.0978 130.847 38.098C131.158 38.098 131.349 38.2732 131.349 38.5172C131.334 38.7139 131.293 38.9078 131.227 39.0937C131.409 39.2203 131.629 39.282 131.85 39.2689C132.379 39.2689 132.853 38.923 132.853 38.11C132.853 37.5949 132.684 37.1831 131.985 37.1831H131.555V36.7968H131.846C132.428 36.7968 132.672 36.4644 132.672 35.7876C132.672 35.3265 132.482 34.9671 131.948 34.9671C131.749 34.9696 131.556 35.0363 131.398 35.1573C131.463 35.3507 131.502 35.5522 131.513 35.7562C131.513 35.9928 131.325 36.168 131.012 36.168C130.727 36.168 130.579 35.9523 130.579 35.6873C130.579 35.2067 131.169 34.6721 132.144 34.6721C133.37 34.6721 133.654 35.3968 133.654 35.7622C133.654 36.1889 133.424 36.6905 132.576 36.8941V36.96C133.118 37.0079 133.843 37.2715 133.843 38.1324C133.843 38.9934 132.99 39.5609 131.897 39.5609"
          fill="currentColor"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default Logo;
