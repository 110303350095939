import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const holdingTypeList: List = {
  listType: "holdingType",
  lang: "en-GB",
  items: [
    {
      id: "Saving",
      displayText: "Savings",
    },
    {
      id: "Investment",
      displayText: "Investment",
    },
    {
      id: "Pension",
      displayText: "Pension",
    },
  ],
};
