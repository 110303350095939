import React from "react";
import { Button, Dialog, IconButton } from "@saturn-ui/components";
import { CloseIcon } from "../../../../../assets/icons/CloseIcon";
import "./SharingYourInformationDialog.scss";

type Props = {
  onCancelClick?: () => void;
  onQuitClick?: () => void;
};
export const SharingYourInformationDialog: React.FC<Props> = ({
  onCancelClick,
  onQuitClick,
}) => {
  return (
    <Dialog isOpen={true} onClose={onCancelClick} className="auth-dialog">
      <IconButton
        className="dialog-close-button"
        ariaLabel={"Close"}
        onClick={onCancelClick}
      >
        <CloseIcon />
      </IconButton>
      <h2 className="dialog-title">Sharing your information</h2>
      <p>
        At this point we need you to share some information about your current
        situation. This is to help your adviser better understand your
        circumstances and prepare tailored advice.
      </p>
      <p>
        We need every person who is attending the financial advice meeting to
        share their information.
      </p>
      <div className="dialog-single-action">
        <Button
          className="decorated-button"
          onClick={onQuitClick}
          variant="contained"
          id="proceed-button"
          disableRipple={true}
        >
          <span className="decoration">We are ready to continue</span>
        </Button>
      </div>
    </Dialog>
  );
};
