import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const UnselectedIcon: React.FC<Props> = ({
  className,
  height = 16,
  width = 16,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <rect
      x="1"
      y="1"
      width="14"
      height="14"
      rx="2px"
      ry="2px"
      stroke="#A1A9B1"
      strokeWidth="2px"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
