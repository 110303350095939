import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./YourFeelingsStep.scss";
import { Layout } from "../../../layout/Layout";
import { useHistory, useLocation } from "react-router-dom";
import {
  getErrorRoute,
  getThankYouRoute,
  getYourMoneyRoute,
} from "../../../../helpers";
import "../../JourneySteps.scss";
import {
  CompleteJourneyState,
  FinishATRState,
  InitialiseYourFeelingsStepStates,
  YourFeelingsStepContentStates,
  YourFeelingsStepDefinition,
  YourFeelingsStepFormState,
} from "../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepTypes";
import { initialiseYourFeelingsStep } from "../../../../feature/journeySteps/steps/yourFeelingsStep/thunks/InitialiseYourFeelingsStep";
import { useAppDispatch } from "../../../../redux/store";
import { RootState, useRootSelector } from "skipton-features";
import Modal from "../../../modal/Modal";
import { Video } from "../../../video/Video";
import { getVideoData } from "../../JourneySteps.utils";
import { VIDEO_INTEGRATION_VIDEOS_ATR_ID } from "../../../../feature/videoIntegration/VideoIntegrationConstants";
import { useSelector } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { SendCFFCustomDataStates } from "../../../../feature/customData/CustomDataTypes";
import { postCFFCustomData } from "../../../../feature/customData/thunks/PostCustomDataCFF";
import {
  resetState as resetYourFeelingsStepState,
  setCurrentScope,
  setValues,
} from "../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepSlice";
import { YourFeelingsStepContent } from "./yourFeelingsStepContent/YourFeelingsStepContent";
import { ATR } from "./ATR/ATR";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
} from "../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import { OverlayContext } from "../../../../contexts/OverlayContext";
import { JointCaseDetailsDialog } from "../dialogs/jointCaseDetailsDialog/JointCaseDetailsDialog";
import { JointContinueAtrDialog } from "../dialogs/jointCountinueAtrDialog/JointContinueAtrDialog";
import { ReadyToCompleteDialog } from "../dialogs/readyToCompleteDialog/ReadyToCompleteDialog";
import { postPartyATR } from "../../../../feature/journeySteps/steps/yourFeelingsStep/thunks/PostPartyATR";
import {
  PersistableSections,
  useSaveAndResumeLogic,
} from "../../../../hooks/useSaveAndResumeLogic";
import {
  HTTP_UNAUTHORIZED_STATUS_CODE,
  HttpRequestError,
} from "skipton-common";
import { ErrorRetry } from "../../../errorRetry/ErrorRetry";
import { PuffLoader } from "@saturn-ui/components";
import { useOnMount } from "../../../../hooks/useOnMount";
import { postCompleteJourney } from "../../../../feature/journeySteps/steps/yourFeelingsStep/thunks/PostCompleteJourney";
import { resetUserData } from "../../../../feature/authentication";
import { resetToInitialValues as resetFinancialAdvicePageToInitialValues } from "../../../../feature/financialAdvicePage/FinancialAdvicePageSlice";
import { resetToInitialValues as resetOurAdviceStepToInitialValues } from "../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepSlice";
import { resetToInitialValues as resetAboutYouStepToInitialValues } from "../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepSlice";
import { resetState as resetCustomDataState } from "../../../../feature/customData/CustomDataSlice";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../../../helpers/adobeAnalyticsHelpers";

type Props = {
  definition: YourFeelingsStepDefinition;
};

export type LocationState = {
  allSectionsCompleted?: boolean;
};
export const YourFeelingsStep: FC<Props> = ({ definition }) => {
  const history = useHistory() as unknown[];
  const location = useLocation<LocationState>() as { state: LocationState };
  const { allSectionsCompleted = false } = location?.state || {};
  const dispatch = useAppDispatch();
  const { showOverlay, hideOverlay } = useContext(OverlayContext);
  const { commitStepStarted, commitStepCompleted } = useSaveAndResumeLogic(
    PersistableSections.ATR
  );
  const [showCompleteJourneyDialog, setShowCompleteJourneyDialog] =
    useState(allSectionsCompleted);
  const [finishATRState, setFinishATRState] = useState(
    FinishATRState.Uninitialised
  );
  const [completeJourneyState, setCompleteJourneyState] = useState(
    CompleteJourneyState.Uninitialised
  );
  const [initialiseYourFeelingsStepState, setInitialiseYourFeelingsStepState] =
    useState(InitialiseYourFeelingsStepStates.Uninitialised);

  const [__, setSendCFFCustomDataState] = useState(
    SendCFFCustomDataStates.Uninitialised
  );

  const [yourFeelingsStepContentState, setYourFeelingsStepContentState] =
    useState(YourFeelingsStepContentStates.ConfirmNoQuestions);

  const { APIUri, isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );

  const isContentATR = useMemo(
    () => yourFeelingsStepContentState === YourFeelingsStepContentStates.ATR,
    [yourFeelingsStepContentState]
  );

  const { finishATRError, finishATRLoading } = useMemo(
    () => ({
      finishATRError:
        finishATRState === FinishATRState.Error
          ? "Something went wrong. Please try again."
          : null,
      finishATRLoading: finishATRState === FinishATRState.Initialising,
    }),
    [finishATRState]
  );

  const { completeJourneyError, completeJourneyLoading } = useMemo(
    () => ({
      completeJourneyError:
        completeJourneyState === CompleteJourneyState.Error
          ? "Something went wrong. Please try again."
          : null,
      completeJourneyLoading:
        completeJourneyState === CompleteJourneyState.Initialising,
    }),
    [completeJourneyState]
  );

  const checkYourFeelingsStepState = (
    ...states: InitialiseYourFeelingsStepStates[]
  ) => !!states.find((state) => state === initialiseYourFeelingsStepState);

  const {
    yourFeelings: { atrQuestions, currentScope },
    videoIntegration: { content: videoIntegrationContent },
    financialAdvicePage: {
      clientInfo: {
        category,
        salutation: { [CLIENT_2_SCOPE_NAME]: client2Salutation },
      },
    },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = getVirtualPageCustomerType(category);
      if (
        yourFeelingsStepContentState ===
        YourFeelingsStepContentStates.ConfirmNoQuestions
      ) {
        trackVirtualPageView({
          pageName: "Let's talk about risk",
          customerType,
        });
      }
    }
  }, [isAdobeAnalyticsLoaded]);

  const sendCFFCustomData = useCallback(async (): Promise<void> => {
    try {
      setSendCFFCustomDataState(SendCFFCustomDataStates.Initialising);

      await dispatch(
        postCFFCustomData({
          baseUrl: APIUri ?? "",
        })
      ).unwrap();
      setSendCFFCustomDataState(SendCFFCustomDataStates.Success);
    } catch (error) {
      setSendCFFCustomDataState(SendCFFCustomDataStates.Error);
    }
  }, [APIUri, dispatch]);

  const handleBackClick = useCallback(() => {
    history.push(getYourMoneyRoute());
  }, [history]);

  const handleCommitStepStarted = useCallback(async () => {
    try {
      await commitStepStarted();
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
    }
  }, [commitStepStarted, history]);

  const videoData = getVideoData(
    videoIntegrationContent,
    VIDEO_INTEGRATION_VIDEOS_ATR_ID
  );

  const initialisePage = useCallback(async () => {
    try {
      setInitialiseYourFeelingsStepState(
        InitialiseYourFeelingsStepStates.Initialising
      );

      await dispatch(
        initialiseYourFeelingsStep({
          baseUrl: APIUri ?? "",
        })
      ).unwrap();

      await handleCommitStepStarted();

      setInitialiseYourFeelingsStepState(
        InitialiseYourFeelingsStepStates.Success
      );
    } catch (error) {
      setInitialiseYourFeelingsStepState(
        InitialiseYourFeelingsStepStates.Error
      );
    }
  }, [APIUri, dispatch, handleCommitStepStarted]);

  const handleProceedATR = useCallback(() => {
    hideOverlay();
    setYourFeelingsStepContentState(YourFeelingsStepContentStates.ATR);
  }, [hideOverlay]);

  const handleComplete = useCallback(async () => {
    try {
      setCompleteJourneyState(CompleteJourneyState.Initialising);
      await dispatch(
        postCompleteJourney({
          baseUrl: APIUri ?? "",
        })
      ).unwrap();
      setCompleteJourneyState(CompleteJourneyState.Success);

      dispatch(resetUserData());
      dispatch(resetFinancialAdvicePageToInitialValues());
      dispatch(resetOurAdviceStepToInitialValues());
      dispatch(resetAboutYouStepToInitialValues());
      dispatch(resetYourFeelingsStepState());
      dispatch(resetCustomDataState());

      setShowCompleteJourneyDialog(false);
      history.push({
        pathname: getThankYouRoute(),
        state: { category },
      });
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setCompleteJourneyState(CompleteJourneyState.Error);
    }
  }, [APIUri, dispatch, history]);

  const handleProceedClient2ATR = useCallback(() => {
    hideOverlay();
    dispatch(setCurrentScope(CLIENT_2_SCOPE_NAME));
  }, [dispatch, hideOverlay]);

  const handleCloseCompleteJourneyDialog = useCallback(() => {
    setShowCompleteJourneyDialog(false);
  }, []);

  const renderReadyToCompleteDialog = useCallback(
    () => (
      <ReadyToCompleteDialog
        loading={completeJourneyLoading}
        errorMessage={completeJourneyError}
        onCancelClick={handleCloseCompleteJourneyDialog}
        onCompleteClick={handleComplete}
      />
    ),
    [
      completeJourneyError,
      completeJourneyLoading,
      handleComplete,
      hideOverlay,
      setShowCompleteJourneyDialog,
    ]
  );

  const handleClickFinishATR = useCallback(async () => {
    if (
      category === CLIENT_CATEGORY_JOINT &&
      currentScope === CLIENT_1_SCOPE_NAME
    ) {
      showOverlay({
        content: JointContinueAtrDialog,
        props: {
          salutation: client2Salutation?.forename || "",
          onCancelClick: hideOverlay,
          onProceedClick: handleProceedClient2ATR,
        },
      });
    } else {
      try {
        setFinishATRState(FinishATRState.Initialising);
        await dispatch(
          postPartyATR({
            baseUrl: APIUri ?? "",
          })
        ).unwrap();
        await commitStepCompleted();
        setFinishATRState(FinishATRState.Success);
        setShowCompleteJourneyDialog(true);
      } catch (error) {
        if (
          (error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE
        ) {
          history.push({
            pathname: getErrorRoute(),
            state: { errorCode: (error as HttpRequestError).code, category },
          });
        }
        setFinishATRState(FinishATRState.Error);
      }
    }
  }, [
    category,
    currentScope,
    showOverlay,
    client2Salutation?.forename,
    hideOverlay,
    handleProceedClient2ATR,
    dispatch,
    APIUri,
    commitStepCompleted,
    history,
  ]);

  const handleConfirmNoQuesFormSubmit = useCallback(
    async (values: YourFeelingsStepFormState) => {
      dispatch(setValues(values));
      await sendCFFCustomData();
      if (category === CLIENT_CATEGORY_JOINT) {
        showOverlay({
          content: JointCaseDetailsDialog,
          props: {
            onCancelClick: hideOverlay,
            onProceedClick: handleProceedATR,
          },
        });
      } else {
        handleProceedATR();
      }
    },
    [
      category,
      dispatch,
      handleProceedATR,
      hideOverlay,
      sendCFFCustomData,
      showOverlay,
    ]
  );

  useOnMount(() => void initialisePage());

  const renderYourFeelingsContent = () => {
    if (
      checkYourFeelingsStepState(InitialiseYourFeelingsStepStates.Initialising)
    ) {
      return (
        <div className="your-feelings-step-preloader-wrapper">
          <PuffLoader />
        </div>
      );
    }

    if (checkYourFeelingsStepState(InitialiseYourFeelingsStepStates.Error)) {
      return (
        <>
          <div className="left-side your-feelings-step-error-retry-wrapper">
            <ErrorRetry handleRetryButtonClick={initialisePage} />
          </div>
          <div className="right-side" />
        </>
      );
    }

    return (
      <>
        <div className={`left-side ${isContentATR ? "hide-mobile" : ""}`}>
          <div className="step-pagination" aria-hidden="true">
            Step 4 of 4
          </div>
          <h1 className="step-title">Your feelings towards risk and reward</h1>
          <p>
            At Skipton Building Society, we will only ever recommend an
            investment approach that you feel comfortable with.
          </p>
          <p>
            To find out more, please watch the following short video which
            explains our approach to assessing your risk and reward feelings.
          </p>

          <Video src={videoData?.video} description={videoData?.description} />
        </div>
        <div className="right-side journey-step-bg">
          <Modal.InPage>
            {yourFeelingsStepContentState ===
              YourFeelingsStepContentStates.ConfirmNoQuestions && (
              <YourFeelingsStepContent
                definition={definition}
                onFormSubmit={handleConfirmNoQuesFormSubmit}
              />
            )}
            {isContentATR && (
              <ATR
                loading={finishATRLoading}
                errorMessage={finishATRError}
                scope={currentScope}
                questions={atrQuestions || []}
                definition={definition}
                onClickFinish={handleClickFinishATR}
              />
            )}
          </Modal.InPage>
        </div>
        {showCompleteJourneyDialog && renderReadyToCompleteDialog()}
      </>
    );
  };

  return (
    <Layout
      backButtonText="Back"
      onBackPress={handleBackClick}
      alwaysHavePadding={true}
      progressStepperProps={{
        numberOfSteps: 4,
        active: 4,
        maxStepDone: 3,
      }}
    >
      <div className="journey-step your-feelings-step">
        {renderYourFeelingsContent()}
      </div>
    </Layout>
  );
};
