import { CaseApi } from "skipton-common";
import { ProvisionFormState } from "../YourMoneyStepTypes";
import { ClientCategory } from "../../../../../feature/financialAdvicePage/FinancialAdvicePageTypes";
import {
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_FORM_POLICY_REFERENCE_NUMBER,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_FORM_WHO_BELONG_TO,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
} from "../YourMoneyStepConstants";
import { CLIENT_CATEGORY_JOINT } from "../../../../../feature/financialAdvicePage/FinancialAdvicePageConstants";

export const mapDataToProvisionApi = (
  provision: ProvisionFormState,
  category: ClientCategory
): CaseApi.Provision => {
  return {
    id: null,
    holdingType: provision[PROVISION_FORM_TYPE_OF_HOLDING]?.id || "",
    providerName:
      provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH]?.displayText || "",
    providerOtherName:
      provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER] || "",
    product: provision[PROVISION_FORM_PRODUCT_TYPE]?.id || "",
    ownership:
      category === CLIENT_CATEGORY_JOINT
        ? provision[PROVISION_FORM_WHO_BELONG_TO]?.id || "Party1"
        : "Party1",
    value: provision[PROVISION_FORM_HOW_MUCH_IS_IT_WORTH] || 0,
    policyNo: provision[PROVISION_FORM_POLICY_REFERENCE_NUMBER] || "",
  };
};
