import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCaseApi } from "skipton-common";
import { AppState } from "../../../../../types/AppState";
import { mapDataToPartyPersonalApi } from "../mappers";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
} from "../../../../financialAdvicePage/FinancialAdvicePageConstants";

export const postPartyPersonal = createAsyncThunk<
  void,
  {
    baseUrl: string;
  },
  { state: AppState }
>(
  "post-party-personal",
  async (
    data: {
      baseUrl: string;
    },
    { getState }
  ): Promise<void> => {
    const { baseUrl } = data;
    const {
      aboutYou: {
        initialValues: { aboutYou },
      },
      financialAdvicePage: {
        clientInfo: { category },
      },
    } = getState();

    const caseApi = withSessionId(getCaseApi(baseUrl));
    const client1PartyPersonalBody = mapDataToPartyPersonalApi(
      aboutYou[CLIENT_1_SCOPE_NAME]
    );

    await caseApi.postPartyPersonal(1, client1PartyPersonalBody);

    if (category === CLIENT_CATEGORY_JOINT) {
      const client2PartyPersonalBody = mapDataToPartyPersonalApi(
        aboutYou[CLIENT_2_SCOPE_NAME]
      );

      await caseApi.postPartyPersonal(2, client2PartyPersonalBody);
    }
  }
);

export const postPartyPersonalSuccess = postPartyPersonal.fulfilled;
export const postPartyPersonalPending = postPartyPersonal.pending;
export const postPartyPersonalFailed = postPartyPersonal.rejected;
