import {
  FieldValidator,
  Localization,
  ValidationConfiguration,
} from "@saturn-ui/components";
import {
  ATRErrors,
  ATRFormDefinition,
  ATRFormErrors,
  ATRFormState,
  ATRState,
} from "./YourFeelingsStepTypes";
import {
  ClientCategory,
  ClientScopeName,
} from "../../../financialAdvicePage/FinancialAdvicePageTypes";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
} from "../../../financialAdvicePage/FinancialAdvicePageConstants";

export const validate = (
  scopeName: ClientScopeName,
  ATRValues: ATRFormState,
  definition: ATRFormDefinition,
  config: ValidationConfiguration,
  localization: Localization,
  clientCategory: ClientCategory
): { errors: ATRFormErrors; isValid: boolean } => {
  const errors: ATRFormErrors = {
    [CLIENT_1_SCOPE_NAME]: {},
    [CLIENT_2_SCOPE_NAME]: {},
  };
  let isValid = true;

  const client1Errors = validateClient(
    ATRValues[CLIENT_1_SCOPE_NAME],
    definition,
    config,
    localization
  );
  const isClient1Valid = client1Errors.isValid;
  isValid = isValid && isClient1Valid;

  let isClient2Valid;
  if (clientCategory === CLIENT_CATEGORY_JOINT) {
    const client2Errors = validateClient(
      ATRValues[CLIENT_2_SCOPE_NAME],
      definition,
      config,
      localization
    );
    isClient2Valid = client2Errors.isValid;
    isValid = isValid && isClient2Valid;

    if (Object.keys(client2Errors.errors).length > 0) {
      errors[CLIENT_2_SCOPE_NAME] = client2Errors.errors;
    }
  }

  if (Object.keys(client1Errors.errors).length > 0) {
    errors[CLIENT_1_SCOPE_NAME] = client1Errors.errors;
  }

  return {
    errors,
    isValid,
  };
};

const validateClient = (
  ATRValues: ATRState,
  definition: ATRFormDefinition,
  config: ValidationConfiguration,
  localization: Localization
): { errors: ATRErrors; isValid: boolean } => {
  const errors: ATRErrors = {};

  const fv = new FieldValidator<ATRErrors>(errors, config, localization);

  for (const key in ATRValues) {
    fv.checkSelect(definition.fields.content.question, ATRValues[key], key);
  }

  return {
    errors,
    isValid: fv.isValid(),
  };
};
