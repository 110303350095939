import { YourMoneyStepDefinition } from "./YourMoneyStepTypes";

export const template: YourMoneyStepDefinition = {
  provisionForm: {
    type: "group",
    content: {
      fields: {
        type: "group",
        content: {
          typeOfHolding: {
            type: "list",
          },
          whoBelongTo: {
            type: "list",
          },
          whoIsItHeldWith: {
            type: "list",
          },
          whoIsItHeldWithOther: {
            type: "string",
          },
          productType: {
            type: "list",
          },
          isPolicyReferenceNumberKnown: {
            type: "list",
          },
          policyReferenceNumber: {
            type: "string",
          },
          howMuchItWorth: {
            type: "number",
          },
        },
      },
    },
  },
};
