import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import { Layout } from "../layout/Layout";
import "./FinancialAdvicePage.scss";
import { Video } from "../video/Video";
import Card from "../card/Card";
import { getAboutAdviserRoute, getOurAdviceRoute } from "../../helpers";
import { useHistory } from "react-router-dom";
import { Button } from "@saturn-ui/components";
import { useAppDispatch } from "../../redux/store";
import { RootState, useRootSelector } from "skipton-features";
import { FinancialAdvicePageStates } from "../../feature/financialAdvicePage/FinancialAdvicePageTypes";
import { getAdviser } from "../../feature/financialAdvicePage/thunks";
import { useSelector } from "react-redux";
import { AppState } from "../../types/AppState";
import {
  VIDEO_INTEGRATION_ADVISER_DEFAULT_ID,
  VIDEO_INTEGRATION_CONTENT_TYPE_PROFILES,
} from "../../feature/videoIntegration/VideoIntegrationConstants";
import { VideoIntegrationProfilesContent } from "../../feature/videoIntegration/VideoIntegrationTypes";
import { setVideo } from "../../feature/financialAdvicePage/FinancialAdvicePageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
} from "../../feature/financialAdvicePage/FinancialAdvicePageConstants";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../helpers/adobeAnalyticsHelpers";

export const FinancialAdvicePage: FC = () => {
  const history = useHistory() as unknown[];
  const dispatch = useAppDispatch();
  const { APIUri, isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const {
    financialAdvicePage: {
      adviser,
      video,
      clientInfo: {
        salutation: {
          [CLIENT_1_SCOPE_NAME]: client1Salutation,
          [CLIENT_2_SCOPE_NAME]: client2Salutation,
        },
        category,
      },
    },
    videoIntegration: { content: videoIntegrationContent },
  } = useSelector((state: AppState) => state);

  const [, setFinancialAdvicePageState] = useState(
    FinancialAdvicePageStates.Uninitialised
  );

  const getVideoSrc = useCallback((adviserEmail?: string): string => {
    const profiles = videoIntegrationContent.find(
      (content) => content.type === VIDEO_INTEGRATION_CONTENT_TYPE_PROFILES
    );
    const defaultVideo =
      (profiles as VideoIntegrationProfilesContent).advisers.find(
        (profileAdviser) =>
          profileAdviser.id === VIDEO_INTEGRATION_ADVISER_DEFAULT_ID
      )?.video ?? "";

    if (adviserEmail) {
      return (
        (profiles as VideoIntegrationProfilesContent).advisers.find(
          (profileAdviser) =>
            profileAdviser.id.toUpperCase() === adviserEmail.toUpperCase()
        )?.video ?? defaultVideo
      );
    }

    return defaultVideo;
  }, []);

  const getAdviserInfo = async (): Promise<void> => {
    try {
      setFinancialAdvicePageState(FinancialAdvicePageStates.Initialising);

      const response = await dispatch(
        getAdviser({
          baseUrl: APIUri ?? "",
        })
      );

      const { email } = unwrapResult(response);

      void dispatch(setVideo(getVideoSrc(email)));

      setFinancialAdvicePageState(FinancialAdvicePageStates.Success);
    } catch (error) {
      void dispatch(setVideo(getVideoSrc()));

      setFinancialAdvicePageState(FinancialAdvicePageStates.Error);
    }
  };

  useEffect(() => {
    if (adviser === null) {
      void getAdviserInfo();
    }
  }, []);

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = getVirtualPageCustomerType(category);

      trackVirtualPageView({
        pageName: "Welcome to Skipton FA",
        customerType,
      });
    }
  }, [isAdobeAnalyticsLoaded]);

  const handlePressMoreInformation = useCallback(() => {
    history.push(getAboutAdviserRoute());
  }, [history]);

  const handlePressStart = useCallback(() => {
    history.push(getOurAdviceRoute());
  }, [history]);

  const getHello = (): string => {
    let helloToShow = "Hello";
    if (client1Salutation) {
      helloToShow += ` ${client1Salutation.forename || ""}`;

      if (client2Salutation) {
        helloToShow += ` & ${client2Salutation.forename || ""}`;
      }
    }
    return helloToShow;
  };

  return (
    <Layout>
      <div className="financial-advice-page">
        <h1 className="page-title financial-advice-page-title">
          <span>{getHello()}</span>
          <br />
          You are ready to begin your fact find
        </h1>
        <div className="hello">
          <p>Your financial adviser wants to say hello!</p>
          <p>
            Please watch this short video from your adviser, so you can start to
            get to know them better. They also explain why they need key
            information from you now.
          </p>
        </div>

        <Video src={video} />

        <Card className="financial-adviser-card">
          <Card.Header className="financial-adviser-card-header">
            <h2 className="financial-adviser-card-title">
              About your Financial Adviser
            </h2>
          </Card.Header>
          <Card.Content className="financial-adviser-card-content">
            <span>
              When it comes to your finances, there is plenty to consider. Being
              able to openly discuss the different options available, in a way
              you can understand, value and trust, is really important. That is
              why I am really looking forward to our meeting.
            </span>
            <div className="financial-adviser-card-actions">
              <Button
                id="welcome-page-start-button"
                className="decorated-button welcome-page-start-button"
                onClick={handlePressMoreInformation}
                variant="outlined"
                disableRipple={true}
              >
                <span className="decoration">More about your adviser</span>
              </Button>
            </div>
          </Card.Content>
        </Card>

        <h2 className="financial-advice-page-how-it-work-title page-title ">
          How does your fact find work?
        </h2>

        <div className="financial-advice-page-steps" role="list">
          <div role="listitem">
            <Card className="step-card">
              <Card.Content className="step-card-content">
                <div className="step-card-number">
                  <span className="step-card-number-label">1</span>
                </div>
                <div className="step-card-content-wrapper">
                  <h3 className="step-card-title">Our advice approach</h3>
                  <p className="step-card-description">
                    Firstly, we will tell you a bit more about our service and
                    the things we can advise you on.
                  </p>
                </div>
              </Card.Content>
            </Card>
          </div>
          <div role="listitem">
            <Card className="step-card">
              <Card.Content className="step-card-content">
                <div className="step-card-number">
                  <span className="step-card-number-label">2</span>
                </div>
                <div className="step-card-content-wrapper">
                  <h3 className="step-card-title">About you</h3>
                  <p className="step-card-description">
                    Next, we will ask you to provide some basic information
                    about yourself.
                  </p>
                </div>
              </Card.Content>
            </Card>
          </div>
          <div role="listitem">
            <Card className="step-card">
              <Card.Content className="step-card-content">
                <div className="step-card-number">
                  <span className="step-card-number-label">3</span>
                </div>
                <div className="step-card-content-wrapper">
                  <h3 className="step-card-title">Your Money</h3>
                  <p className="step-card-description">
                    This section covers your current savings, investment and
                    pension arrangements. This is where we will ask you to
                    upload documents related to your holdings.
                  </p>
                </div>
              </Card.Content>
            </Card>
          </div>
          <div role="listitem">
            <Card className="step-card">
              <Card.Content className="step-card-content">
                <div className="step-card-number">
                  <span className="step-card-number-label">4</span>
                </div>
                <div className="step-card-content-wrapper">
                  <h3 className="step-card-title">
                    Your feelings towards risk and reward
                  </h3>
                  <p className="step-card-description">
                    The final step for now is for us to begin to understand your
                    personal feelings towards risk and reward. There is a
                    questionnaire for you to complete.
                  </p>
                </div>
              </Card.Content>
            </Card>
          </div>
        </div>
        <Button
          className="financial-advice-page-start-button decorated-button"
          variant="contained"
          id="continue-step-one-button"
          disableRipple={true}
          onClick={handlePressStart}
        >
          <span className="decoration">I am ready to go to step 1</span>
        </Button>
      </div>
    </Layout>
  );
};

export default FinancialAdvicePage;
