import {
  AboutYouFormState,
  AboutYouStepState,
  PropertyFormState,
} from "./AboutYouStepTypes";
import {
  ABOUT_YOU_FORM_MARITAL_STATUS_FIELD,
  ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD,
  ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD,
  ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD,
  ABOUT_YOU_FORM_TAX_STATUS_FIELD,
  ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD,
  ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD,
  ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD,
  YOUR_PROPERTY_SCOPE_NAME,
  ABOUT_YOU_SCOPE_NAME,
} from "./AboutYouStepConstants";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
} from "../../../financialAdvicePage/FinancialAdvicePageConstants";
import { YourPropertySummaryComponent } from "../../../../components/journeySteps/steps/aboutYouStep/yourPropertyContent/yourPropertySummaryComponent/YourPropertySummaryComponent";
import { YouPropertyDetailsComponent } from "../../../../components/journeySteps/steps/aboutYouStep/yourPropertyContent/youPropertyDetailsComponent/YouPropertyDetailsComponent";
import { YourPropertySavedDetailsComponent } from "../../../../components/journeySteps/steps/aboutYouStep/yourPropertyContent/yourPropertySavedDetailsComponent/YourPropertySavedDetailsComponent";

export const defaultAboutYouFormState: AboutYouFormState = {
  [ABOUT_YOU_FORM_MARITAL_STATUS_FIELD]: null,
  [ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD]: null,
  [ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD]: null,
  [ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD]: null,
  [ABOUT_YOU_FORM_TAX_STATUS_FIELD]: null,
  [ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD]: null,
  [ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD]: null,
};

export const defaultAboutYouStepPropertyState: PropertyFormState = {
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]: null,
  [ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]: null,
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD]: null,
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD]: null,
  [ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]: null,
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]: null,
  [ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]: null,
  [ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]: null,
};

export const defaultProperty = {
  SummaryComponent: YourPropertySummaryComponent,
  DetailsComponent: YouPropertyDetailsComponent,
  SavedDetailsComponent: YourPropertySavedDetailsComponent,
  isSaved: false,
  canContinue: false,
  expanded: true,
  values: defaultAboutYouStepPropertyState,
};

export const defaultAboutYouStepFormState: AboutYouStepState = {
  [ABOUT_YOU_SCOPE_NAME]: {
    [CLIENT_1_SCOPE_NAME]: defaultAboutYouFormState,
    [CLIENT_2_SCOPE_NAME]: defaultAboutYouFormState,
  },
  [YOUR_PROPERTY_SCOPE_NAME]: {
    [ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD]: null,
  },
};
