import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCaseApi } from "skipton-common";
import { AppState } from "../../../../../types/AppState";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";

export const postCompleteJourney = createAsyncThunk<
  void,
  {
    baseUrl: string;
  },
  { state: AppState }
>("post-party-personal", async (data: { baseUrl: string }): Promise<void> => {
  const { baseUrl } = data;
  const caseApi = withSessionId(getCaseApi(baseUrl));
  await caseApi.postCompleteJourney();
});

export const postCompleteJourneySuccess = postCompleteJourney.fulfilled;
export const postCompleteJourneyPending = postCompleteJourney.pending;
export const postCompleteJourneyFailed = postCompleteJourney.rejected;
