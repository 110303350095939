import React from "react";
import { FC } from "react";
import "./Modal.scss";

export type ModalProps = {
  className?: string;
  children: React.ReactNode;
};

type ModalSubComponents = {
  InPage: typeof InPage;
};

export const Modal: FC<ModalProps> & ModalSubComponents = ({
  children,
  className,
}) => {
  return <div className={`modal-wrapper ${className || ""}`}>{children}</div>;
};

const InPage: FC = ({ children }) => {
  return (
    <div data-testid="modal-in-page" className="modal-wrapper">
      {children}
    </div>
  );
};

Modal.InPage = InPage;

export default Modal;
