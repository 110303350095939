import React, { FC } from "react";
import { YourMoneyStepState } from "../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";
import {
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_SCOPE_NAME,
} from "../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepConstants";
import { DetailsListComponent } from "./detailsListComponent/DetailsListComponent";
import { UploadStatementsComponent } from "./uploadStatementsComponent/UploadStatementsComponent";
import "./ProvisionSavedDetailsComponent.scss";
import { SavingsDetailsListComponent } from "./savingsDetailsListComponent/SavingsDetailsListComponent";

type Props = {
  values: YourMoneyStepState;
  setValuesAndAllowContinue: (values: YourMoneyStepState) => void;
};

export const ProvisionSavedDetailsComponent: FC<Props> = ({
  values,
  setValuesAndAllowContinue,
}) => {
  const isSavings = !!(
    values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING] &&
    values[PROVISION_SCOPE_NAME][PROVISION_FORM_TYPE_OF_HOLDING]?.id ===
      "Saving"
  );

  return (
    <div className="provision-saved-details-component">
      {isSavings && <SavingsDetailsListComponent values={values} />}
      {!isSavings && (
        <div className="details-and-upload">
          <div className="column">
            <DetailsListComponent values={values} />
          </div>
          <div className="column">
            <UploadStatementsComponent
              values={values}
              setValuesAndAllowContinue={setValuesAndAllowContinue}
            />
          </div>
        </div>
      )}
    </div>
  );
};
