import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const ownershipJointClientList = (
  client1Forename: string,
  client2Forename: string
): List => ({
  listType: "ownershipJointClient",
  lang: "en-GB",
  items: [
    {
      id: "Equally",
      displayText: "We each own 50% of the property",
    },
    {
      id: "Client1",
      displayText: `${client1Forename} owns the property`,
    },
    {
      id: "Client2",
      displayText: `${client2Forename} owns the property`,
    },
    {
      id: "Other",
      displayText: "Other",
    },
  ],
});
