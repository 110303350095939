import React, { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { StylesProvider } from "@mui/styles";
import { ErrorBoundary } from "skipton-common";
import { RootStoreProvider } from "skipton-features";
import store from "./redux/store";
import Logo from "./components/logo/Logo";
import { AppContent } from "./AppContent";
import { rootStore } from "./fsg-saturn-skipton-customer-fact-find";
import { SaturnUIThemeProvider } from "@saturn-ui/foundations";
import { skiptonCFFLightTheme } from "./theme/skiptonLightThemeOverrides";
import { hot } from "react-hot-loader/root";
import { setConfig } from "react-hot-loader";
import { OverlayProvider } from "./contexts/OverlayContext";

const Root: FC = () => {
  const config = store.getState().configuration.data;
  const errorPageDefinition = config.definitions.errorPage;

  return (
    <RootStoreProvider rootStore={rootStore}>
      <Provider store={store}>
        <StylesProvider injectFirst>
          <Router>
            <ErrorBoundary definition={errorPageDefinition} TopLogo={Logo}>
              <SaturnUIThemeProvider theme={skiptonCFFLightTheme}>
                <OverlayProvider>
                  <AppContent />
                </OverlayProvider>
              </SaturnUIThemeProvider>
            </ErrorBoundary>
          </Router>
        </StylesProvider>
      </Provider>
    </RootStoreProvider>
  );
};

setConfig({
  reloadHooks: true,
});
export default hot(Root);
