import React from "react";
import { Button, Dialog, IconButton } from "@saturn-ui/components";
import "./LogoutConfirmModal.scss";
import { CloseIcon } from "../../assets/icons/CloseIcon";

type Props = {
  onCancelClick?: () => void;
  onQuitClick?: () => void;
};
export const LogoutConfirmDialog: React.FC<Props> = ({
  onCancelClick,
  onQuitClick,
}) => {
  return (
    <Dialog isOpen={true} onClose={onCancelClick} className="auth-dialog">
      <IconButton
        className="dialog-close-button"
        ariaLabel={"Close"}
        onClick={onCancelClick}
      >
        <CloseIcon />
      </IconButton>
      <h2 className="dialog-title">Are you sure you want to quit?</h2>
      <p>
        The information you have completed on this page so far has not been
        saved. You have to complete the full step for this information to be
        saved and shared with us.
      </p>
      <p>
        Select ‘Cancel’ to carry on completing your information. If you want to
        exit without saving your progress, select ‘Exit’.
      </p>
      <div className="dialog-actions-wrapper">
        <Button
          className="decorated-button"
          onClick={onCancelClick}
          variant="outlined"
          id="cancel-button"
          disableRipple={true}
        >
          <span className="decoration">Cancel</span>
        </Button>
        <Button
          className="decorated-button"
          onClick={onQuitClick}
          variant="contained"
          id="logout-button"
          disableRipple={true}
        >
          <span className="decoration">Exit</span>
        </Button>
      </div>
    </Dialog>
  );
};
