import { useCallback, useContext, useEffect, useState } from "react";
import { RootState, useRootSelector } from "skipton-features";
import {
  login,
  logout,
  refresh,
  resetUserData,
} from "../feature/authentication";
import { OverlayContext } from "../contexts/OverlayContext";
import { useAppDispatch } from "../redux/store";
import {
  LoginResponseModel,
  RefreshResponseModel,
} from "skipton-common/lib/api/utils/authentication";
import { PayloadAction } from "@reduxjs/toolkit";
import { SessionExpiredDialog } from "../components/sessionExpiredDialog/SessionExpiredDialog";
import { LogoutConfirmDialog } from "../components/logoutConfirmModal/LogoutConfirmDialog";
import { SessionEvents, SessionService } from "../classes/SessionService";
import { ErrorDialog } from "../components/errorDialog/ErrorDialog";
import { selectIsRefreshFailed } from "../redux/selectors/authentication";
import { useSelector } from "react-redux";
import { resetToInitialValues as resetAboutYouStepToInitialValues } from "../feature/journeySteps/steps/aboutYouStep/AboutYouStepSlice";
import { resetToInitialValues as resetOurAdviceStepToInitialValues } from "../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepSlice";
import { resetToInitialValues as resetFinancialAdvicePageToInitialValues } from "../feature/financialAdvicePage/FinancialAdvicePageSlice";
import { resetToInitialValues as resetYourMoneyStepToInitialValues } from "../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepSlice";
import { useHistory } from "react-router-dom";
import {
  persistableSectionsRoutes,
  SectionStatus,
} from "./useSaveAndResumeLogic";
import { getCFFSectionCustomData } from "../feature/customData/thunks";
import { CaseApi, HttpRequestError } from "skipton-common";
import { getSalutation } from "../feature/financialAdvicePage/thunks";
import {
  getErrorRoute,
  getFinancialAdviceRoute,
  getYourFeelingsRoute,
} from "../helpers";
import { resetState as resetYourFeelingsStepState } from "../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepSlice";
import { resetState as resetCustomDataState } from "../feature/customData/CustomDataSlice";
import every from "lodash/every";
import { getCFFCustomData } from "../feature/customData/thunks/GetCFFCustomData";

export enum InitializeUserData {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

type AuthLogicReturnType = {
  handleLogout: () => void;
  handleLogin: (id: string) => void;
  handleSessionStop: () => void;
  onPressLogout: () => void;
  initializeUserDataState: InitializeUserData;
  checkInitializeUserDataState: (...states: InitializeUserData[]) => boolean;
};

const findStartedSection = (sections: CaseApi.CFFSection[]) =>
  sections.find((section) => section.status === SectionStatus.Started);

const checkAllSectionCompleted = (sections: CaseApi.CFFSection[]) =>
  every(sections, (section) => section.status === SectionStatus.Completed);

export const useAuthLogic = (): AuthLogicReturnType => {
  const isRefreshFailed = useSelector(selectIsRefreshFailed);
  const history = useHistory() as unknown[];
  const dispatch = useAppDispatch();
  const { hideOverlay, showOverlay } = useContext(OverlayContext);
  const { APIUri } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const [initializeUserDataState, setInitializeUserDataState] = useState(
    InitializeUserData.Uninitialised
  );

  const checkInitializeUserDataState = (...states: InitializeUserData[]) =>
    !!states.find((state) => state === initializeUserDataState);

  const handleSessionStop = useCallback(() => {
    //reset redux store
    dispatch(resetUserData());
    dispatch(resetFinancialAdvicePageToInitialValues());
    dispatch(resetOurAdviceStepToInitialValues());
    dispatch(resetAboutYouStepToInitialValues());
    dispatch(resetYourMoneyStepToInitialValues());
    dispatch(resetYourFeelingsStepState());
    dispatch(resetCustomDataState());

    SessionService.stop();
  }, [dispatch]);

  const handleLogout = useCallback(async () => {
    hideOverlay();
    try {
      await dispatch(logout({ baseUrl: APIUri || "" })).unwrap();
    } catch (error) {
      history.push({
        pathname: getErrorRoute(),
        state: { errorCode: (error as HttpRequestError).code },
      });
    } finally {
      handleSessionStop();
    }
  }, [hideOverlay, handleSessionStop, dispatch, APIUri, history]);

  const handleRefreshError = useCallback(() => {
    showOverlay({
      content: ErrorDialog,
      props: {
        title: "Something went wrong",
        description:
          "An error occurred while refreshing your session. Please log in again.",
        onClose: handleLogout,
      },
    });
    SessionService.stop();
  }, [handleLogout, showOverlay]);

  const handleRefresh = useCallback(async () => {
    const { payload } = (await dispatch(
      refresh({ baseUrl: APIUri || "" })
    )) as PayloadAction<RefreshResponseModel>;
    payload?.expiry && SessionService.restart(new Date(payload?.expiry));
  }, [APIUri, dispatch]);

  const handleContinueSession = useCallback(async () => {
    hideOverlay();
    await handleRefresh();
  }, [hideOverlay, handleRefresh]);

  const openSessionExpiredModal = useCallback(() => {
    showOverlay({
      content: SessionExpiredDialog,
      props: {
        onContinueClick: handleContinueSession,
        onClose: handleContinueSession,
        secondsToLogout: SessionService.secondsToSessionExpiration,
      },
    });
  }, [showOverlay, handleContinueSession]);

  const setupHandlers = useCallback(() => {
    SessionService.attachHandler(
      SessionEvents.INACTIVITY_WAITING,
      openSessionExpiredModal
    );
    SessionService.attachHandler(
      SessionEvents.INACTIVITY_INTERRUPTED,
      handleRefresh
    );
    SessionService.attachHandler(SessionEvents.SESSION_EXPIRED, handleLogout);
  }, [handleLogout, handleRefresh, openSessionExpiredModal]);

  const navigateToStartedSection = useCallback(
    (sections: CaseApi.CFFSection[]) => {
      if (!sections?.length) {
        history.push(getFinancialAdviceRoute());
        return;
      }

      // Redirect to Started Section
      const firstStartedSection = findStartedSection(sections);
      if (
        firstStartedSection &&
        persistableSectionsRoutes[firstStartedSection.name]
      ) {
        history.push(persistableSectionsRoutes[firstStartedSection.name]);
        return;
      }

      // Redirect to ATR if all sections is completed but journey not completed
      const allSectionsCompleted = checkAllSectionCompleted(sections);
      if (allSectionsCompleted) {
        history.push({
          pathname: getYourFeelingsRoute(),
          state: { allSectionsCompleted },
        });
        return;
      }

      history.push(getFinancialAdviceRoute());
    },
    [history]
  );

  const handleLogin = useCallback(
    async (id: string) => {
      setInitializeUserDataState(InitializeUserData.Initialising);
      const { payload } = (await dispatch(
        login({
          baseUrl: APIUri || "",
          loginPayload: { passCode: id },
        })
      )) as PayloadAction<LoginResponseModel>;
      const isLoggedIn = payload?.sessionId !== undefined;

      if (isLoggedIn) {
        setupHandlers();
        SessionService.start(new Date(payload?.expiry));

        try {
          await dispatch(
            getSalutation({
              baseUrl: APIUri ?? "",
            })
          ).unwrap();

          await dispatch(
            getCFFCustomData({
              baseUrl: APIUri ?? "",
            })
          ).unwrap();

          const payload = await dispatch(
            getCFFSectionCustomData({ baseUrl: APIUri ?? "" })
          ).unwrap();

          setInitializeUserDataState(InitializeUserData.Success);
          navigateToStartedSection(payload);
        } catch (error) {
          history.push({
            pathname: getErrorRoute(),
            state: { errorCode: (error as HttpRequestError).code },
          });
          setInitializeUserDataState(InitializeUserData.Error);
        }
      }

      setInitializeUserDataState(InitializeUserData.Uninitialised);
    },
    [dispatch, APIUri, setupHandlers, navigateToStartedSection, history]
  );

  const onPressLogout = useCallback(() => {
    showOverlay({
      content: LogoutConfirmDialog,
      props: {
        onCancelClick: hideOverlay,
        onQuitClick: handleLogout,
      },
    });
  }, [showOverlay, hideOverlay, handleLogout]);

  useEffect(() => {
    isRefreshFailed && handleRefreshError();
  }, [handleRefreshError, isRefreshFailed]);

  return {
    handleLogout,
    handleLogin,
    handleSessionStop,
    onPressLogout,
    initializeUserDataState,
    checkInitializeUserDataState,
  };
};
