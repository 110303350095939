import React, { FC, useCallback, useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import { Layout } from "../../../layout/Layout";
import {
  OurAdviceStepDefinition,
  OurAdviceStepFormErrors,
  OurAdviceStepFormState,
  OurAdviceStepFormTouched,
} from "../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepTypes";
import { useAppDispatch } from "../../../../redux/store";
import {
  FormContextProvider,
  FormsConfiguration,
  PuffLoader,
  useFormContainer,
  useFormsConfiguration,
  useLocalization,
} from "@saturn-ui/components";
import { useSelector } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { validate } from "../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepValidation";
import { useHistory } from "react-router-dom";
import {
  getAboutYouRoute,
  getErrorRoute,
  getFinancialAdviceRoute,
} from "../../../../helpers";
import "../../JourneySteps.scss";
import "./OurAdviceStep.scss";
import { setValues } from "../../../../feature/journeySteps/steps/ourAdviceStep/OurAdviceStepSlice";
import { VIDEO_INTEGRATION_VIDEOS_ADV_ID } from "../../../../feature/videoIntegration/VideoIntegrationConstants";
import { Video } from "../../../video/Video";
import { getVideoData } from "../../JourneySteps.utils";
import { postCFFCustomData } from "../../../../feature/customData/thunks/PostCustomDataCFF";
import { RootState, useRootSelector } from "skipton-features";
import {
  OurAdviceStepStates,
  SendCFFCustomDataStates,
} from "../../../../feature/customData/CustomDataTypes";
import {
  PersistableSections,
  useSaveAndResumeLogic,
} from "../../../../hooks/useSaveAndResumeLogic";
import { useOnMount } from "../../../../hooks/useOnMount";
import {
  HTTP_UNAUTHORIZED_STATUS_CODE,
  HttpRequestError,
} from "skipton-common";
import { ErrorRetry } from "../../../errorRetry/ErrorRetry";
import { OurAdviceStepFormContent } from "./ourAdviceStepFormContent/OurAdviceStepFormContent";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../../../helpers/adobeAnalyticsHelpers";

type Props = {
  definition: OurAdviceStepDefinition;
};

export const OurAdviceStep: FC<Props> = ({ definition }) => {
  const history = useHistory() as unknown[];
  const dispatch = useAppDispatch();
  const { APIUri, isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const { commitStepStarted, commitStepCompleted } = useSaveAndResumeLogic(
    PersistableSections.OurAdvice
  );
  const [sendCFFCustomDataState, setSendCFFCustomDataState] = useState(
    SendCFFCustomDataStates.Uninitialised
  );
  const [ourAdviceStepState, setOurAdviceStepState] = useState(
    OurAdviceStepStates.Uninitialised
  );

  const { validation } = useFormsConfiguration<FormsConfiguration>();
  const localization = useLocalization();
  const {
    ourAdvice: { initialValues },
    videoIntegration: { content: videoIntegrationContent },
    financialAdvicePage: {
      clientInfo: { category },
    },
  } = useSelector((state: AppState) => state);

  const initialisePage = async () => {
    try {
      setOurAdviceStepState(OurAdviceStepStates.Initialising);

      await commitStepStarted();

      setOurAdviceStepState(OurAdviceStepStates.Success);
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setOurAdviceStepState(OurAdviceStepStates.Error);
    }
  };

  useOnMount(() => void initialisePage());

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = getVirtualPageCustomerType(category);

      trackVirtualPageView({
        pageName: "Our advice",
        customerType,
      });
    }
  }, [isAdobeAnalyticsLoaded]);

  const formContainer = useFormContainer<
    OurAdviceStepFormState,
    OurAdviceStepFormErrors,
    OurAdviceStepFormTouched
  >({
    initialValues: initialValues,
    validateFunc: (ourAdvice) =>
      validate(ourAdvice, definition.form.content, validation, localization),
    enableReinitialize: true,
  });

  const { values } = formContainer;

  const checkOurAdviceStepState = (...states: OurAdviceStepStates[]) =>
    !!states.find((state) => state === ourAdviceStepState);

  const sendAllCustomData = async (): Promise<void> => {
    try {
      setSendCFFCustomDataState(SendCFFCustomDataStates.Initialising);

      await dispatch(
        postCFFCustomData({
          baseUrl: APIUri ?? "",
        })
      ).unwrap();

      await commitStepCompleted();

      setSendCFFCustomDataState(SendCFFCustomDataStates.Success);
      history.push(getAboutYouRoute());
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setSendCFFCustomDataState(SendCFFCustomDataStates.Error);
    }
  };

  const handleBackClick = useCallback(
    (values) => {
      history.push(getFinancialAdviceRoute());
      dispatch(setValues(values));
    },
    [dispatch, history]
  );

  const videoData = getVideoData(
    videoIntegrationContent,
    VIDEO_INTEGRATION_VIDEOS_ADV_ID
  );

  const renderOurAdviceContent = () => {
    if (checkOurAdviceStepState(OurAdviceStepStates.Initialising)) {
      return (
        <div className="journey-step-preloader-wrapper">
          <PuffLoader />
        </div>
      );
    }

    if (checkOurAdviceStepState(OurAdviceStepStates.Error)) {
      return (
        <>
          <div className="left-side journey-step-error-retry-wrapper">
            <ErrorRetry handleRetryButtonClick={initialisePage} />
          </div>
          <div className="right-side" />
        </>
      );
    }

    return (
      <>
        <div className="left-side">
          <div className="step-pagination" aria-hidden="true">
            Step 1 of 4
          </div>
          <h1 className="step-title">Our advice approach</h1>
          <p>
            At Skipton Building Society, we have chosen to offer restricted
            financial advice. But what exactly does this mean?
          </p>
          <p>
            Please watch this short video which explains our advice approach –
            and the services we offer
          </p>

          <Video src={videoData?.video} description={videoData?.description} />
        </div>
        <div className="right-side journey-step-bg">
          <Modal.InPage>
            <FormContextProvider value={formContainer}>
              <form
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
                noValidate
              >
                <OurAdviceStepFormContent
                  definition={definition}
                  sendCFFCustomDataState={sendCFFCustomDataState}
                  handleContinueClick={sendAllCustomData}
                />
              </form>
            </FormContextProvider>
          </Modal.InPage>
        </div>
      </>
    );
  };

  return (
    <Layout
      backButtonText="Back"
      onBackPress={() => handleBackClick(values)}
      alwaysHavePadding={true}
      progressStepperProps={{
        numberOfSteps: 4,
        active: 1,
        maxStepDone: 1,
      }}
    >
      <div className="journey-step our-advice-step">
        {renderOurAdviceContent()}
      </div>
    </Layout>
  );
};
