import * as React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

export const SelectArrowIcon: React.FC<Props> = ({
  className,
  height = 20,
  width = 20,
}) => (
  <SvgIcon
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <g clipPath="url(#clip0_3834_1019)">
      <path
        d="M6.175 7.1582L10 10.9749L13.825 7.1582L15 8.3332L10 13.3332L5 8.3332L6.175 7.1582Z"
        fill="#424B5A"
      />
    </g>
    <defs>
      <clipPath id="clip0_3834_1019">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
