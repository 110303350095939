export const YOUR_FEELINGS_STEP_ART = "atr";

export const YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD = "confirmWatchVideo";
export const YOUR_FEELINGS_FORM_IS_QUESTION_FIELD = "isQuestion";
export const YOUR_FEELINGS_FORM_QUESTION_FIELD = "question";

export const yourFeelingsFieldKeys = [
  YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  YOUR_FEELINGS_FORM_IS_QUESTION_FIELD,
  YOUR_FEELINGS_FORM_QUESTION_FIELD,
];
