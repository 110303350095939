function isFunctionDescriptor(
  name: string,
  descriptor: PropertyDescriptor
): boolean {
  return typeof descriptor.value === "function" && name !== "constructor";
}

export function bindAllClass(target: unknown): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const proto = Object.getPrototypeOf(target);
  const protoPropertyDescriptors = Object.getOwnPropertyDescriptors(proto);

  const classMethods = Object.entries(protoPropertyDescriptors).filter(
    ([name, value]) => isFunctionDescriptor(name, value)
  );

  const descriptorsMap: Record<PropertyKey, PropertyDescriptor> =
    classMethods.reduce((map, [name, descriptor]) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      descriptor.value = descriptor?.value?.bind(target);
      map[name] = descriptor;

      return map;
    }, {} as Record<PropertyKey, PropertyDescriptor>);

  Object.defineProperties(proto, descriptorsMap);
}
