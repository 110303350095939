import { FinancialAdvicePageMeta } from "./FinancialAdvicePageTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getAdviserSuccess, getSalutationSuccess } from "./thunks";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
  CLIENT_CATEGORY_JOINT,
  CLIENT_CATEGORY_SINGLE,
} from "./FinancialAdvicePageConstants";
import { defaultClientInfo } from "./FinancialAdvicePageInitialState";

const defaultState: FinancialAdvicePageMeta = {
  adviser: null,
  video: undefined,
  clientInfo: defaultClientInfo,
};

const financialAdvicePageSlice = createSlice({
  name: "financialAdvicePage",
  initialState: defaultState,
  reducers: {
    setVideo: (state, action: PayloadAction<string>) => {
      state.video = action.payload;
    },
    resetToInitialValues: (state) => {
      state.video = undefined;
      state.adviser = null;
      state.clientInfo = defaultClientInfo;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdviserSuccess, (state, action) => {
      state.adviser = action.payload;
    });
    builder.addCase(getSalutationSuccess, (state, action) => {
      const [client1Salutation, client2Salutation] = action.payload;

      state.clientInfo.salutation[CLIENT_1_SCOPE_NAME] = client1Salutation;
      state.clientInfo.salutation[CLIENT_2_SCOPE_NAME] =
        client2Salutation ?? null;
      state.clientInfo.category =
        action.payload.length > 1
          ? CLIENT_CATEGORY_JOINT
          : CLIENT_CATEGORY_SINGLE;
    });
  },
});

export const { setVideo, resetToInitialValues } =
  financialAdvicePageSlice.actions;
export default financialAdvicePageSlice.reducer;
