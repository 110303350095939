import React, { FC, useCallback, useEffect, useRef } from "react";
import {
  AboutYouFormScope,
  AboutYouStepDefinition,
  AboutYouStepErrors,
  AboutYouStepState,
  AboutYouStepTouched,
  GetTaxStatusListStates,
  SendPartyPersonalStates,
} from "../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepTypes";
import { isEmpty } from "lodash";
import { useAppDispatch } from "../../../../../redux/store";
import {
  Button,
  CurrencyField,
  FieldRenderer,
  FieldUpdateEventArgs,
  ListField,
  ListItem,
  RadioGroupInput,
  SubmitButton,
  TextField,
  useFormContext,
  useLocalization,
} from "@saturn-ui/components";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { setAboutYouValues } from "../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepSlice";
import {
  ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD,
  ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD,
  ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD,
  ABOUT_YOU_FORM_MARITAL_STATUS_FIELD,
  ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD,
  ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD,
  ABOUT_YOU_FORM_TAX_STATUS_FIELD,
  ABOUT_YOU_SCOPE_NAME,
  ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST,
  ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST,
  aboutYouStepFieldKeys,
} from "../../../../../feature/journeySteps/steps/aboutYouStep/AboutYouStepConstants";
import { employmentList, fullISAAllowanceList } from "../aboutYouStepLists";
import { handleTaxJurisdictionFieldChange } from "../AboutYouStep.utils";
import { ClientScopeName } from "../../../../../feature/financialAdvicePage/FinancialAdvicePageTypes";
import clsx from "clsx";
import {
  PersistableSections,
  useSaveAndResumeLogic,
} from "../../../../../hooks/useSaveAndResumeLogic";
import { RootState, useRootSelector } from "skipton-features";
import { getTaxStatusList } from "../../../../../feature/journeySteps/steps/aboutYouStep/thunks";
import { ErrorRetry } from "../../../../errorRetry/ErrorRetry";
import { getErrorRoute } from "../../../../../helpers";
import {
  HTTP_UNAUTHORIZED_STATUS_CODE,
  HttpRequestError,
} from "skipton-common";
import { useHistory } from "react-router-dom";
import {
  focusOnInputById,
  getFirstErrorKey,
} from "../../../../../utils/formUtils";

type Props = {
  scope: AboutYouFormScope;
  clientScopeName: ClientScopeName;
  definition: AboutYouStepDefinition;
  title: string;
  salutation: string;
  checkSendPartyPersonalState: (
    ...states: SendPartyPersonalStates[]
  ) => boolean;
  handleContinueClick: (isDialogShown: boolean) => void;
  handleBackClick?: () => void;
  setGetTaxStatusListState: React.Dispatch<
    React.SetStateAction<GetTaxStatusListStates>
  >;
  checkGetTaxStatusListState: (...states: GetTaxStatusListStates[]) => boolean;
};

export const AboutYouContent: FC<Props> = ({
  definition,
  clientScopeName,
  scope,
  salutation,
  title,
  checkSendPartyPersonalState,
  handleContinueClick,
  handleBackClick,
  checkGetTaxStatusListState,
  setGetTaxStatusListState,
}) => {
  const history = useHistory() as unknown[];
  const dispatch = useAppDispatch();
  const { commitStepCompleted } = useSaveAndResumeLogic(
    PersistableSections.AboutYou
  );

  const localization = useLocalization();
  const { APIUri } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const {
    aboutYou: { lists, isJointCustomerDialogShown },
    financialAdvicePage: {
      clientInfo: { category },
    },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTaxStatus = async (taxJurisdiction: string) => {
    try {
      setGetTaxStatusListState(GetTaxStatusListStates.Initialising);

      await dispatch(
        getTaxStatusList({
          baseUrl: APIUri ?? "",
          taxJurisdiction,
        })
      ).unwrap();

      setGetTaxStatusListState(GetTaxStatusListStates.Success);
    } catch (error) {
      if ((error as HttpRequestError).code === HTTP_UNAUTHORIZED_STATUS_CODE) {
        history.push({
          pathname: getErrorRoute(),
          state: { errorCode: (error as HttpRequestError).code, category },
        });
      }
      setGetTaxStatusListState(GetTaxStatusListStates.Error);
    }
  };

  const formContext = useFormContext<
    AboutYouStepState,
    AboutYouStepErrors,
    AboutYouStepTouched
  >();

  const {
    touched,
    errors,
    handleFieldUpdate,
    values,
    validateForm,
    setFieldTouched,
    updateState,
  } = formContext;

  const formValues = useRef(values);

  useEffect(() => {
    formValues.current = values;
  }, [values]);

  const handlePressContinue = useCallback(() => {
    validateForm();
    setFieldTouched(
      `${ABOUT_YOU_SCOPE_NAME}.${clientScopeName}.${ABOUT_YOU_FORM_MARITAL_STATUS_FIELD}`
    );
    setFieldTouched(
      `${ABOUT_YOU_SCOPE_NAME}.${clientScopeName}.${ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD}`
    );
    setFieldTouched(
      `${ABOUT_YOU_SCOPE_NAME}.${clientScopeName}.${ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD}`
    );
    setFieldTouched(
      `${ABOUT_YOU_SCOPE_NAME}.${clientScopeName}.${ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD}`
    );
    setFieldTouched(
      `${ABOUT_YOU_SCOPE_NAME}.${clientScopeName}.${ABOUT_YOU_FORM_TAX_STATUS_FIELD}`
    );
    setFieldTouched(
      `${ABOUT_YOU_SCOPE_NAME}.${clientScopeName}.${ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD}`
    );
    setFieldTouched(
      `${ABOUT_YOU_SCOPE_NAME}.${clientScopeName}.${ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD}`
    );

    if (isEmpty(errors[ABOUT_YOU_SCOPE_NAME][clientScopeName])) {
      dispatch(setAboutYouValues(values[ABOUT_YOU_SCOPE_NAME]));
      handleContinueClick(isJointCustomerDialogShown);
    } else {
      const firstErrorKey = getFirstErrorKey(
        errors[ABOUT_YOU_SCOPE_NAME][clientScopeName],
        aboutYouStepFieldKeys
      );
      if (firstErrorKey) {
        focusOnInputById(
          `${ABOUT_YOU_SCOPE_NAME}-${clientScopeName}-${firstErrorKey}`,
          setFieldTouched
        );
      }
    }
  }, [
    validateForm,
    setFieldTouched,
    ABOUT_YOU_SCOPE_NAME,
    clientScopeName,
    errors,
    dispatch,
    values,
    commitStepCompleted,
    handleContinueClick,
    isJointCustomerDialogShown,
  ]);

  const handlePressPreviousPage = useCallback(() => {
    dispatch(setAboutYouValues(values[ABOUT_YOU_SCOPE_NAME]));
    handleBackClick && handleBackClick();
  }, [dispatch, handleBackClick, values]);

  const handleTaxJurisdictionFieldUpdate = useCallback(
    async (args: FieldUpdateEventArgs<ListItem>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      handleTaxJurisdictionFieldChange(
        updateState,
        setFieldTouched,
        clientScopeName
      );
      if (args.value) {
        await getTaxStatus(args.value.id);
      }
    },
    [getTaxStatus, handleFieldUpdate, setFieldTouched, updateState]
  );

  const getTaxStatusOptions = (): ListItem[] => {
    const taxJurisdiction =
      values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
        ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
      ];

    if (!taxJurisdiction) {
      return [];
    }

    if (taxJurisdiction.id === ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST) {
      return lists[ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST] || [];
    }

    if (taxJurisdiction.id === ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST) {
      return lists[ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST] || [];
    }

    return [];
  };

  const renderAboutYouFormContent = () => {
    return (
      <div>
        <div className="form-wrapper">
          <div className="column">
            <FieldRenderer
              name={ABOUT_YOU_FORM_MARITAL_STATUS_FIELD}
              definition={
                definition.form.content.fields.content[
                  ABOUT_YOU_FORM_MARITAL_STATUS_FIELD
                ]
              }
              scope={scope}
              render={(fieldProps) => (
                <ListField
                  {...fieldProps}
                  isLarge
                  options={lists[ABOUT_YOU_FORM_MARITAL_STATUS_FIELD] || []}
                  value={
                    values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                      ABOUT_YOU_FORM_MARITAL_STATUS_FIELD
                    ]
                  }
                  onUpdate={handleFieldUpdate}
                />
              )}
            />
            <FieldRenderer
              name={ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD}
              definition={
                definition.form.content.fields.content[
                  ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD
                ]
              }
              scope={scope}
              render={(fieldProps) => (
                <ListField
                  {...fieldProps}
                  isLarge
                  options={employmentList.items || []}
                  value={
                    values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                      ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD
                    ]
                  }
                  onUpdate={handleFieldUpdate}
                />
              )}
            />
            <FieldRenderer
              name={ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD}
              definition={
                definition.form.content.fields.content[
                  ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD
                ]
              }
              scope={scope}
              render={(fieldProps) => (
                <CurrencyField
                  {...fieldProps}
                  formatterName="Default"
                  currencyCode={localization.defaultCurrencyCode}
                  min={0}
                  isLarge
                  value={
                    values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                      ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD
                    ]
                  }
                  onUpdate={handleFieldUpdate}
                />
              )}
            />

            <div className="about-you-custom-error-wrapper">
              <fieldset
                id={`${ABOUT_YOU_SCOPE_NAME}-${clientScopeName}-${ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD}`}
              >
                <FieldRenderer
                  name={ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD}
                  definition={
                    definition.form.content.fields.content[
                      ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
                    ]
                  }
                  scope={scope}
                  render={(fieldProps) => (
                    <div className="radio-group-wrapper">
                      <span className="radio-group-label">
                        {
                          definition.form.content.fields.content[
                            ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
                          ].caption
                        }
                      </span>
                      {errors &&
                        errors[ABOUT_YOU_SCOPE_NAME] &&
                        errors[ABOUT_YOU_SCOPE_NAME][clientScopeName] &&
                        errors[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                          ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
                        ] &&
                        touched &&
                        touched[ABOUT_YOU_SCOPE_NAME] &&
                        touched[ABOUT_YOU_SCOPE_NAME][clientScopeName] &&
                        touched[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                          ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
                        ] && (
                          <div className="error-message">
                            Please answer this question
                          </div>
                        )}
                      <RadioGroupInput
                        {...fieldProps}
                        isVertical
                        isLarge
                        value={
                          values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                            ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
                          ]
                        }
                        options={
                          lists[ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD] || []
                        }
                        onUpdate={handleTaxJurisdictionFieldUpdate}
                      />
                    </div>
                  )}
                />
              </fieldset>
            </div>
          </div>
          <div className="column">
            <FieldRenderer
              name={ABOUT_YOU_FORM_TAX_STATUS_FIELD}
              definition={
                definition.form.content.fields.content[
                  ABOUT_YOU_FORM_TAX_STATUS_FIELD
                ]
              }
              scope={scope}
              render={(fieldProps) => (
                <ListField
                  {...fieldProps}
                  isLarge
                  options={getTaxStatusOptions()}
                  value={
                    values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                      ABOUT_YOU_FORM_TAX_STATUS_FIELD
                    ]
                  }
                  disabled={
                    values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                      ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
                    ] === null
                  }
                  onUpdate={handleFieldUpdate}
                />
              )}
            />
            <div className="about-you-custom-error-wrapper">
              <fieldset
                id={`${ABOUT_YOU_SCOPE_NAME}-${clientScopeName}-${ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD}`}
              >
                <FieldRenderer
                  name={ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD}
                  definition={
                    definition.form.content.fields.content[
                      ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD
                    ]
                  }
                  scope={scope}
                  render={(fieldProps) => (
                    <div className="radio-group-wrapper">
                      <span className="radio-group-label">
                        {
                          definition.form.content.fields.content[
                            ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD
                          ].caption
                        }
                      </span>
                      {errors &&
                        errors[ABOUT_YOU_SCOPE_NAME] &&
                        errors[ABOUT_YOU_SCOPE_NAME][clientScopeName] &&
                        errors[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                          ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD
                        ] &&
                        touched &&
                        touched[ABOUT_YOU_SCOPE_NAME] &&
                        touched[ABOUT_YOU_SCOPE_NAME][clientScopeName] &&
                        touched[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                          ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD
                        ] && (
                          <div className="error-message">
                            Please answer this question
                          </div>
                        )}
                      <RadioGroupInput
                        {...fieldProps}
                        isVertical
                        isLarge
                        value={
                          values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                            ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD
                          ]
                        }
                        options={fullISAAllowanceList.items || []}
                        onUpdate={handleFieldUpdate}
                      />
                    </div>
                  )}
                />
              </fieldset>
            </div>
            <FieldRenderer
              name={ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD}
              definition={
                definition.form.content.fields.content[
                  ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD
                ]
              }
              scope={scope}
              render={(fieldProps) => (
                <TextField
                  {...fieldProps}
                  isLarge
                  value={
                    values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                      ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD
                    ]
                  }
                  onUpdate={handleFieldUpdate}
                />
              )}
            />
          </div>
        </div>
        <div
          className={clsx("form-actions", {
            "justify-content-center": !handleBackClick,
          })}
        >
          {handleBackClick && (
            <Button
              className="about-you-button decorated-button"
              onClick={handlePressPreviousPage}
              variant="outlined"
              id="previous-button"
              disableRipple={true}
              color="primary"
            >
              <span className="decoration">Previous page</span>
            </Button>
          )}
          <div
            className={clsx("about-you-step-continue-button-wrapper", {
              error: checkSendPartyPersonalState(SendPartyPersonalStates.Error),
            })}
          >
            {checkSendPartyPersonalState(SendPartyPersonalStates.Error) && (
              <div className="error-message">
                Something went wrong. Please try again.
              </div>
            )}
            <SubmitButton
              className="about-you-button submit-button decorated-button"
              onClick={handlePressContinue}
              variant="contained"
              id="about-you-continue-button"
              disableRipple={true}
              color="primary"
              submitting={checkSendPartyPersonalState(
                SendPartyPersonalStates.Initialising
              )}
            >
              <span className="decoration">I am ready to continue</span>
            </SubmitButton>
          </div>
        </div>
      </div>
    );
  };

  const renderAboutYouContent = () => {
    if (checkGetTaxStatusListState(GetTaxStatusListStates.Error)) {
      return (
        <>
          <div className="left-side journey-step-error-retry-wrapper">
            <ErrorRetry
              handleRetryButtonClick={() =>
                getTaxStatus(
                  values[ABOUT_YOU_SCOPE_NAME][clientScopeName][
                    ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD
                  ]?.id ?? ""
                )
              }
            />
          </div>
          <div className="right-side" />
        </>
      );
    }

    return (
      <>
        <h1 className="step-title">{title}</h1>
        <p>
          {`${salutation}, this information will help us prepare for your meeting`}
        </p>
        {renderAboutYouFormContent()}
      </>
    );
  };

  return renderAboutYouContent();
};
