import React, { FC } from "react";
import { YourMoneyStepState } from "../../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepTypes";
import {
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_FORM_POLICY_REFERENCE_NUMBER,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_FORM_WHO_BELONG_TO,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
  PROVISION_SCOPE_NAME,
} from "../../../../../../../feature/journeySteps/steps/yourMoneyStep/YourMoneyStepConstants";
import { useLocalization } from "@saturn-ui/components";
import { formatCurrencyValueToView } from "../../../../../../../components/journeySteps/steps/aboutYouStep/AboutYouStep.utils";

type Props = {
  values: YourMoneyStepState;
};

export const DetailsListComponent: FC<Props> = ({ values }) => {
  const localization = useLocalization();
  const provision = values[PROVISION_SCOPE_NAME];
  const whoIsItHeldWithText =
    provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH]?.id === "Other"
      ? provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER]
      : provision[PROVISION_FORM_WHO_IS_IT_HELD_WITH]?.displayText;

  return (
    <div className="details-list-component">
      <dl>
        <dt>Holding Type</dt>
        <dd>
          {provision[PROVISION_FORM_TYPE_OF_HOLDING] &&
            provision[PROVISION_FORM_TYPE_OF_HOLDING].displayText}
        </dd>

        {provision[PROVISION_FORM_WHO_BELONG_TO] &&
          provision[PROVISION_FORM_WHO_BELONG_TO].displayText && (
            <>
              <dt>Who does it belong to?</dt>
              <dd>{provision[PROVISION_FORM_WHO_BELONG_TO].displayText}</dd>
            </>
          )}

        <dt>Who is it held with?</dt>
        <dd>{whoIsItHeldWithText}</dd>

        <dt>What kind of product is it?</dt>
        <dd>
          {provision[PROVISION_FORM_PRODUCT_TYPE] &&
            provision[PROVISION_FORM_PRODUCT_TYPE].displayText}
        </dd>

        <dt>Your policy reference number</dt>
        <dd>{provision[PROVISION_FORM_POLICY_REFERENCE_NUMBER]}</dd>

        <dt>How much is it worth?</dt>
        <dd>
          {formatCurrencyValueToView(
            localization,
            provision[PROVISION_FORM_HOW_MUCH_IS_IT_WORTH]
          )}
        </dd>
      </dl>
    </div>
  );
};
