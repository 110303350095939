import { VIDEO_INTEGRATION_CONTENT_TYPE_VIDEOS } from "../../feature/videoIntegration/VideoIntegrationConstants";
import {
  VideoIntegrationContent,
  VideoIntegrationVideoData,
  VideoIntegrationVideosContent,
} from "../../feature/videoIntegration/VideoIntegrationTypes";

export const getVideoData = (
  videoIntegrationContent: VideoIntegrationContent,
  id: string
): VideoIntegrationVideoData | undefined => {
  const videos = videoIntegrationContent.find(
    (content) => content.type === VIDEO_INTEGRATION_CONTENT_TYPE_VIDEOS
  );

  return (videos as VideoIntegrationVideosContent).videos.find(
    (video) => video.id === id
  );
};
