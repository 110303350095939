import { createAsyncThunk } from "@reduxjs/toolkit";
import { CaseApi, getCaseApi } from "skipton-common";
import { AppState } from "../../../types/AppState";
import { withSessionId } from "../../../utils/AuthorisationUtils";

export const postCFFSectionCustomData = createAsyncThunk<
  void,
  {
    baseUrl: string;
    sections: CaseApi.CFFSection[];
  },
  { state: AppState }
>(
  "post-cff-section-custom-data",
  async (data: {
    baseUrl: string;
    sections: CaseApi.CFFSection[];
  }): Promise<void> => {
    const { baseUrl, sections } = data;

    const caseApi = withSessionId(getCaseApi(baseUrl));

    await caseApi.postCFFSectionCustomData({
      CFFSection: {
        version: "1.0",
        section: sections,
      },
    });
  }
);

export const postCFFSectionCustomDataSuccess =
  postCFFSectionCustomData.fulfilled;
export const postCFFSectionCustomDataPending = postCFFSectionCustomData.pending;
export const postCFFSectionCustomDataFailed = postCFFSectionCustomData.rejected;
