import "react-hot-loader";
import React from "react";
import ReactDOM from "react-dom";
import { Store } from "@reduxjs/toolkit";
import { RootState } from "skipton-features";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  /* eslint-disable-next-line */
  errorBoundary(err, info, props): any {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

/* eslint-disable-next-line */
export const { bootstrap, unmount } = lifecycles;

export let rootStore: Store<RootState>;
export let useRootDispatch: () => typeof rootStore.dispatch;

/* eslint-disable */
export const mount = (props: any) => {
  // do something with the common configuration settings
  rootStore = props.rootStore;
  useRootDispatch = props.useRootDispatch;
  return lifecycles.mount(props);
};
