import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCFFSectionCustomDataSuccess } from "./thunks";
import { CustomDataState } from "./CustomDataTypes";
import { CaseApi } from "skipton-common";
import { getCFFCustomDataSuccess } from "./thunks/GetCFFCustomData";

export const defaultCustomDataState: CustomDataState = {
  sections: null,
  remoteCustomData: null,
};

const configurationSettingsSlice = createSlice({
  name: "custom-data",
  initialState: defaultCustomDataState,
  reducers: {
    setCFFSectionCustomData: (
      state,
      { payload }: PayloadAction<CaseApi.CFFSection[] | null>
    ) => ({
      ...state,
      sections: payload,
    }),
    resetState: () => ({
      ...defaultCustomDataState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getCFFSectionCustomDataSuccess, (state, { payload }) => ({
      ...state,
      sections: payload,
    }));
    builder.addCase(getCFFCustomDataSuccess, (state, { payload }) => ({
      ...state,
      remoteCustomData: payload,
    }));
  },
});

export const { setCFFSectionCustomData, resetState } =
  configurationSettingsSlice.actions;

export default configurationSettingsSlice.reducer;
