import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalutationApi, getSalutationApi } from "skipton-common";
import { withSessionId } from "../../../utils/AuthorisationUtils";

export const getSalutation = createAsyncThunk<
  SalutationApi.SalutationResponse,
  {
    baseUrl: string;
  }
>(
  "get-salutation",
  async (data: {
    baseUrl: string;
  }): Promise<SalutationApi.SalutationResponse> => {
    const { baseUrl } = data;

    const salutationApi = withSessionId(getSalutationApi(baseUrl));

    const response = await salutationApi.getSalutation();

    return response.body;
  }
);

export const getSalutationSuccess = getSalutation.fulfilled;
export const getSalutationPending = getSalutation.pending;
export const getSalutationFailed = getSalutation.rejected;
