import { NO, YES } from "../feature/customData/CustomDataConstants";

export const booleanToText = (
  condition: boolean | null,
  defaultValue = ""
): string => {
  if (typeof condition === "boolean") {
    return condition ? YES : NO;
  } else {
    return defaultValue;
  }
};
