import {
  YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  YOUR_FEELINGS_FORM_IS_QUESTION_FIELD,
  YOUR_FEELINGS_FORM_QUESTION_FIELD,
} from "./YourFeelingsStepConstants";
import { YourFeelingsStepFormState } from "./YourFeelingsStepTypes";

export const defaultYourFeelingsStepFormState: YourFeelingsStepFormState = {
  [YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD]: null,
  [YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]: null,
  [YOUR_FEELINGS_FORM_QUESTION_FIELD]: null,
};
