import { CFFVideoIntegrationConfig } from "../VideoIntegrationTypes";

export const CFFVideoConfigDev: CFFVideoIntegrationConfig = {
  Content: [
    {
      id: "P01",
      description: "List of Advisers",
      type: "profiles",
      advisers: [
        {
          id: "Default",
          video: "https://www.youtube-nocookie.com/embed/SR1BxOqp7CM?rel=0",
        },
      ],
    },
    {
      id: "V01",
      description: "Video",
      type: "videos",
      videos: [
        {
          id: "ADV",
          description: "Advice approach video",
          video: "https://www.youtube-nocookie.com/embed/gnJBpdQpd-I?rel=0",
        },
        {
          id: "ATR",
          description: "ATR video",
          video: "https://www.youtube-nocookie.com/embed/L-XSmTlPVqs?rel=0",
        },
      ],
    },
  ],
};
