import { DefinitionResource } from "@saturn-ui/components";
import { FooterDefinition } from "../../../feature/layout/Footer";
import { getCookiePolicyRoute } from "../../../helpers";

const footerDefinition: DefinitionResource<FooterDefinition> = {
  content: {
    linkItems: {
      content: {
        HelpAndFAQs: {
          content: {
            title: { caption: "Help & FAQs" },
            linkTo: {
              caption:
                "https://www.skipton.co.uk/your-financial-advice-meeting",
            },
          },
        },
        LegalNotice: {
          content: {
            title: { caption: "Legal notice" },
            linkTo: { caption: "https://www.skipton.co.uk/legal-notice" },
          },
        },
        SecurityPolicy: {
          content: {
            title: { caption: "Security policy" },
            linkTo: { caption: "https://www.skipton.co.uk/security-policy" },
          },
        },
        DataPrivacyNotice: {
          content: {
            title: { caption: "Data privacy notice" },
            linkTo: { caption: "https://www.skipton.co.uk/privacy-policy" },
          },
        },
        ModernSlaveryAct: {
          content: {
            title: { caption: "Modern slavery act" },
            linkTo: {
              caption:
                "https://www.skipton.co.uk/about-us/governance/modern-slavery-act",
            },
          },
        },
        Cookies: {
          content: {
            title: { caption: "Cookies" },
            linkTo: { caption: getCookiePolicyRoute() },
          },
        },
        InvestorRelations: {
          content: {
            title: { caption: "Investor relations" },
            linkTo: { caption: "https://www.skipton.co.uk/investorrelations" },
          },
        },
        PressOffice: {
          content: {
            title: { caption: "Press office" },
            linkTo: { caption: "https://www.skipton.co.uk/press-office" },
          },
        },
      },
    },
  },
};

export default footerDefinition;
