import { DefinitionResource } from "@saturn-ui/components";
import { YourFeelingsStepDefinition } from "../../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepTypes";

export const yourFeelingsStepDefinition: DefinitionResource<YourFeelingsStepDefinition> =
  {
    content: {
      form: {
        content: {
          fields: {
            content: {
              confirmWatchVideo: {
                appearance: "checkbox",
                caption:
                  "I confirm everyone who is going to receive financial advice has watched the video",
                errors: {
                  required: true,
                  messages: {
                    required:
                      "Oops! We need you to confirm you have watched the video before we continue. Please tick the box when you have watched the video.",
                  },
                },
              },
              isQuestion: {
                errors: {
                  required: true,
                },
              },
              question: {
                caption: "Please type your question below",
                placeholder: "Type here",
                errors: {
                  required: true,
                },
              },
            },
          },
        },
      },
      ATRForm: {
        content: {
          fields: {
            content: {
              question: {
                errors: {
                  required: true,
                  messages: {
                    required: "Please answer this question.",
                  },
                },
              },
            },
          },
        },
      },
    },
  };
