import { CaseApi } from "skipton-common";
import { CFFCustomData } from "skipton-common/lib/api/utils/case";

export enum OurAdviceStepStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export enum SendCFFCustomDataStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export type CustomDataState = {
  sections: CaseApi.CFFSection[] | null;
  remoteCustomData: Partial<CFFCustomData["CFF"]> | null;
};
