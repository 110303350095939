import React, { useEffect } from "react";
import { Button, Dialog, IconButton } from "@saturn-ui/components";
import { useTimer } from "../../hooks/useTimer";
import "./SessionExpiredDialog.scss";
import { formatCounter } from "../../utils/time";
import { CloseIcon } from "../../assets/icons/CloseIcon";

type Props = {
  secondsToLogout?: number;
  onContinueClick: () => void;
  onClose: () => void;
};

const DEFAULT_TIMER_TO_LOGOUT_S = 60;

export const SessionExpiredDialog: React.FC<Props> = ({
  onContinueClick,
  secondsToLogout,
}) => {
  const { state, timerRef } = useTimer({
    from: secondsToLogout || DEFAULT_TIMER_TO_LOGOUT_S,
    to: 0,
    direction: -1,
  });

  useEffect(() => {
    timerRef.start();
    return () => timerRef.reset();
  }, []);

  return (
    <Dialog isOpen={true} onClose={onContinueClick} className="auth-dialog">
      <IconButton
        className="dialog-close-button"
        ariaLabel="Close"
        onClick={onContinueClick}
      >
        <CloseIcon />
      </IconButton>
      <h2 className="dialog-title">Session is about to expire</h2>
      <p>For security reasons, your session will expire in</p>
      <h3 className="countdown-number">{formatCounter(state?.current)}</h3>
      <div className="dialog-single-action">
        <Button
          className="primary-button"
          onClick={onContinueClick}
          variant="contained"
          id="refresh-button"
          disableRipple={true}
        >
          Continue Session
        </Button>
      </div>
    </Dialog>
  );
};
