import { ProvisionSummaryComponent } from "../../../../../src/components/journeySteps/steps/yourMoneyStep/provisionComponent/provisionSummaryComponent/ProvisionSummaryComponent";
import { ProvisionFormState } from "./YourMoneyStepTypes";
import {
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
  PROVISION_FORM_WHO_BELONG_TO,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_KNOW_POLICY_REF_NUM,
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_FORM_POLICY_REFERENCE_NUMBER,
  PROVISION_ID,
  PROVISION_FORM_UPLOADED_FILES,
} from "./YourMoneyStepConstants";

import { ProvisionDetailsComponent } from "../../../../../src/components/journeySteps/steps/yourMoneyStep/provisionComponent/provisionDetailsComponent/ProvisionDetailsComponent";
import { ProvisionSavedDetailsComponent } from "../../../../../src/components/journeySteps/steps/yourMoneyStep/provisionComponent/provisionSavedDetailsComponent/ProvisionSavedDetailsComponent";

export const defaultProvisionFormState: ProvisionFormState = {
  [PROVISION_FORM_TYPE_OF_HOLDING]: null,
  [PROVISION_FORM_WHO_BELONG_TO]: null,
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH]: null,
  [PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER]: null,
  [PROVISION_FORM_PRODUCT_TYPE]: null,
  [PROVISION_FORM_KNOW_POLICY_REF_NUM]: null,
  [PROVISION_FORM_POLICY_REFERENCE_NUMBER]: null,
  [PROVISION_FORM_HOW_MUCH_IS_IT_WORTH]: null,
  [PROVISION_ID]: null,
  [PROVISION_FORM_UPLOADED_FILES]: [],
};

export const defaultProvision = {
  SummaryComponent: ProvisionSummaryComponent,
  DetailsComponent: ProvisionDetailsComponent,
  SavedDetailsComponent: ProvisionSavedDetailsComponent,
  isSaved: false,
  canContinue: false,
  expanded: true,
  values: defaultProvisionFormState,
};
