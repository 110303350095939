import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AboutYouState,
  AboutYouStepMeta,
  AboutYouStepState,
  YourPropertyFormState,
} from "./AboutYouStepTypes";
import {
  defaultAboutYouStepFormState,
  defaultProperty,
} from "./AboutYouStepInitialState";
import {
  ABOUT_YOU_SCOPE_NAME,
  ABOUT_YOU_STEP_MARITAL_STATUS_LIST,
  ABOUT_YOU_STEP_TAX_JURISDICTION_LIST,
  ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST,
  ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST,
  YOUR_PROPERTY_SCOPE_NAME,
} from "./AboutYouStepConstants";
import { getTaxStatusListSuccess, initialiseAboutYouSuccess } from "./thunks";
import { JourneyStepsAccordionItemProps } from "../../JourneyStepsTypes";

const defaultState: AboutYouStepMeta = {
  initialValues: defaultAboutYouStepFormState,
  properties: [defaultProperty],
  activePropertyIndex: 0,
  lists: {
    [ABOUT_YOU_STEP_MARITAL_STATUS_LIST]: null,
    [ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST]: null,
    [ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST]: null,
    [ABOUT_YOU_STEP_TAX_JURISDICTION_LIST]: null,
  },
  isJointCustomerDialogShown: true,
  isSharingYourInformationDialogShown: true,
};

const aboutYouStepSlice = createSlice({
  name: "aboutYouStep",
  initialState: defaultState,
  reducers: {
    setAboutYouStepValues: (
      state,
      action: PayloadAction<AboutYouStepState>
    ) => {
      state.initialValues = action.payload;
    },
    setAboutYouValues: (state, action: PayloadAction<AboutYouState>) => {
      state.initialValues[ABOUT_YOU_SCOPE_NAME] = action.payload;
    },
    setYourPropertyValues: (
      state,
      action: PayloadAction<YourPropertyFormState>
    ) => {
      state.initialValues[YOUR_PROPERTY_SCOPE_NAME] = action.payload;
    },
    setProperties: (
      state,
      action: PayloadAction<JourneyStepsAccordionItemProps[]>
    ) => {
      state.properties = action.payload;
    },
    setActivePropertyIndex: (state, action: PayloadAction<number>) => {
      state.activePropertyIndex = action.payload;
    },
    setIsJointCustomerDialogShown: (state, action: PayloadAction<boolean>) => {
      state.isJointCustomerDialogShown = action.payload;
    },
    setIsSharingYourInformationDialogShown: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSharingYourInformationDialogShown = action.payload;
    },
    resetToInitialValues: (state) => {
      state.initialValues = defaultAboutYouStepFormState;
      state.properties = [defaultProperty];
      state.isJointCustomerDialogShown = true;
      state.activePropertyIndex = 0;
      state.isSharingYourInformationDialogShown = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initialiseAboutYouSuccess, (state, action) => {
      state.lists = action.payload;
    });
    builder.addCase(getTaxStatusListSuccess, (state, action) => {
      state.lists[ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST] =
        action.payload[ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST];
      state.lists[ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST] =
        action.payload[ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST];
    });
  },
});

export const {
  setAboutYouStepValues,
  setAboutYouValues,
  setYourPropertyValues,
  setProperties,
  setActivePropertyIndex,
  setIsJointCustomerDialogShown,
  setIsSharingYourInformationDialogShown,
  resetToInitialValues,
} = aboutYouStepSlice.actions;
export default aboutYouStepSlice.reducer;
