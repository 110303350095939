import React, { FC } from "react";
import "./Video.scss";

interface Props {
  src: string | undefined;
  description?: string;
}

export const Video: FC<Props> = ({ src, description = "video" }) => (
  <div className="video-wrapper mockVideoBlock">
    <iframe
      src={src}
      title={description}
      allow="fullscreen"
      frameBorder="0"
      allowFullScreen
    />
  </div>
);
