import React, { FC, useCallback } from "react";
import {
  BooleanField,
  createFormScope,
  FieldRenderer,
  FieldUpdateEventArgs,
  FormContextProvider,
  FormsConfiguration,
  SubmitButton,
  TextField,
  useFormContainer,
  useFormsConfiguration,
  useLocalization,
} from "@saturn-ui/components";
import {
  YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  YOUR_FEELINGS_FORM_IS_QUESTION_FIELD,
  YOUR_FEELINGS_FORM_QUESTION_FIELD,
  yourFeelingsFieldKeys,
} from "../../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepConstants";
import {
  YourFeelingsStepDefinition,
  YourFeelingsStepFormErrors,
  YourFeelingsStepFormState,
  YourFeelingsStepFormTouched,
} from "../../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepTypes";
import { validate } from "../../../../../feature/journeySteps/steps/yourFeelingsStep/YourFeelingsStepValidation";
import { handleIsQuestionFieldChange } from "../../ourAdviceStep/OurAdviceStep.utils";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import {
  focusOnInputById,
  getFirstErrorKey,
} from "../../../../../utils/formUtils";

type Props = {
  definition: YourFeelingsStepDefinition;
  onFormSubmit: (values: YourFeelingsStepFormState) => Promise<void>;
};

export const YourFeelingsStepContent: FC<Props> = ({
  definition,
  onFormSubmit,
}) => {
  const {
    yourFeelings: { initialValues },
  } = useSelector((state: AppState) => state);

  const { validation } = useFormsConfiguration<FormsConfiguration>();

  const localization = useLocalization();

  const formContainer = useFormContainer<
    YourFeelingsStepFormState,
    YourFeelingsStepFormErrors,
    YourFeelingsStepFormTouched
  >({
    initialValues: initialValues,
    validateFunc: (ourAdvice) =>
      validate(ourAdvice, definition.form.content, validation, localization),
    enableReinitialize: true,
  });

  const {
    touched,
    errors,
    isValid,
    handleFieldUpdate,
    values,
    validateForm,
    setFieldTouched,
    updateState,
  } = formContainer;

  const yourFeelingsScope = createFormScope({
    name: "",
    values,
    errors,
    touched,
    readOnly: false,
    disabled: false,
  });

  const handlePressContinue = useCallback(async () => {
    validateForm();
    setFieldTouched(YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD);
    setFieldTouched(YOUR_FEELINGS_FORM_IS_QUESTION_FIELD);
    setFieldTouched(YOUR_FEELINGS_FORM_QUESTION_FIELD);
    if (isValid) {
      await onFormSubmit(values);
    } else {
      const firstErrorKey = getFirstErrorKey(errors, yourFeelingsFieldKeys);
      if (firstErrorKey) {
        focusOnInputById(firstErrorKey, setFieldTouched);
      }
    }
  }, [
    validateForm,
    setFieldTouched,
    isValid,
    onFormSubmit,
    values,
    errors,
    getFirstErrorKey,
    focusOnInputById,
  ]);

  const handleIsQuestionFieldUpdate = useCallback(
    (args: FieldUpdateEventArgs<boolean>) => {
      handleFieldUpdate(args);

      if (!args.changed) {
        return;
      }

      handleIsQuestionFieldChange(updateState, setFieldTouched);
    },
    [handleFieldUpdate, setFieldTouched, updateState]
  );

  return (
    <FormContextProvider value={formContainer}>
      <form onSubmit={(e) => e.preventDefault()} autoComplete="off" noValidate>
        <fieldset>
          <legend className="legend-no-top-margin">
            It is a requirement that everyone who is attending the financial
            advice meeting watches this video.
          </legend>
          <div className="your-feelings-step-checkbox-wrapper">
            <FieldRenderer
              name={YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD}
              definition={
                definition.form.content.fields.content[
                  YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD
                ]
              }
              scope={yourFeelingsScope}
              render={(fieldProps) => (
                <BooleanField
                  {...fieldProps}
                  labelPosition="right"
                  value={values[YOUR_FEELINGS_FORM_CONFIRM_WATCH_VIDEO_FIELD]}
                  onUpdate={handleFieldUpdate}
                  errorMessage={fieldProps.errorMessage}
                />
              )}
            />
          </div>
        </fieldset>
        <fieldset className="fieldset-with-top-margin">
          <legend className="legend-important legend-no-bottom-margin">
            Do you have any questions about the information in the video?
          </legend>
          <div className="your-feelings-question-wrapper">
            <p className="paragraph-no-margin">
              Select yes below to share your query, and we'll either answer when
              you meet your adviser or contact you before your meeting.
            </p>
            <div className="your-feelings-question-radio-wrapper">
              {errors[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD] &&
                touched[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD] && (
                  <div className="error-message">
                    Please select if you do or do not have questions.
                  </div>
                )}
              <FieldRenderer
                name={YOUR_FEELINGS_FORM_IS_QUESTION_FIELD}
                definition={
                  definition.form.content.fields.content[
                    YOUR_FEELINGS_FORM_IS_QUESTION_FIELD
                  ]
                }
                scope={yourFeelingsScope}
                render={(fieldProps) => (
                  <BooleanField
                    {...fieldProps}
                    trueLabel="Yes, I do have questions"
                    falseLabel="No, I do not have questions"
                    value={values[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD]}
                    onUpdate={handleIsQuestionFieldUpdate}
                  />
                )}
              />
            </div>
            {values[YOUR_FEELINGS_FORM_IS_QUESTION_FIELD] && (
              <FieldRenderer
                name={YOUR_FEELINGS_FORM_QUESTION_FIELD}
                definition={
                  definition.form.content.fields.content[
                    YOUR_FEELINGS_FORM_QUESTION_FIELD
                  ]
                }
                scope={yourFeelingsScope}
                render={(fieldProps) => (
                  <TextField
                    {...fieldProps}
                    isLarge
                    value={values[YOUR_FEELINGS_FORM_QUESTION_FIELD]}
                    onUpdate={handleFieldUpdate}
                  />
                )}
              />
            )}
          </div>
        </fieldset>
        <SubmitButton
          className="our-advice-continue-button submit-button decorated-button"
          onClick={handlePressContinue}
          variant="contained"
          id="our-advice-continue-button"
          disableRipple={true}
          color={"primary"}
        >
          <span className="decoration">I am ready to continue</span>
        </SubmitButton>
      </form>
    </FormContextProvider>
  );
};
