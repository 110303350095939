import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthenticationApi, AuthenticationApi } from "skipton-common";
import { withSessionId } from "../../../utils/AuthorisationUtils";

export const login = createAsyncThunk(
  "authentication/login",
  async (data: {
    baseUrl: string;
    loginPayload: AuthenticationApi.LoginPayload;
  }): Promise<AuthenticationApi.LoginResponseModel> => {
    const { baseUrl, loginPayload } = data;

    const authenticationApi = withSessionId(getAuthenticationApi(baseUrl));

    const response = await authenticationApi.login(loginPayload);

    return response.body;
  }
);

export const loginSuccess = login.fulfilled;
export const loginPending = login.pending;
export const loginFailed = login.rejected;
