import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const productTypeSavingsList: List = {
  listType: "productTypeSavings",
  lang: "en-GB",
  items: [
    {
      id: "BA",
      displayText: "Bank Account",
    },
    {
      id: "BSOC",
      displayText: "Building Society",
    },
    {
      id: "FSS",
      displayText: "Friendly Society Savings",
    },
    {
      id: "ISA",
      displayText: "ISA",
    },
    {
      id: "JISA",
      displayText: "Junior ISA",
    },
    {
      id: "LISA",
      displayText: "Lifetime ISA",
    },
    {
      id: "NATS",
      displayText: "National Savings",
    },
    {
      id: "REGSAV",
      displayText: "Regular Savings Plan",
    },
  ],
};
