import { bindAllClass } from "../utils/classes";
import { SessionEvents } from "./SessionService";

export type TEventNames = Record<string, string>;
export type CallbackFunction = (...args: unknown[]) => void;

export interface EventEmitterINT<
  EventNames extends TEventNames = TEventNames,
  Events extends keyof EventNames = keyof EventNames
> {
  EVENT_NAMES: EventNames;

  has(event: Events): boolean;
  remove(event: Events): void;
  on(event: Events, callback: CallbackFunction | CallbackFunction[]): void;
  off(event: Events, callback: CallbackFunction | CallbackFunction[]): void;
  emit<Payload = unknown>(event: Events, payload?: Payload): void;
}

export class EventEmitter<
  EventNames extends TEventNames = TEventNames,
  Events extends keyof EventNames = keyof EventNames
> implements EventEmitterINT<EventNames>
{
  public get EVENT_NAMES(): EventNames {
    return this._eventNames;
  }

  private events: Map<Events, CallbackFunction[]>;

  constructor(private _eventNames: EventNames) {
    this.events = new Map();

    bindAllClass(this);
  }

  public emit<Payload = unknown>(event: Events, payload?: Payload): void {
    const callbacks = this.events.get(event);

    if (!callbacks?.length) {
      return;
    } else {
      callbacks.forEach((callback) => callback(payload));
    }
  }

  public on(
    event: Events,
    callback: CallbackFunction | CallbackFunction[]
  ): void {
    const callbacksQueue = this.events.get(event) || [];

    this.setCallbacks(event, [
      ...callbacksQueue,
      ...this.parseCallbackValue(callback),
    ]);
  }

  public off(
    event: Events,
    callback: CallbackFunction | CallbackFunction[]
  ): void {
    const callbacks = this.events.get(event);

    if (!callbacks?.length) {
      console.log(
        `Cannot find subscribed callbacks to event with name [${String(
          event
        )}].`
      );

      return;
    } else {
      const staleCallbacks = this.parseCallbackValue(callback);
      const clearedCallbacks = callbacks.filter(
        (queueCallback) => !staleCallbacks.includes(queueCallback)
      );

      this.setCallbacks(event, clearedCallbacks);
    }
  }

  public remove(event: Events): void {
    if (!this.events.get(event)) {
      console.log(
        new Error(
          `Event [${String(event)}] does not exist in emitter. Cannot remove`
        ).message
      );
    } else {
      this.events.delete(event);
    }
  }

  public has(event: Events): boolean {
    return this.events.has(event);
  }

  private parseCallbackValue(
    callback: CallbackFunction | CallbackFunction[]
  ): CallbackFunction[] {
    return Array.isArray(callback) ? callback : [callback];
  }

  private setCallbacks(event: Events, callbacks: CallbackFunction[]): void {
    this.events.set(event, callbacks);
  }
}

export const EVENTS = {
  ...SessionEvents,
};

export type MainEmitterEvents = typeof EVENTS;

export const MainEmitter = new EventEmitter(EVENTS);
