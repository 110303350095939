import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import "./Layout.scss";
import clsx from "clsx";
import { ArrowBackIcon } from "../../assets/icons/ArrowBackIcon";
import { Button, Footer, Link } from "@saturn-ui/components";
import { useSaturnUITheme } from "@saturn-ui/foundations";
import { backButtonSx } from "../../theme/skiptonLightThemeOverrides";
import { Header } from "../header/Header";
import Logo from "../../assets/icons/Logo";
import { Stack } from "@mui/material";
import footerDefinition from "../../config/default/definitions/Footer";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../redux/selectors/authentication";
import { useAuthLogic } from "../../hooks/useAuthLogic";
import {
  ProgressStepper,
  ProgressStepperProps,
} from "../progressStepper/ProgressStepper";

type LayoutProps = {
  backButtonText?: string;
  backButtonDisabled?: boolean;
  onBackPress?: () => void;
  alwaysHavePadding?: boolean;
  progressStepperProps?: ProgressStepperProps;
  emptyFooter?: boolean;
  logoutIsHidden?: boolean;
  borderRadiusNone?: boolean;
};

type LayoutSubComponents = {
  SubPage: typeof SubPage;
};

const LayoutBase: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  emptyFooter = false,
  logoutIsHidden = false,
}) => {
  const { palette } = useSaturnUITheme();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { onPressLogout } = useAuthLogic();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderFooterLinks = useCallback(() => {
    const items = footerDefinition.content.linkItems?.content || {};
    return Object.keys(items).map((key, index) => {
      const linkItem = items[key];
      return (
        <Link
          target={"_blank"}
          key={`footer-link-${index}`}
          href={linkItem?.content?.linkTo?.caption}
          invertedColors
        >
          {linkItem?.content?.title?.caption}
        </Link>
      );
    });
  }, []);

  const HeaderActions = useMemo(() => {
    return isLoggedIn && !logoutIsHidden ? (
      <Button
        className="decorated-button inverted"
        key="logout-button"
        onClick={onPressLogout}
        disableRipple={true}
        variant="contained"
        invertedColors
        sx={{ pl: 2, pr: 2 }}
      >
        <span className="decoration">Logout</span>
      </Button>
    ) : null;
  }, [isLoggedIn, onPressLogout]);

  return (
    <>
      <Header logo={<Logo />} actions={HeaderActions} />
      {children}
      <div className="footer">
        <Footer
          logo={null}
          sx={{
            backgroundColor: palette.background.dark,
          }}
          data-testid="footer"
        >
          {!emptyFooter && (
            <Stack
              gap={1}
              className="page-container"
              sx={{
                flexDirection: "row",
                width: "100%",
                gap: 3,
                flexWrap: "wrap",
                position: "relative",
                marginBottom: "28px",
              }}
            >
              {renderFooterLinks()}
              <span className="build-number">{`1.000.${
                process?.env?.REACT_APP_VERSION ?? ""
              }`}</span>
            </Stack>
          )}
        </Footer>
      </div>
    </>
  );
};

export const Layout: FC<LayoutProps> & LayoutSubComponents = ({
  children,
  backButtonText,
  backButtonDisabled = false,
  onBackPress,
  alwaysHavePadding,
  progressStepperProps,
  borderRadiusNone,
  logoutIsHidden,
}) => {
  return (
    <LayoutBase logoutIsHidden={logoutIsHidden}>
      <div
        className={clsx("page-wrapper", {
          "padding-always": alwaysHavePadding,
          "border-radius-none": borderRadiusNone,
        })}
        data-testid="content"
      >
        {backButtonText && (
          <div className="back-button-wrapper">
            <Button
              id="back-button"
              className="back-button"
              disabled={backButtonDisabled}
              sx={backButtonSx}
              startIcon={<ArrowBackIcon />}
              onClick={onBackPress}
              disableRipple={true}
              variant="text"
            >
              {backButtonText}
            </Button>
          </div>
        )}
        {!!progressStepperProps && (
          <ProgressStepper
            numberOfSteps={progressStepperProps.numberOfSteps}
            active={progressStepperProps.active}
            maxStepDone={progressStepperProps.maxStepDone}
          />
        )}
        <div className="content-wrapper page-container">{children}</div>
      </div>
    </LayoutBase>
  );
};

type SubPageLayoutProps = {
  title?: string;
  backButtonText?: string;
  onBackPress?: () => void;
  alwaysHavePadding?: boolean;
  emptyFooter?: boolean;
  logoutIsHidden?: boolean;
};

export const SubPage: FC<SubPageLayoutProps> = ({
  children,
  title,
  backButtonText,
  onBackPress,
  alwaysHavePadding,
  emptyFooter,
  logoutIsHidden,
}) => {
  return (
    <LayoutBase emptyFooter={emptyFooter} logoutIsHidden={logoutIsHidden}>
      <div
        className={clsx("page-wrapper", {
          "padding-always": alwaysHavePadding,
        })}
        data-testid="content"
      >
        <div className="page-container subpage-page-container">
          {backButtonText && (
            <Button
              id="return-to-confirmation-button"
              className="back-button"
              sx={backButtonSx}
              startIcon={<ArrowBackIcon />}
              onClick={onBackPress}
              disableRipple={true}
              variant="text"
            >
              {backButtonText}
            </Button>
          )}
          <h1 className="subpage-header">{title}</h1>
          <div className="subpage-content">{children}</div>
        </div>
      </div>
    </LayoutBase>
  );
};

Layout.SubPage = SubPage;
