import { createAsyncThunk } from "@reduxjs/toolkit";
import { CaseApi, getCaseApi } from "skipton-common";
import { AppState } from "../../../../../types/AppState";
import { withSessionId } from "../../../../../utils/AuthorisationUtils";
import { ProvisionFormState } from "../YourMoneyStepTypes";
import { mapDataToProvisionApi } from "../mappers";

export const postProvision = createAsyncThunk<
  CaseApi.ProvisionResponse,
  {
    baseUrl: string;
    provision: ProvisionFormState;
  },
  { state: AppState }
>(
  "post-provision",
  async (
    data: {
      baseUrl: string;
      provision: ProvisionFormState;
    },
    { getState }
  ): Promise<CaseApi.ProvisionResponse> => {
    const { baseUrl, provision } = data;
    const {
      financialAdvicePage: {
        clientInfo: { category },
      },
    } = getState();

    const caseApi = withSessionId(getCaseApi(baseUrl));
    const provisionBody = mapDataToProvisionApi(provision, category);

    const response = await caseApi.postProvision(provisionBody);
    return response.body;
  }
);

export const postProvisionSuccess = postProvision.fulfilled;
export const postProvisionPending = postProvision.pending;
export const postProvisionFailed = postProvision.rejected;
