import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfigurationSettingsApi } from "skipton-common";
import { defaultVideoIntegrationState } from "./VideoIntegrationInitialState";
import { CFFVideoConfig } from "./config/CFFVideoConfig";
import { CFFVideoConfigDev } from "./config/CFFVideoConfigDev";

const videoIntegrationSlice = createSlice({
  name: "videoIntegration",
  initialState: defaultVideoIntegrationState,
  reducers: {
    setVideoIntegration: (
      state,
      action: PayloadAction<{
        config: ConfigurationSettingsApi.DomainCFFVideoConfig;
      }>
    ) => {
      const { config } = action.payload;

      if (
        config ===
        ConfigurationSettingsApi.CONFIGURATION_SETTINGS_CFF_VIDEO_CONFIG_DEV
      ) {
        state.content = CFFVideoConfigDev.Content;
      }

      if (
        config ===
        ConfigurationSettingsApi.CONFIGURATION_SETTINGS_CFF_VIDEO_CONFIG
      ) {
        state.content = CFFVideoConfig.Content;
      }
    },
  },
});

export const { setVideoIntegration } = videoIntegrationSlice.actions;
export default videoIntegrationSlice.reducer;
