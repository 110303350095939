import { List } from "../../../../../feature/journeySteps/JourneyStepsTypes";

export const employmentList: List = {
  listType: "employment",
  lang: "en-GB",
  items: [
    {
      id: "Employed",
      displayText: "I am in full time employment",
    },
    {
      id: "Retired",
      displayText: "I am retired",
    },
    {
      id: "SelfEmployed",
      displayText: "I am self-employed",
    },
    {
      id: "SemiRetired",
      displayText: "I am semi-retired",
    },
  ],
};
