import React from "react";
import "./DocumentUpload.scss";
import { FileDownloadDoneIcon, TextSnippetIcon } from "../../assets";

type Props = {
  fileName: string;
};

export const DocumentUploaded: React.FC<Props> = ({ fileName }) => {
  return (
    <div className="document-upload document-uploaded">
      {!!fileName && (
        <div className="the-document successfully-uploaded">
          <TextSnippetIcon className="text-snippet-icon" />
          <div className="file-name">{fileName}</div>
          <span className="uploaded">Uploaded</span>
          <FileDownloadDoneIcon />
        </div>
      )}
    </div>
  );
};
