import { CaseApi } from "skipton-common";
import { PropertyFormState } from "../AboutYouStepTypes";
import {
  ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD,
} from "../AboutYouStepConstants";
import { ClientCategory } from "../../../../financialAdvicePage/FinancialAdvicePageTypes";
import { CLIENT_CATEGORY_JOINT } from "../../../../financialAdvicePage/FinancialAdvicePageConstants";

export const mapDataToPropertyApi = (
  propertyValues: PropertyFormState,
  category: ClientCategory
): CaseApi.Property => {
  let ownership;
  let ownershipPercentage;
  let otherOwner;

  if (category === CLIENT_CATEGORY_JOINT) {
    switch (propertyValues[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?.id) {
      case "Equally":
        ownership = "J";
        ownershipPercentage = [50, 50];
        break;
      case "Client1":
        ownership = "1";
        ownershipPercentage = [100];
        break;
      case "Client2":
        ownership = "2";
        ownershipPercentage = [100];
        break;
      default:
        const client1Percentage =
          (propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] ??
            0) * 100;
        const client2Percentage =
          (propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD] ??
            0) * 100;
        if (client1Percentage === 0 && client2Percentage === 0) {
          ownership = "J";
          ownershipPercentage = [0, 0];

          break;
        }
        if (client1Percentage === 0) {
          ownership = "2";
          ownershipPercentage = [100];

          break;
        }

        if (client2Percentage === 0) {
          ownership = "1";
          ownershipPercentage = [100];
          break;
        }

        ownership = "J";
        ownershipPercentage = [client1Percentage, client2Percentage];
    }

    otherOwner =
      propertyValues[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD] ?? "";
  } else {
    if (
      propertyValues[ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?.id === "Myself"
    ) {
      ownership = "1";
      ownershipPercentage = [100];
      otherOwner = "";
    } else {
      ownership = "1";
      ownershipPercentage = [
        (propertyValues[ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD] ??
          0) * 100,
      ];
      otherOwner =
        propertyValues[ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD] ?? "";
    }
  }

  return {
    type: propertyValues[ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]?.id ?? "",
    ownership,
    ownershipPercentage,
    otherOwner,
    value: propertyValues[ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD] ?? 0,
    mortgage: {
      mortgage:
        propertyValues[ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]
          ?.id === "Yes",
      ...(propertyValues[ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]
        ? {
            remaining:
              propertyValues[
                ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD
              ] ?? undefined,
          }
        : {}),
    },
  };
};
