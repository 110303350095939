import { createSlice } from "@reduxjs/toolkit";
import { ConfigurationSliceState } from "./ConfigurationSliceTypes";
import { createConfiguration } from "./DefaultConfiguration";
import defaultBundles from "../../config/default";

const defaultConfig = createConfiguration(defaultBundles);

const defaultState: ConfigurationSliceState = {
  data: defaultConfig,
  isLoaded: true,
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState: defaultState,
  reducers: {},
});

export default configurationSlice.reducer;
