import {
  Localization,
  FieldValidator,
  ValidationConfiguration,
} from "@saturn-ui/components";

import {
  LoginFormDefinition,
  LoginFormError,
  LoginFormState,
} from "../../feature/welcomePage/WelcomePageTypes";

export const validate = (
  formState: LoginFormState,
  definition: LoginFormDefinition,
  config: ValidationConfiguration,
  localization: Localization
): { errors: LoginFormError; isValid: boolean } => {
  const errors: LoginFormError = {};

  const fv = new FieldValidator<LoginFormError>(errors, config, localization);

  fv.checkText(definition.fields.content.id, formState.id, "id");

  return {
    errors,
    isValid: fv.isValid(),
  };
};
