import { template as ourAdviceStepTemplate } from "./steps/ourAdviceStep/OurAdviceStepDefinition";
import { template as aboutYouStepTemplate } from "./steps/aboutYouStep/AboutYouStepDefinition";
import { template as yourFeelingsTemplate } from "./steps/yourFeelingsStep/YourFeelingsStepDefinition";
import { template as yourMoneyTemplate } from "./steps/yourMoneyStep/YourMoneyStepDefinition";

import { JourneyStepsDefinition } from "./JourneyStepsTypes";

export const template: JourneyStepsDefinition = {
  steps: {
    type: "group",
    content: {
      ourAdvice: {
        type: "group",
        content: ourAdviceStepTemplate,
      },
      aboutYou: {
        type: "group",
        content: aboutYouStepTemplate,
      },
      yourFeelings: {
        type: "group",
        content: yourFeelingsTemplate,
      },
      yourMoney: {
        type: "group",
        content: yourMoneyTemplate,
      },
    },
  },
};
