import { createAsyncThunk } from "@reduxjs/toolkit";
import { CaseApi, getCaseApi } from "skipton-common";
import { withSessionId } from "../../../utils/AuthorisationUtils";
import { mapResponseToCustomSectionsState } from "../mappers";

export const getCFFSectionCustomData = createAsyncThunk(
  "get-cff-section-custom-data",
  async (data: { baseUrl: string }): Promise<CaseApi.CFFSection[]> => {
    const { baseUrl } = data;

    const caseApi = withSessionId(getCaseApi(baseUrl));

    const response = await caseApi.getCFFSectionCustomData();

    return mapResponseToCustomSectionsState(response.body);
  }
);

export const getCFFSectionCustomDataSuccess = getCFFSectionCustomData.fulfilled;
export const getCFFSectionCustomDataPending = getCFFSectionCustomData.pending;
export const getCFFSectionCustomDataFailed = getCFFSectionCustomData.rejected;
