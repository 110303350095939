import { CaseApi } from "skipton-common";
import { SectionStatus } from "../hooks/useSaveAndResumeLogic";
import { some } from "lodash";

export const prepareSectionsWithCompleted = (
  sections: CaseApi.CFFSection[],
  sectionName: string
): CaseApi.CFFSection[] =>
  sections.map((section) =>
    section.name === sectionName
      ? updateSection(section, SectionStatus.Completed)
      : section
  );

export const prepareSectionsWithStarted = (
  sections: CaseApi.CFFSection[],
  sectionName: string
): CaseApi.CFFSection[] => {
  if (!sections.length) {
    return [createNewSection(sectionName)];
  }

  const sectionExist = some(
    sections,
    (section) => section.name === sectionName
  );

  if (!sectionExist) {
    return [...sections, createNewSection(sectionName)];
  }

  return sections.map((section) =>
    section.name === sectionName
      ? updateSection(section, section.status)
      : section
  );
};

const createNewSection = (name: string): CaseApi.CFFSection => ({
  startDate: new Date().toISOString(),
  name,
  status: SectionStatus.Started,
  completedDate: null,
  lastAccessDate: new Date().toISOString(),
});

const updateSection = (
  section: CaseApi.CFFSection,
  status: string
): CaseApi.CFFSection => ({
  ...section,
  status,
  completedDate:
    status === SectionStatus.Completed && !section.completedDate
      ? new Date().toISOString()
      : section.completedDate || null,
  lastAccessDate: new Date().toISOString(),
});
