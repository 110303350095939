import { OurAdviceStepFormState } from "./OurAdviceStepTypes";
import {
  OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD,
  OUR_ADVICE_FORM_IS_QUESTION_FIELD,
  OUR_ADVICE_FORM_QUESTION_FIELD,
} from "./OurAdviceStepConstants";

export const defaultOurAdviceStepFormState: OurAdviceStepFormState = {
  [OUR_ADVICE_FORM_CONFIRM_WATCH_VIDEO_FIELD]: null,
  [OUR_ADVICE_FORM_IS_QUESTION_FIELD]: null,
  [OUR_ADVICE_FORM_QUESTION_FIELD]: null,
};
