import { v4 as uuidv4 } from "uuid";

export const getExtensionFromFileName = (fileName: string): string => {
  const pattern = /\.([0-9a-z]+)$/i;
  const match = pattern.exec(fileName);
  if (!match) {
    return "";
  } else {
    return match[0];
  }
};

export const getUniqueInputId = (idText: string): string => {
  return `${idText}-${uuidv4()}`;
};
