import {
  FieldMessages,
  FormScope,
  GroupContent,
  GroupDef,
  ListFieldDef,
  ListItem,
  NumberFieldDef,
  TextFieldDef,
} from "@saturn-ui/components";
import {
  ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD,
  ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD,
  ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD,
  ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD,
  ABOUT_YOU_FORM_MARITAL_STATUS_FIELD,
  ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD,
  ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD,
  ABOUT_YOU_FORM_TAX_STATUS_FIELD,
  ABOUT_YOU_STEP_MARITAL_STATUS_LIST,
  ABOUT_YOU_STEP_TAX_JURISDICTION_LIST,
  ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD,
  ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD,
  ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD,
  ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD,
  ABOUT_YOU_SCOPE_NAME,
  YOUR_PROPERTY_SCOPE_NAME,
  ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM,
  ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST,
  ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST,
} from "./AboutYouStepConstants";
import {
  CLIENT_1_SCOPE_NAME,
  CLIENT_2_SCOPE_NAME,
} from "../../../financialAdvicePage/FinancialAdvicePageConstants";
import { JourneyStepsAccordionItemProps } from "../../JourneyStepsTypes";

export type AboutYouStepDefinition = {
  form: GroupDef & GroupContent<AboutYouFormDefinition>;
  yourPropertyForm: GroupDef & GroupContent<YourPropertyFormDefinition>;
};

export type YourPropertyFormDefinition = {
  fields: GroupDef & GroupContent<YourPropertyFormFieldsDefinition>;
};

export type AboutYouFormDefinition = {
  fields: GroupDef & GroupContent<AboutYouFormFieldsDefinition>;
};

export type YourPropertyFormFieldsDefinition = {
  [ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD]: ListFieldDef;
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]: ListFieldDef;
  [ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]: ListFieldDef;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD]: NumberFieldDef;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD]: NumberFieldDef;
  [ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]: TextFieldDef;
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]: NumberFieldDef;
  [ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]: ListFieldDef;
  [ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]: NumberFieldDef;
};

export type AboutYouFormFieldsDefinition = {
  [ABOUT_YOU_FORM_MARITAL_STATUS_FIELD]: ListFieldDef;
  [ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD]: ListFieldDef;
  [ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD]: NumberFieldDef;
  [ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD]: ListFieldDef;
  [ABOUT_YOU_FORM_TAX_STATUS_FIELD]: ListFieldDef;
  [ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD]: ListFieldDef;
  [ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD]: TextFieldDef;
};

export type AboutYouStepLists = {
  [ABOUT_YOU_STEP_MARITAL_STATUS_LIST]: ListItem[] | null;
  [ABOUT_YOU_STEP_TAX_JURISDICTION_LIST]: ListItem[] | null;
} & AboutYouStepTaxStatusLists;

export type AboutYouStepTaxStatusLists = {
  [ABOUT_YOU_STEP_TAX_STATUS_REST_UK_LIST]: ListItem[] | null;
  [ABOUT_YOU_STEP_TAX_STATUS_SCOTLAND_LIST]: ListItem[] | null;
};

export type AboutYouStepMeta = {
  initialValues: AboutYouStepState;
  properties: JourneyStepsAccordionItemProps[];
  activePropertyIndex: number;
  lists: AboutYouStepLists;
  isJointCustomerDialogShown: boolean;
  isSharingYourInformationDialogShown: boolean;
};

export type PropertyFormState = {
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]: ListItem | null;
  [ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]: ListItem | null;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD]: number | null;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD]: number | null;
  [ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]: string | null;
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]: number | null;
  [ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]: ListItem | null;
  [ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]: number | null;
};

export type PropertyFormErrors = {
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]?: FieldMessages;
  [ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?: FieldMessages;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD]?: FieldMessages;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD]?: FieldMessages;
  [ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]?: FieldMessages;
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]?: FieldMessages;
  [ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]?: FieldMessages;
  [ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]?: FieldMessages;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM]?: boolean;
};

export type PropertyFormTouched = {
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_TYPE_FIELD]?: boolean;
  [ABOUT_YOU_PROPERTY_FORM_OWNERSHIP_FIELD]?: boolean;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_1_FIELD]?: boolean;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_CLIENT_2_FIELD]?: boolean;
  [ABOUT_YOU_PROPERTY_FORM_OTHER_OWNER_DETAILS_FIELD]?: boolean;
  [ABOUT_YOU_PROPERTY_FORM_PROPERTY_VALUE_FIELD]?: boolean;
  [ABOUT_YOU_PROPERTY_FORM_IS_PROPERTY_MORTGAGE_FIELD]?: boolean;
  [ABOUT_YOU_PROPERTY_FORM_MORTGAGE_REMAINING_FIELD]?: boolean;
  [ABOUT_YOU_PROPERTY_FORM_PERCENTAGE_SUM]?: boolean;
};

export type YourPropertyFormState = {
  [ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD]: ListItem | null;
};

export type YourPropertyFormErrors = {
  [ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD]?: FieldMessages;
};

export type YourPropertyFormTouched = {
  [ABOUT_YOU_PROPERTY_FORM_IS_OWN_PROPERTY_FIELD]?: boolean;
};

export type AboutYouState = {
  [CLIENT_1_SCOPE_NAME]: AboutYouFormState;
  [CLIENT_2_SCOPE_NAME]: AboutYouFormState;
};

export type AboutYouErrors = {
  [CLIENT_1_SCOPE_NAME]: AboutYouFormErrors;
  [CLIENT_2_SCOPE_NAME]: AboutYouFormErrors;
};

export type AboutYouTouched = {
  [CLIENT_1_SCOPE_NAME]: AboutYouFormTouched;
  [CLIENT_2_SCOPE_NAME]: AboutYouFormTouched;
};

export type AboutYouStepState = {
  [ABOUT_YOU_SCOPE_NAME]: AboutYouState;
  [YOUR_PROPERTY_SCOPE_NAME]: YourPropertyFormState;
};

export type AboutYouStepErrors = {
  [ABOUT_YOU_SCOPE_NAME]: AboutYouErrors;
  [YOUR_PROPERTY_SCOPE_NAME]: YourPropertyFormErrors;
};

export type AboutYouStepTouched = {
  [ABOUT_YOU_SCOPE_NAME]: AboutYouTouched;
  [YOUR_PROPERTY_SCOPE_NAME]: YourPropertyFormTouched;
};

export type AboutYouFormState = {
  [ABOUT_YOU_FORM_MARITAL_STATUS_FIELD]: ListItem | null;
  [ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD]: ListItem | null;
  [ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD]: number | null;
  [ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD]: ListItem | null;
  [ABOUT_YOU_FORM_TAX_STATUS_FIELD]: ListItem | null;
  [ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD]: ListItem | null;
  [ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD]: string | null;
};

export type AboutYouFormErrors = {
  [ABOUT_YOU_FORM_MARITAL_STATUS_FIELD]?: FieldMessages;
  [ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD]?: FieldMessages;
  [ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD]?: FieldMessages;
  [ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD]?: FieldMessages;
  [ABOUT_YOU_FORM_TAX_STATUS_FIELD]?: FieldMessages;
  [ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD]?: FieldMessages;
  [ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD]?: FieldMessages;
};

export type AboutYouFormTouched = {
  [ABOUT_YOU_FORM_MARITAL_STATUS_FIELD]?: boolean;
  [ABOUT_YOU_FORM_EMPLOYMENT_STATUS_FIELD]?: boolean;
  [ABOUT_YOU_FORM_GROSS_ANNUAL_INCOME_FIELD]?: boolean;
  [ABOUT_YOU_FORM_TAX_JURISDICTION_FIELD]?: boolean;
  [ABOUT_YOU_FORM_TAX_STATUS_FIELD]?: boolean;
  [ABOUT_YOU_FORM_FULL_ISA_ALLOWANCE_FIELD]?: boolean;
  [ABOUT_YOU_FORM_NATIONAL_INSURANCE_NUMBER_FIELD]?: boolean;
};

export type AboutYouFormScope = FormScope<
  AboutYouFormState,
  AboutYouFormErrors,
  AboutYouFormTouched
>;

export type AboutYouScope = FormScope<
  AboutYouState,
  AboutYouErrors,
  AboutYouTouched
>;

export type AboutYouStepScope = FormScope<
  AboutYouStepState,
  AboutYouStepErrors,
  AboutYouStepTouched
>;

export type YourPropertyFormScope = FormScope<
  YourPropertyFormState,
  YourPropertyFormErrors,
  YourPropertyFormTouched
>;

export type PropertyFormScope = FormScope<
  PropertyFormState,
  PropertyFormErrors,
  PropertyFormTouched
>;

export enum InitialiseAboutYouStepStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export enum AboutYouStepContentStates {
  Client1Content = "Client1Content",
  Client2Content = "Client2Content",
  YourProperty = "YourProperty",
}

export enum GetTaxStatusListStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export enum SendPartyPersonalStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export enum SendPropertyStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}

export enum YourPropertyContentStates {
  Uninitialised,
  Initialising,
  Idle,
  Success,
  Error,
}
