import { DefinitionResource } from "@saturn-ui/components";
import { LogoDefinition } from "../../../feature/logo/logoTypes";

const logoDefinition: DefinitionResource<LogoDefinition> = {
  content: {
    altText: {
      caption: "CFF",
    },
  },
};

export default logoDefinition;
