import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthenticationApi, getAuthenticationApi } from "skipton-common";
import { withSessionId } from "../../../utils/AuthorisationUtils";

export const logout = createAsyncThunk(
  "authentication/logout",
  async (data: {
    baseUrl: string;
  }): Promise<AuthenticationApi.LogoutResponseModel> => {
    const { baseUrl } = data;

    const authenticationApi = withSessionId(getAuthenticationApi(baseUrl));

    const response = await authenticationApi.logout();

    return response.status === 200;
  }
);

export const logoutSuccess = logout.fulfilled;
export const logoutPending = logout.pending;
export const logoutFailed = logout.rejected;
