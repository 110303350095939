import { WelcomePageDefinition } from "../../../feature/welcomePage/WelcomePageTypes";
import { DefinitionResource } from "@saturn-ui/components";

export const welcomePageDefinition: DefinitionResource<WelcomePageDefinition> =
  {
    content: {
      confirmationForm: {
        content: {
          fields: {
            content: {
              confirmRead: {
                appearance: "checkbox",
                caption: "I have read the welcome information",
                errors: {
                  required: true,
                },
              },
              confirmNoQuestions: {
                appearance: "checkbox",
                caption: "I do not have any questions at this point",
                errors: {
                  required: true,
                },
              },
            },
          },
        },
      },
      loginForm: {
        content: {
          fields: {
            content: {
              id: {
                caption: "Enter your Access Code",
                placeholder: "Type here",
                errors: {
                  required: true,
                  messages: {
                    required: "Something went wrong. Please try again.",
                  },
                },
              },
            },
          },
        },
      },
    },
  };

export default welcomePageDefinition;
