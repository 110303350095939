import React, { useEffect } from "react";
import { FC } from "react";
import { Layout } from "../layout/Layout";
import { useHistory } from "react-router-dom";
import { getFinancialAdviceRoute } from "../../helpers";
import "./AboutAdviserPage.scss";
import { RootState, useRootSelector } from "skipton-features";
import { useSelector } from "react-redux";
import { AppState } from "../../types/AppState";
import {
  getVirtualPageCustomerType,
  trackVirtualPageView,
} from "../../helpers/adobeAnalyticsHelpers";

export const AboutAdviserPage: FC = () => {
  const history = useHistory() as unknown[];
  const { isAdobeAnalyticsLoaded } = useRootSelector(
    (state: RootState) => state.configurationSettings
  );
  const {
    financialAdvicePage: {
      clientInfo: { category },
    },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    if (isAdobeAnalyticsLoaded) {
      const customerType = getVirtualPageCustomerType(category);

      trackVirtualPageView({
        pageName: "About your financial adviser",
        customerType,
      });
    }
  }, [isAdobeAnalyticsLoaded]);

  const handleBackPress = () => {
    history.push(getFinancialAdviceRoute());
  };

  return (
    <Layout.SubPage
      title="About your financial adviser"
      backButtonText="Back"
      onBackPress={handleBackPress}
      alwaysHavePadding={true}
    >
      <div className="about-adviser-page">
        <h2>How I help my customers</h2>
        <p>
          At Skipton, our advice is always tailored to you. When you first
          invest with us, we’ll have taken the time to understand your financial
          circumstances and future goals. This includes your appetite to risk
          and reward. We have a team of in-house experts who research options to
          help me build a portfolio that reflects your needs. We also work
          closely with leading investment organisations to strengthen our
          research.
        </p>
        <h2 className="about-adviser-page-subtitle-small">
          What to expect from our initial meeting?
        </h2>
        <p>
          Our meeting will be very relaxed. It’ll act as a guide in order to
          help you make important decisions and offer you peace of mind about
          your long-term future. There will be no up-front fees to meet, you’ll
          only be charged if you decide to act on a recommendation. With all
          costs and charges clearly laid out, you’ll know exactly what they are
          before you decide to continue. We believe in helping you make informed
          choices in your own time. That’s our No Pressure Promise.
        </p>
        <h2 className="about-adviser-page-subtitle-small">
          The different areas I can provide advice on
        </h2>
        <p className="different-areas-description">
          We understand that when it comes to your finances, there are lots to
          consider. But being able to openly discuss the different options
          available, in a way you can understand, value and trust, is really
          important.
        </p>
        <p className="bold before-list">
          I can discuss the following areas with you:
        </p>
        <ul className="list-disc">
          <li>
            <span className="bold">Investment planning</span> – this includes
            investing your money, as well as reviewing existing investments.
          </li>
          <li>
            <span className="bold">Retirement planning</span> – this is focused
            on helping you build a big enough pot of money for when you retire.
          </li>
          <li>
            <span className="bold">Retirement income planning</span> – this is
            helping you use your saved assets to fund your retirement.
          </li>
          <li>
            <span className="bold">Inheritance Tax</span> – this is where you
            could help maximise the amount of money you leave to your loved
            ones.
          </li>
        </ul>
      </div>
    </Layout.SubPage>
  );
};

export default AboutAdviserPage;
