import { AboutYouStepDefinition } from "./AboutYouStepTypes";

export const template: AboutYouStepDefinition = {
  form: {
    type: "group",
    content: {
      fields: {
        type: "group",
        content: {
          maritalStatus: {
            type: "list",
          },
          employmentStatus: {
            type: "list",
          },
          grossAnnualIncome: {
            type: "number",
          },
          taxJurisdiction: {
            type: "list",
          },
          taxStatus: {
            type: "list",
          },
          fullISAAllowance: {
            type: "list",
          },
          nationalInsuranceNumber: {
            type: "string",
          },
        },
      },
    },
  },
  yourPropertyForm: {
    type: "group",
    content: {
      fields: {
        type: "group",
        content: {
          isOwnProperty: {
            type: "list",
          },
          propertyType: {
            type: "list",
          },
          ownership: {
            type: "list",
          },
          ownershipPercentageClient1: {
            type: "number",
          },
          ownershipPercentageClient2: {
            type: "number",
          },
          otherOwnerDetails: {
            type: "string",
          },
          propertyValue: {
            type: "number",
          },
          isPropertyMortgage: {
            type: "list",
          },
          mortgageRemaining: {
            type: "number",
          },
        },
      },
    },
  },
};
