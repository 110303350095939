export const PROVISION_SCOPE_NAME = "provision";

export const PROVISION_FORM_TYPE_OF_HOLDING = "typeOfHolding";
export const PROVISION_FORM_WHO_BELONG_TO = "whoBelongTo";
export const PROVISION_FORM_WHO_IS_IT_HELD_WITH = "whoIsItHeldWith";
export const PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER = "whoIsItHeldWithOther";
export const PROVISION_FORM_PRODUCT_TYPE = "productType";
export const PROVISION_FORM_KNOW_POLICY_REF_NUM =
  "isPolicyReferenceNumberKnown";
export const PROVISION_FORM_POLICY_REFERENCE_NUMBER = "policyReferenceNumber";
export const PROVISION_FORM_HOW_MUCH_IS_IT_WORTH = "howMuchItWorth";
export const PROVISION_ID = "provisionId";
export const PROVISION_FORM_UPLOADED_FILES = "uploadedFiles";

export const PROVISION_TYPE_OF_HOLDING_LIST = "typeOfHoldingList";
export const PROVISION_WHO_BELONG_TO_LIST = "whoBelongToList";
export const PROVISION_WHO_IS_IT_HELD_WITH_LIST = "whoIsItHeldWithList";
export const PROVISION_PRODUCT_TYPE_LIST_SAVINGS = "savingsProductTypeList";
export const PROVISION_PRODUCT_TYPE_LIST_INVESTMENT =
  "InvestmentProductTypeList";
export const PROVISION_PRODUCT_TYPE_LIST_PENSION = "pensionProductTypeList";
export const PROVISION_KNOW_POLICY_REF_NUM_LIST =
  "isPolicyReferenceNumberKnownList";

export const provisionFormFieldKeys = [
  PROVISION_FORM_TYPE_OF_HOLDING,
  PROVISION_FORM_WHO_BELONG_TO,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH,
  PROVISION_FORM_WHO_IS_IT_HELD_WITH_OTHER,
  PROVISION_FORM_PRODUCT_TYPE,
  PROVISION_FORM_KNOW_POLICY_REF_NUM,
  PROVISION_FORM_POLICY_REFERENCE_NUMBER,
  PROVISION_FORM_HOW_MUCH_IS_IT_WORTH,
  PROVISION_ID,
  PROVISION_FORM_UPLOADED_FILES,
];
