import { ListApi } from "skipton-common";
import { ListItem } from "@saturn-ui/components";

export const mapListToState = (list: ListApi.List): ListItem[] =>
  list.listItems.map((item) => ({
    id: item.value,
    displayText: item.text,
  }));

export const mapListToStateWithRealId = (list: ListApi.List): ListItem[] =>
  list.listItems.map((item) => ({
    id: item.id,
    displayText: item.text,
    otherText: item.value,
  }));
